import { useCallback, useState } from "react"

import { Grid, IconButton, makeStyles, Paper, Tooltip } from "@material-ui/core"
import { Delete, ExpandLess, ExpandMore } from "@material-ui/icons"

import CharacterAkaExpandedContent from "./CharacterAkaExpandedContent"
import AkaLocalization from "./AkaLocalization"
import Filler from "../Filler"


const useStyles = makeStyles(theme => ({
  CharacterAka: {
    padding: theme.spacing(1),
    margin: `${theme.spacing(1)}px 0`,
  },
  OriginalAka: {
    padding: `0 ${theme.spacing(1)}px`,
  },
}))

const CharacterAkaHeader = ({
  expanded,
  onClickExpand,
  onClickDeleteAka,
  aka,
  character,
  saveOriginal,
}) => {
  const classes = useStyles()

  return (
    <Grid container direction="row" wrap="nowrap" alignItems="center">
      <div className={classes.OriginalAka}>
        <AkaLocalization {...{
          aka,
          character,
          localization: aka.original,
          nameLabel: "Original Name",
          saveMethod: saveOriginal,
        }} />
      </div>
      <Filler />
      {false && (
        <Tooltip title="Delete AKA">
          <span>
            <IconButton disabled={true} onClick={onClickDeleteAka}>
              <Delete />
            </IconButton>
          </span>
        </Tooltip>
      )}
      <IconButton onClick={onClickExpand}>
        {expanded ? <ExpandMore /> : <ExpandLess />}
      </IconButton>
    </Grid>
  )
}

const CharacterAka = ({
  character,
  aka,
  updateCharacterAkaOriginal,
  updateLocalizationToCharacterAka,
  titles,
}) => {
  // TODO: the aka is not being updated after calling API,
  // which causes CLEAR button to show wrong value
  const classes = useStyles()
  //const [akaValue, setAkaValue] = useState(aka && aka.original && aka.original.value)
  // const [langValue, setLangValue] = useState(aka.language)
  const [expanded, setExpanded] = useState(true)

  const saveOriginal = useCallback(({akaValue, language}) => {
    const input = {
      id: character.id,
      akaId: aka.id,
      original: {
        language,
        value: akaValue,
      },
    }
    console.log(`CharacterAka/saveOriginal/updateCharacterAkaOriginal`, input)
    return updateCharacterAkaOriginal(input)
  }, [updateCharacterAkaOriginal, character, aka])

  const saveLocalized = useCallback(({akaValue, language}) => {
    const input = {
      id: character.id,
      akaId: aka.id,
      localization: {
        language,
        value: akaValue,
      },
    }
    console.log(`CharacterAka/saveLocalized/updateLocalizationToCharacterAka(${JSON.stringify(input)})`)
    return updateLocalizationToCharacterAka(input)
  }, [updateLocalizationToCharacterAka, character, aka])

  const onClickExpand = useCallback(() => {
    setExpanded(exp => !exp)
  }, [setExpanded])

  const onClickDeleteAka = useCallback(() => {
    console.log('TODO: /character/aka/onClickDeleteAka', aka)
  }, [aka])

  return (
    <Paper className={classes.CharacterAka}>
      <CharacterAkaHeader {...{
        onClickDeleteAka,
        onClickExpand,
        expanded,
        aka,
        character,
        saveOriginal,
      }} />

      {expanded && (
        <CharacterAkaExpandedContent {...{
          aka,
          character,
          titles,
          saveLocalized,
        }} />
      )}
    </Paper>
  )
}

export default CharacterAka
