import React from 'react'
import LinkButton from './LinkButton'
import config from '../config'

const WMLoginButton = ({id}) => {
  return  (
    <LinkButton label="WM Login" to={config.app.loginURL} id={id} />
  )
}

export default WMLoginButton
