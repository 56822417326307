// import { Autocomplete } from '@material-ui/lab'
import Select from '@material-ui/core/Select'
import { 
  FormControl,
  InputLabel,
  makeStyles,
  MenuItem
  // PropTypes,
  // FormControl, Grid, InputLabel, TextField, Typography,
} from '@material-ui/core'
import clsx from 'clsx'
import { FC } from 'react'
import { LMTLanguageByBcp47Map, SelectOnChange } from '../types'
// import { drillDown, assignByKey } from 'deepdown'

// const useStylesAutocomplete = makeStyles(theme => ({
//   inputRoot: {
//     padding: `0 ${theme.spacing(1)}px`,
//     color: theme.palette.primary.contrastText,
//     "&:before": {
//       borderColor: theme.palette.primary.contrastText,
//     },
//     // this selector was determined by inspecing the dom tree,
//     // forcing on the :hover state for the dom node with this style applied,
//     // and determining which selector caused the border style.
//     "&.MuiInput-underline:hover:not(.Mui-disabled):before": {
//       borderColor: theme.palette.primary.contrastText,
//     },
//   },
//   popupIndicator: {
//     color: theme.palette.primary.contrastText,
//   },
//   root: {
//     padding: `0 ${theme.spacing(1)}px`,
//     minWidth: 280,
//   },
// }))

// const useStylesOption = makeStyles(theme => ({
//   OptionRoot: {
//     maxWidth: 200,
//     minWidth: 200,
//   },
// }))

// const renderOption = LMTBcp47ToDescription => (option, state) => {
//   // const classes = useStylesOption()
//   const display = (option && LMTBcp47ToDescription && LMTBcp47ToDescription[option]) ? `${LMTBcp47ToDescription[option]} (${option})` : option
//   return (
// <Grid container direction="row" justifyContent="flex-start" alignItems="center" wrap="nowrap">
//   <Typography>{display}</Typography>
// </Grid>
//   )
// }

// const renderInput = LMTBcp47ToDescription => (params) => {
//   const spot = ['inputProps', 'value']
//   const v = drillDown(params, spot) || ''
//   const display = (v && LMTBcp47ToDescription) ? `${LMTBcp47ToDescription[v]} (${v})` : v
//   assignByKey(params, spot, display)

//   return (
//     <TextField
//       {...params}
//       variant="standard"
//       size="small"
//       value={display}
//     />
//   )
// }

const useStyles = makeStyles(theme => ({
  wrapper: {
    margin: `0 ${theme.spacing(1)}px`,
    minWidth: 160,
  },
}))

const useStylesSelect = makeStyles(theme => ({
  root: {
    margin: `0 ${theme.spacing(1)}px`,
  },
}))

export interface LanguageSelectorProps {
  disabled?: boolean
  LMTBcp47ToDescription: LMTLanguageByBcp47Map
  onChangeLanguage: SelectOnChange
  label?: string
  id?: string
  lang?: string
  className?: string
  keyprefix?: string
  color?: 'primary' | 'secondary' //PropTypes.Color
}

const LanguageSelector: FC<LanguageSelectorProps> = ({
  disabled,
  LMTBcp47ToDescription,
  onChangeLanguage,
  lang,
  id,
  className,
  label,
  keyprefix = "/language-selector",
  color = 'primary',
}) => {
  const selectClasses = useStylesSelect()
  const classes = useStyles()
  const options = Object.keys(LMTBcp47ToDescription)

  return (
    <div className={clsx(classes.wrapper, className)} id={id}>
      <FormControl fullWidth>
        {label && (<InputLabel>{label}</InputLabel>)}
        <Select
          color={color}
          disabled={disabled}
          label={label}
          classes={selectClasses}
          value={lang || ''}
          onChange={onChangeLanguage}
          // inputProps={{classes: inputClasses}}
        >
          {options.map((option) => {
            const display = (option && LMTBcp47ToDescription && LMTBcp47ToDescription[option]) ? `${LMTBcp47ToDescription[option]} (${option})` : option
            return (
              <MenuItem key={`${keyprefix}/${option}`} value={option}>{display}</MenuItem>
            )
          })}
        </Select>
      </FormControl>
    </div>
  )

  // return (
  // <Autocomplete
  //   debug
  //   disableClearable
  //   options={options}
  //   value={lang || ''}
  //   onChange={onChangeLanguage}
  //   renderInput={renderInput(LMTBcp47ToDescription)}
  //   renderOption={renderOption(LMTBcp47ToDescription)}
  //   classes={classes}
  // />
  // )
}

export default LanguageSelector
