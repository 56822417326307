import React, { useState, useEffect } from 'react'

import SearchResults from '../components/SearchResults'
import { recentUpdates } from '../services/localization'
import Layout from '../components/Layout'
import {models} from 'dubcard'
const { reducerTitles } = models

const Dashboard = (props) => {
  const [pageData, setPageData] = useState({ fetching: false, results: [], error: null, firstCall: false })
  // console.log('-- rendering Dashboard')
  const { user, titles, setTitles, onClickClearTerm } = props

  useEffect(() => {
    if (!user || pageData.firstCall || pageData.fetching) {
      console.log('- Dashboard - NOT going to call recentUpdates()')
      return
    }

    console.log('- Dashboard - about to call recentUpdates()')
    const prerequest = { ...pageData, fetching: true, firstCall: true }
    setPageData(prerequest)
    recentUpdates()
      .then(results => {
        console.log('- recentUpdates', results)
        setTitles({ ...titles, ...(results.title.reduce(reducerTitles, {})) })
        setPageData({ ...prerequest, fetching: false, results: results.hits, error: null, firstCall: true })
      })
      .catch(e => {
        console.error('- recentUpdates error:', e)
        setPageData({ ...prerequest, error: e, fetching: false })
      })
  }, [user, pageData, setPageData, titles, setTitles])

  // TODO: make a custom view for recent updates,
  // i am re-using SearchResults view for now.
  return (
    <Layout {...props} >
      {!pageData.error && (
        <SearchResults
          data={pageData}
          titles={titles}
          heading="Recent Credit Updates"
          label="Loading Recent Credit Updates"
          onClickClearTerm={onClickClearTerm}
        />
      )}
    </Layout>
  )
}

export default Dashboard
