import { FC } from "react"
import { makeStyles, Typography } from "@material-ui/core"
import { LMTLanguageByBcp47Map } from "../../types"
import LanguageSelector, {LanguageSelectorProps} from "../LanguageSelector"

const useStyles = makeStyles(theme => ({
  SelectOriginalLanguage: {
    padding: theme.spacing(2),
  },
  SelectContainer: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
  },
}))

export interface SelectOriginalLanguageProps {
  onChangeLanguage: LanguageSelectorProps['onChangeLanguage']
  lang: string
  LMTBcp47ToDescription: LMTLanguageByBcp47Map
}

const SelectOriginalLanguage: FC<SelectOriginalLanguageProps> = ({
  lang,
  LMTBcp47ToDescription,
  onChangeLanguage,
}) => {

  const classes = useStyles()

  return (
    <div className={classes.SelectOriginalLanguage}>
      <div>
        <Typography variant="h4" align="center">Choose Original Language</Typography>
      </div>
      <div>
        <Typography variant="caption" align="center">
          The title's original language value was not included in the metadata.
          This happens sometimes due to data entry procedures.
          You must select the title's original language
          before adding Original Language Characters and Localized Credits.
        </Typography>
      </div>
      <div className={classes.SelectContainer}>
        <LanguageSelector {...{
          label: "Original Language",
          LMTBcp47ToDescription,
          onChangeLanguage,
          lang,
          color: 'secondary',
        }}/>
      </div>
    </div>
  )
}

export default SelectOriginalLanguage
