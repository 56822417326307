/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
    "aws_project_region": "us-west-2",
    "aws_content_delivery_bucket": "dev-localization.contentnow.warnerbros.com",
    "aws_content_delivery_bucket_region": "us-west-2",
    "aws_content_delivery_url": "https://df5kmc8d1ompf.cloudfront.net",
    "aws_appsync_graphqlEndpoint": "https://eqpsi3edzjfphhxjg5zkel52ia.appsync-api.us-west-2.amazonaws.com/graphql",
    "aws_appsync_region": "us-west-2",
    "aws_appsync_authenticationType": "AMAZON_COGNITO_USER_POOLS"
};


export default awsmobile;
