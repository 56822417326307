"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const role_types_1 = require("./role-types");
const templatesByName = {
    Studio: {
        heading: 'Localized Version',
        subHeading: '',
        // forceNewPage: true,
        roleFilter: {
            excludes: role_types_1.castRoles,
        },
    },
    Characters: {
        heading: 'Localized Version',
        subHeading: 'With the Voices of',
        // forceNewPage: true,
        roleFilter: {
            includes: role_types_1.castRoles,
            checkCharacter: {
                value: true,
            },
        },
    },
    Unnamed: {
        heading: 'Localized Version',
        subHeading: 'Additional Voices',
        // forceNewPage: true,
        hideRole: true,
        roleFilter: {
            includes: role_types_1.castRoles,
            checkCharacter: {
                value: false,
            },
        },
    },
};
exports.default = templatesByName;
