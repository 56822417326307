"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.isCastRole = exports.castRoles = exports.isRole = exports.RoleTypes = void 0;
var RoleTypes;
(function (RoleTypes) {
    RoleTypes["DUBBING_VOICE"] = "Dubbing Voice";
    RoleTypes["DUBBING_STUDIO"] = "Dubbing Studio";
    RoleTypes["DUBBING_DIRECTOR"] = "Dubbing Director";
    RoleTypes["DUBBING_TRANSLATOR"] = "Translator";
    RoleTypes["DUBBING_ADAPTATION"] = "Adaptation";
    RoleTypes["DUBBING_EDITOR"] = "Editor";
    RoleTypes["DUBBING_SUPERVISOR"] = "Supervisor";
    RoleTypes["DUBBING_ENGINEER"] = "Engineer";
    RoleTypes["DUBBING_RECORDING_TECHNICIAN"] = "Recording Technician";
    RoleTypes["DUBBING_MIXER"] = "Mixer";
})(RoleTypes = exports.RoleTypes || (exports.RoleTypes = {}));
const isRole = (x) => {
    return Object.values(RoleTypes).includes(x);
};
exports.isRole = isRole;
exports.castRoles = [
    RoleTypes.DUBBING_VOICE,
];
const isCastRole = (role) => {
    return (role === RoleTypes.DUBBING_VOICE);
};
exports.isCastRole = isCastRole;
// export const studioRoles = Object.keys(EnumCreditRole)
//   .map(key => EnumCreditRole[key])
//   .filter(role => !castRoles.includes(role))
