"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.status = exports.platforms = void 0;
var platforms;
(function (platforms) {
    platforms["HBOMAX"] = "HBOMAX";
})(platforms = exports.platforms || (exports.platforms = {}));
var status;
(function (status) {
    status["IN_PROGRESS"] = "IN_PROGRESS";
    status["COMPLETED"] = "COMPLETED";
    status["ERROR"] = "ERROR";
})(status = exports.status || (exports.status = {}));
