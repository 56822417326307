import { Grid, makeStyles, IconButton, TextField, Tooltip } from '@material-ui/core'
import { AddCircle, Clear, Search } from '@material-ui/icons'

const useStyles = makeStyles(theme => ({
  inputRoot: {
    backgroundColor: theme.palette.primary[50],
  },
  inputInput: {
    padding: theme.spacing(1),
  },
  TalentSearchHeader: {
    padding: theme.spacing(1),
  },
}))


const TalentSearchHeader = ({
  inputValue,
  onChangeInputValue,
  onClickClearSearch,
  onClickAddTalent,
}) => {
  const classes = useStyles()

  return (
    <div className={classes.TalentSearchHeader}>
      <Grid container direction="row" alignItems="center" justifyContent="flex-start" wrap="nowrap">
        <TextField
          value={inputValue}
          placeholder="Search…"
          fullWidth
          onChange={onChangeInputValue}
          margin="dense"
          InputProps={{
            classes: {
              root: classes.inputRoot,
              input: classes.inputInput,
            },
            startAdornment: (
              <Search />
            ),
            endAdornment: (inputValue.length === 0) ? null : (
              <IconButton
                onClick={onClickClearSearch}
              >
                <Clear />
              </IconButton>
            ),
          }}
        />
        <Tooltip title={(inputValue.length === 0) ? "Create Talent" : `Create "${inputValue}"`}>
          <span>
            <IconButton
              disabled={inputValue.length === 0}
              onClick={onClickAddTalent}
              color="primary"
            >
              <AddCircle />
            </IconButton>
          </span>
        </Tooltip>
      </Grid>
    </div>
  )
}

export default TalentSearchHeader
