import { drillDown, indexByKey, sortByKey, unwindByKey } from "deepdown"

import {
  // selectRoleFromLocalizedCredit,
  // selectBillingFromOriginalTalent,
  // selectCharacterFromOriginalTalent,
  // selectRoleFromOriginalTalent,
  selectSeasonOrEpisodeFromTitleMetadata
} from '../lib/selections'

import TitleTypes from './title-types.json'
import { compile } from './schema-utils'
import schemaXlsxInput from './schema-xlsx-input.json'

import {merge} from './algs'
import { stringSimilarity } from './similarity'
import { v4 as uuidV4 } from 'uuid'

import {
  bulk,
  roles,
  models,
  Character,
  CharactersByIdMap,
  TalentByIdMap,
  TitleCredit,
  CharacterAKA,
  CharacterUnwoundbyAKAs,
  TalentUnwoundByAKAs,
  Talent,
  AtomCharacter,
  InputImportCredit,
  ImportEntityInfo,
  ImportCreditsFromXlsxParams,
  LocalizedTitle,
  TitleMetadata,
  CharacterByIdMap,
  TitleOriginal,
  InputCreateCharacter
} from 'dubcard'

import {
  AtomCharacterCandidatesByNameMap,
  AtomCharacterImportBehavior,
  AtomCharacterImportBehaviorApi,
  AtomCharacterImportBehaviorByNameMap,
  CharacterCandidate,
  CharacterUnwoundBySeries,
  CharacterUnwoundBySeriesIdMap,
  ChooseBehaviorParams,
  ComputeImportXlsxBehaviorSetupOutput,
  ComputeImportXlsxBehaviorsInitParams,
  ComputeImportXlsxBehaviorsParams,
  ComputeImportXlsxBehaviorsSetupParams,
  ComputeImportXlsxBehaviorsTalentSearchHandlerParams,
  ComputeImportXlsxBehaviorsTalentSearchResponse,
  EquivalentBillingParams,
  GetCreditsForRoleParams,
  GetCreditsWithExclusionsParams,
  GetSortedTalentParams,
  GraphQLError,
  ImportBehaviorCredit,
  ImportingBehavior,
  ImportingBehaviorUpdates,
  LoadedXlsx,
  LoadedXlsxCharacterCandidateByNameMap,
  LoadedXlsxRole,
  LoadedXlsxTalentCandidateByNameMap,
  ReducerAtMpmParams,
  ReducerComputeBehaviorParams,
  ReducerRankFranchiseCharactersParams,
  ReducerRankTalentSearchesParams,
  ReducerXlsxToImportBehaviorParams,
  TalentCandidate,
  TitleByIdMap
} from "../types"

const { RoleTypes, isRole } = roles
const { BehaviorMode } = bulk
const { billingSort } = models

// TODO: this should probably be CrewRoleNames (it is used to locate crew)
// const roleNames = Object.keys(RoleTypes).map(k => RoleTypes[k])

const validInput = compile(schemaXlsxInput)

const IpTypesNeedingSeries = [
  TitleTypes.Season,
  TitleTypes.Episode,
  TitleTypes.Pilot,
]

export const equivalentBilling = ({billing, billingLocalized}: EquivalentBillingParams) => {
  return (billing==="" && billingLocalized===null) || (`${billing}` === `${billingLocalized}`)
}

export const episodeSort = (a: LocalizedTitle, b: LocalizedTitle) => {
  const numA = parseInt(drillDown(a, selectSeasonOrEpisodeFromTitleMetadata), 10)
  const numB = parseInt(drillDown(b, selectSeasonOrEpisodeFromTitleMetadata), 10)

  if (numA < numB) return -1
  if (numA > numB) return 1
  return 0
}

export const getReleaseYear = (title: LocalizedTitle, { country = 'United States' } = {}) => {
  if (title && title.originalReleaseYear) {
    return title.originalReleaseYear
  }
  // const foundCountry = (!title ? [] : title.country || []).find(c => c.name === country) || {}
  // const epochDate = foundCountry.theatricalReleaseDate
  // if (!epochDate) {
  //   return null
  // }
  // return new Date(epochDate).getFullYear()
}

const getSeasonNumber = (mpm: string, titles: TitleByIdMap) => {
  const episode = titles[mpm]
  const parentElement = (episode?.parentTitles || []).find(pt => pt.isDefault)
  return parentElement?.parentOriginallyAiredAs
  // const parentTitle = parentElement?.parentMpmNumber && titles[parentElement?.parentMpmNumber]
  // return parentTitle && parentTitle.originallyAiredAs
}

export interface MakeDefaultInputCreateCharacter {
  mpm: string[],
  language: string,
}

export const makeDefaultCreateCharacterParams = ({mpm, language}: MakeDefaultInputCreateCharacter): InputCreateCharacter => ({
  AKAs: [{
    mpm,
    original: {
      value: '',
      language,
    },
  }],
})

export const changeCharacterAkaOriginalValue = <T extends InputCreateCharacter>(model: T, akaIndex: number, value: string) => ({
  ...model,
  AKAs: [
    ...model.AKAs.slice(0, akaIndex),
    {
      ...model.AKAs[akaIndex],
      original: {
        ...model.AKAs[akaIndex].original,
        value,
      },
    },
    ...model.AKAs.slice(akaIndex + 1),
  ],
})

export interface GetFranchiseCharactersParams {
  mpm: string
  titles: TitleByIdMap
  charactersBySeries: CharactersByIdMap
  charactersByMpm: CharactersByIdMap
}

export const getFranchiseCharacters = ({
  mpm,
  titles,
  charactersBySeries,
  charactersByMpm,
}: GetFranchiseCharactersParams): Character[] => {
  const title = (!mpm || !titles) ? null : titles[mpm]
  const season = (!title || !title.mpmProductNumber) ? null : titles[title.mpmProductNumber]
  const series = !season
    ? (!title || !title.mpmFamilyNumber) ? null : titles[title.mpmFamilyNumber]
    : (!season || !season.mpmFamilyNumber) ? null : titles[season.mpmFamilyNumber]

  const franchiseCharacters: Character[] = !series
    ? (!title) ? [] : (charactersByMpm[title.mpm] || [])
    : (charactersBySeries[series.mpm] || [])

  return franchiseCharacters
}

const getSeries = (mpm: string, titles: TitleByIdMap): LocalizedTitle | null => {
  if (!titles) {
    return null
  }

  const title = titles[mpm]
  if (!title) {
    return null
  }

  if (!IpTypesNeedingSeries.includes(title.type)) {
    return null
  }

  const parentTitleElement = (title.parentTitles || []).find(pt => pt.isDefault)
  if (!parentTitleElement) {
    return null
  }

  const parentTitle = (parentTitleElement.parentMpmNumber)
    ? titles[parentTitleElement.parentMpmNumber]
    : null

  if (parentTitle?.type === TitleTypes.Series) {
    return parentTitle
  }

  if (!parentTitle) {
    return null
  }

  if (parentTitle.type !== TitleTypes.Season) {
    return null
  }

  const grandParentElement = (parentTitle.parentTitles || []).find(pt => pt.isDefault)
  if (!grandParentElement) {
    return null
  }

  const grandParentTitle = (grandParentElement.parentMpmNumber)
    ? titles[grandParentElement.parentMpmNumber]
    : null
  if (grandParentTitle?.type === TitleTypes.Series) {
    return grandParentTitle
  }

  return null
}

const episodicIpTypes = [
  TitleTypes.Series,
  TitleTypes.Season,
  TitleTypes.Episode,
]

const IpTypesNeedingSeason = [
  TitleTypes.Episode,
  TitleTypes.Pilot,
]

export interface FormatTitleOptions {
  shortVersion?: boolean
  showReleaseYear?: boolean
  showIpType?: boolean
  defaultTitle?: string
  missing?: string
}

interface FormatTitleSeriesParams {
  strReleaseYear: string
  originalTitle: string
}

const formatTitleSeries = (
  {originalTitle, strReleaseYear}: FormatTitleSeriesParams,
  options: FormatTitleOptions
): string => `Series: ${originalTitle}` + strReleaseYear

interface FormatTitleSeasonParams {
  strReleaseYear: string
  title: LocalizedTitle
  parentSeriesTitle: string
}

const formatTitleSeason = (
  {title, parentSeriesTitle, strReleaseYear}: FormatTitleSeasonParams,
  options: FormatTitleOptions
): string => {
  const {missing} = (options || {})
  return (!!parentSeriesTitle)
    ? `${parentSeriesTitle} Season ${title.originallyAiredAs || missing}` + strReleaseYear //(title.originallyAiredAs)
    // ? `${parentSeriesTitle} Season ${title.originallyAiredAs || '?'}` + strReleaseYear
    // : parentSeriesTitle + strReleaseYear
    : (!!title.original.title)
      ? (!!title.originallyAiredAs)
        ? `${title.original.title} Season ${title.originallyAiredAs}` + strReleaseYear
        : `${title.original.title}` + strReleaseYear
      : (!!title.originallyAiredAs)
        ? `Season ${title.originallyAiredAs}` + strReleaseYear
        : `Season` + strReleaseYear
}

interface FormatTitleSeasonChildParams {
  title: LocalizedTitle
  parentSeasonNumber?: string
  parentSeriesTitle: string
  originalTitle: string
  strReleaseYear: string
}

const formatTitleSeasonChild = ({
  title,
  parentSeasonNumber,
  parentSeriesTitle,
  originalTitle,
  strReleaseYear,
}: FormatTitleSeasonChildParams,
options: FormatTitleOptions): string[] => {
  const { shortVersion, missing } = (options || {})

  const S = parentSeasonNumber || missing
  const E = title.originallyAiredAs || missing

  const prefix = `${parentSeriesTitle} S${S} E${E}`

  // const prefix = (!!parentSeriesTitle)
  //   ? (!!parentSeasonNumber)
  //     ? (title.originallyAiredAs)
  //       ? `${parentSeriesTitle} S${parentSeasonNumber} E${title.originallyAiredAs}`
  //       : `${parentSeriesTitle} S${parentSeasonNumber} E?`
  //     : (title.originallyAiredAs)
  //       ? `${parentSeriesTitle} S? E${title.originallyAiredAs}`
  //       : `${parentSeriesTitle} S? E?`
  //   : (!!parentSeasonNumber)
  //     ? (title.originallyAiredAs)
  //       ? `S${parentSeasonNumber} E${title.originallyAiredAs}`
  //       : `S${parentSeasonNumber} E?`
  //     : (title.originallyAiredAs)
  //       ? `S? E${title.originallyAiredAs}`
  //       : `S? E?`
  
  if (shortVersion) {
    return [prefix]
  }

  return [prefix, originalTitle + strReleaseYear]
}

const FormatTitleDefaultOptions: FormatTitleOptions = {
  missing: '?',
  shortVersion: false,
  showReleaseYear: true,
  showIpType: false,
  defaultTitle: 'Missing Title Data',
}

export const formatTitle = (
  mpm: string,
  titles: TitleByIdMap,
  opts: FormatTitleOptions
): string[] => {
  const options = merge(FormatTitleDefaultOptions, opts)
  const {
    shortVersion=false,
    showReleaseYear=true,
    showIpType=false,
    defaultTitle='Missing Title Data',
  } = (options)

  const title = (mpm && titles) ? titles[mpm] : null
  if (!title) {
    return [defaultTitle]
  }
  const ipTypeIsEpisodic = title && title.type && episodicIpTypes.includes(title.type)
  const ipType = (title && !ipTypeIsEpisodic)
    ? ` - ${title.type}`
    : ''

  const releaseYear = title && title.originalReleaseYear
  const strReleaseYear = (showReleaseYear && releaseYear)
    ? (showIpType)
      ? ` - ${releaseYear}${ipType}`
      : ` - ${releaseYear}`
    : (showIpType)
      ? `${ipType}`
      : ``
  const originalTitle = (title && drillDown(title, 'original.title'.split('.'))) || defaultTitle
  const needsSeason = (!title) ? false : IpTypesNeedingSeason.includes(title.type)
  const needsSeries = (!title) ? false : IpTypesNeedingSeries.includes(title.type)
  const parentSeasonNumber = !needsSeason ? undefined : getSeasonNumber(mpm, titles)
  const parentSeries = !needsSeries ? null : getSeries(mpm, titles)
  const parentSeriesTitle = (needsSeries && parentSeries?.original?.title)
    ? parentSeries.original.title
    : ''
  return (!!title && TitleTypes.Series === title.type)
    ? [formatTitleSeries({originalTitle, strReleaseYear}, options)]
    : (title && TitleTypes.Season === title.type)
      ? [formatTitleSeason({title, parentSeriesTitle, strReleaseYear}, options)]
      : (needsSeason)
        ? formatTitleSeasonChild({title, parentSeasonNumber, parentSeriesTitle, originalTitle, strReleaseYear}, options)
        : shortVersion
          ? [originalTitle]
          : [originalTitle + strReleaseYear]
}

export const reducerLocalizationsIntoTitles = (accum: TitleByIdMap, loc: TitleMetadata): TitleByIdMap => {
  const localized = drillDown(accum, [loc.mpm, 'localized']) || []
  const locIndex = localized.findIndex((ldoc: TitleMetadata) => ldoc.language === loc.language)
  return {
    ...accum,
    [loc.mpm]: {
      ...accum[loc.mpm],
      localized: (locIndex === -1) ? [...localized, loc] : [
        ...localized.slice(0, locIndex), // before
        loc,
        ...localized.slice(locIndex + 1), // after
      ],
    },
  }
}

const reducerAtMpm = ({candidatePoolById, existingAtMpmGroupedById}: ReducerAtMpmParams) => (accum: Character[], cand: Character): Character[] => {
  const character = drillDown(candidatePoolById, [cand.id, 0])

  if (!existingAtMpmGroupedById[cand.id]) {
    // just append array
    // debugger
    return [...accum, character]
  }

  // overwrite exiting
  const existingIndex = accum.findIndex(exist => exist.id === cand.id)

  // this should not be possible after above check
  if (existingIndex === -1) {
    // but just in case
    // debugger
    return [...accum, character]
  }

  // debugger
  return [
    ...accum.slice(0, existingIndex), // before
    character,
    ...accum.slice(existingIndex + 1), // after
  ]
}

export const reduceCharactersBySeries = (
  charactersBySeries: CharactersByIdMap,
  characters: Character[]
): CharactersByIdMap => {
  const candidatePool = characters.filter(c => (c.series && c.series.length > 0))
  const candidatePoolById: CharactersByIdMap = indexByKey(candidatePool, ['id'])

  const unwoundCharsSeries: CharacterUnwoundBySeries = unwindByKey(candidatePool, ['series'])
  const candidatesGroupedBySeries: CharacterUnwoundBySeriesIdMap = indexByKey(unwoundCharsSeries, ['series'])

  // this assumes characters are unique, no repeats.
  const reducerSeries = (accum: CharactersByIdMap, seriesMpm: string): CharactersByIdMap => {
    const candidates = candidatesGroupedBySeries[seriesMpm] || []
    const existingAtMpm = charactersBySeries[seriesMpm] || []
    const existingAtMpmGroupedById = indexByKey(existingAtMpm, ['id'])

    const charactersPerSeries = candidates.reduce(reducerAtMpm({candidatePoolById, existingAtMpmGroupedById}), existingAtMpm)

    return {
      ...accum,
      [seriesMpm]: charactersPerSeries,
    }
  }

  const series = Object.keys(candidatesGroupedBySeries)
  return series.reduce(reducerSeries, charactersBySeries)
}

// type ArrayElement<ArrayType extends readonly unknown[]> = 
//   ArrayType extends readonly (infer ElementType)[] ? ElementType : never;

const getEntityAKA = <
  TAKA extends {id: string},
  TMap extends Record<string, {AKAs: TAKA[]}>,
  TInfo extends {id: string, aka: string}
> (entitiesById: TMap, info?: TInfo): TAKA | undefined => {
  const entity = (entitiesById && info?.id) ? entitiesById[info.id] : null
  if (!entity) {
    return
  }
  const aka = entity && entity.AKAs.find(aka => aka.id === info?.aka)
  return aka
}

const getProp = <T>(vessel: T, propPath: string[]) => {
  return vessel && drillDown(vessel, propPath)
}

const mapCreditToArray = (talents: TalentByIdMap) => (credit: TitleCredit) => [
  credit.role,
  getProp(getEntityAKA(talents, credit.talent), ["value"]) || '',
  getProp(credit, ['billingOrder']) || '',
]

const getCreditsForRole = ({credits, role, talents}: GetCreditsForRoleParams): string[][] => {
  const filtered = credits.filter(credit => credit.role === role)

  return (filtered.length === 0)
    ? [[role]]
    : filtered.map(mapCreditToArray(talents))
}

const getCreditsWithExclusions = ({credits, exclusions, talents}: GetCreditsWithExclusionsParams): string[][] => {
  const filtered = credits.filter(credit => !exclusions.includes(credit.role))

  return filtered.map(mapCreditToArray(talents))
}

export type TitleUnwoundByLocalized = Omit<LocalizedTitle, 'localized'> & {
  localized: TitleMetadata
}

export type TitleUnwoundByLocalizedWithAtom = Omit<TitleUnwoundByLocalized, 'original'> & {
  atom: TitleOriginal
  original: TitleMetadata
}

export interface CreditsToArraysParams {
  talents: TalentByIdMap
  characters: CharacterByIdMap
  title: TitleUnwoundByLocalizedWithAtom
}

export const creditsToArrays = (doc: CreditsToArraysParams) => {
  if (!validInput(doc)) {
    throw new Error('malformed input: ' + JSON.stringify(validInput.errors))
  }

  const talents = doc?.talents || {}
  const characters = doc?.characters || {}
  const {original: originalDoc, localized} = (doc?.title || {})
  const originalCredits = originalDoc?.credits || []
  const originalCharacterCredits = originalCredits
    .filter(credit => !!credit?.character)
    .sort(billingSort())
  const credits: TitleCredit[] = localized?.credits || []
  const localizedCharacterCredits = credits.filter(credit => credit.character && credit.role === RoleTypes.DUBBING_VOICE)
  const indexLocalizedCharacterCreditsByCharacterId = indexByKey(localizedCharacterCredits, ['character', 'id'])
  const voiceCredits = credits.filter(credit => !credit.character && credit.role === RoleTypes.DUBBING_VOICE)

  const exclusions = [
    RoleTypes.DUBBING_STUDIO,
    RoleTypes.DUBBING_DIRECTOR,
    RoleTypes.DUBBING_TRANSLATOR,
    RoleTypes.DUBBING_VOICE,
  ]

  return [
    // title metadata
    ["title",     doc?.title?.atom?.title],
    ["released",  doc?.title?.originalReleaseYear],
    ["show type", doc?.title?.type],
    ["mpm",       doc?.title.mpm],
    ["language",  doc?.title?.localized?.language],
    // crew credits
    ["Crew"],
    ["Role", "Talent", "Billing"],
    ...(getCreditsForRole({credits, role: RoleTypes.DUBBING_STUDIO, talents})),
    ...(getCreditsForRole({credits, role: RoleTypes.DUBBING_DIRECTOR, talents})),
    ...(getCreditsForRole({credits, role: RoleTypes.DUBBING_TRANSLATOR, talents})),
    ...(getCreditsWithExclusions({credits, exclusions, talents})),
    // character credits
    ["Voices - Main"],
    ["Localized Actor", "Localized Character", "Billing", "Original Character"],
    ...(originalCharacterCredits.length === 0
      ? [['']]  // make an empty row
      : originalCharacterCredits.map(originalCredit => {
        const characterAKA: CharacterAKA | undefined = !originalCredit.character ? undefined : getEntityAKA(characters, originalCredit.character)
        const originalCharacter = characterAKA?.original?.value
        const localization = characterAKA && (characterAKA.localizations || []).find(loc => loc.language === doc?.title?.localized?.language)
        const localizedCharacter = localization && localization.value

        const characterId = originalCredit?.character?.id
        const credit = drillDown(indexLocalizedCharacterCreditsByCharacterId, [characterId, 0])
        const localizedActor = credit && getProp(getEntityAKA(talents, credit.talent), ["value"])
        const billingOrder = (credit && credit.billingOrder !== null && credit.billingOrder) || originalCredit.billingOrder

        return [
          localizedActor || '',
          localizedCharacter || '',
          billingOrder || '',
          originalCharacter || '',
        ]
      })
    ),
    // non-character voices
    ["Voices - Other"],
    ...((voiceCredits.length === 0)
      ? [['']] // make an empty row
      : voiceCredits.map(credit => [
        getProp(getEntityAKA(talents, credit.talent), ["value"]) || '',
      ])
    ),
  ]
}

export const formatGraphQlError = (error: GraphQLError) => {
  if (error.message) return error.message
  if (error.errors) {
    return error.errors.map(e => e.message || JSON.stringify(e)).join('\n')
  }
  return JSON.stringify(error)
}

// const reducerFilterRolesForCharacters = (accum, title) => {
//   return [
//     ...accum,
//     ...(title.original.credits.filter(r => r.originalCharacterId).map(r => r.originalCharacterId)),
//   ]
// }

// const reducerFilterUnique = (accum, id) => {
//   if (accum[id]) {
//     return accum
//   }
//   return {
//     ...accum,
//     [id]: true,
//   }
// }

// export const filterFranchiseCharacters = (mpm, titlesById, titlesByProduct, titlesByFamily) => {
//   const episodeChildMpms = (titlesById[mpm] && titlesById[mpm].mpmProductNumber && titlesByProduct[titlesById[mpm].mpmProductNumber]) || []
//   const seasonParentMpm = titlesById[mpm] && titlesById[mpm].mpmProductNumber
//   const seasonChildMpms = ((titlesById[mpm] && titlesByFamily[mpm]) || []).map(t => t.mpm)
//   const seriesParentMpm = titlesById[mpm] && (titlesById[mpm].mpmFamilyNumber || (seasonParentMpm && titlesById[seasonParentMpm] && titlesById[seasonParentMpm].mpmFamilyNumber))
//   const franchiseTitles = [mpm, ...episodeChildMpms, seasonParentMpm, ...seasonChildMpms, seriesParentMpm].filter(m => m && titlesById[m]).map(m => titlesById[m])
//   return Object.keys(franchiseTitles
//     .reduce(reducerFilterRolesForCharacters, [])
//     .reduce(reducerFilterUnique, {}))
// }


// const makeRole = ({type, originalCharacter, character, isMasked}) => {
//   console.log('---- makeRole - originalCharacter', originalCharacter)
//   return {
//     type,
//     originalCharacter,
//     character,
//     isMasked,
//   }
// }

// const updateRoles = (talent, originalCharacter, field, value) => {
//   const foundRoleIndex = talent.roles.findIndex(r => r.originalCharacter === originalCharacter)
//   return {
//     ...talent,
//     ...(['fullName'].includes(field) ? {[field]: value} : {}),
//     roles: (foundRoleIndex === -1) ? [...talent.roles, makeRole({type: EnumTalentRole.Actor, originalCharacter, [field]: value, })] : [
//       ...talent.roles.slice(0, foundRoleIndex),
//       {
//         ...(talent.roles[foundRoleIndex]),
//         ...(!(['fullName'].includes(field)) ? {[field]: value} : {}),
//       },
//       ...talent.roles.slice(foundRoleIndex + 1),
//     ]
//   }
// }




























export const getSortedTalent = ({talents}: GetSortedTalentParams) => {
  const flatTalent = talents //Object.keys(talents).map(tId => talents[tId])
  const sortedTalent = flatTalent.sort(sortByKey({ key: ['updatedOn'], order: true }))
  return sortedTalent
}

const chooseBehavior = ({atomCharacter, candidates, minScore}: ChooseBehaviorParams): AtomCharacterImportBehavior => {
  const billingOrder = atomCharacter.billingOrder

  if (candidates.length < 1) {
    return {
      mode: BehaviorMode.CREATE_NEW,
      candidates,
      ...(billingOrder ? {billingOrder} : {}),
    }
  }

  // assumes the candidates are pre-sorted by score
  if (candidates[0].score > minScore) {
    return {
      mode: BehaviorMode.USE_EXISTING,
      candidates,
      character: drillDown(candidates, '0.character'.split('.')),
      ...(billingOrder ? {billingOrder} : {}),
    }
  }

  return {
    mode: BehaviorMode.CREATE_NEW,
    candidates,
    ...(billingOrder ? {billingOrder} : {}),
  }
}

export const reducerComputeBehavior = ({matchCandidates, minScore}: ReducerComputeBehaviorParams) => (accum: AtomCharacterImportBehaviorByNameMap, atomCharacter: AtomCharacter): AtomCharacterImportBehaviorByNameMap => {
  const candidates = matchCandidates[atomCharacter.characterName]
  const behavior = chooseBehavior({atomCharacter, candidates, minScore})

  return { ...accum, [atomCharacter.characterName]: behavior }
}

const matchSort = <T extends {score: number}>(a: T, b: T) => {
  return b.score - a.score
}

export const reducerRankMatchCandidates = (unwoundAkas: CharacterUnwoundbyAKAs[]) => (accum: AtomCharacterCandidatesByNameMap, atomCharacter: AtomCharacter): AtomCharacterCandidatesByNameMap => {
  const candidates = unwoundAkas.map(character => {
    const score = stringSimilarity(atomCharacter.characterName, character.AKAs.original.value)

    return {
      atomCharacter,
      character,
      score,
    }
  }).sort(matchSort)

  return {
    ...accum,
    [atomCharacter.characterName]: candidates,
  }
}

export const reducerImportedAtomCharactersToApiBehaviors = (atomImportData: AtomCharacterImportBehaviorByNameMap) => (accum: AtomCharacterImportBehaviorApi[], characterName: string): AtomCharacterImportBehaviorApi[] => {
  const {mode, character, ignore, billingOrder} = atomImportData[characterName]

  if (ignore) {
    return accum
  }

  return [
    ...accum,
    {
      characterName,
      mode,
      ...((mode !== BehaviorMode.USE_EXISTING || !character) ? {} : {
        character: {
          id: character.id,
          aka: character.AKAs.id,
        },
      }),
      ...(billingOrder ? {billingOrder} : {}),
    },
  ]
}

export const reducerRankFranchiseCharacters = ({
  xlsxRolesByCharacterName,
  unwoundFranchiseCharacterAKAs,
}: ReducerRankFranchiseCharactersParams) => (accum: LoadedXlsxCharacterCandidateByNameMap, xlsxCharacterName: string): LoadedXlsxCharacterCandidateByNameMap => {

  const xlsxRole = xlsxRolesByCharacterName[xlsxCharacterName][0]

  // M x 1
  const scores: CharacterCandidate[] = unwoundFranchiseCharacterAKAs.map(franchiseCharacter => {
    const score = stringSimilarity(franchiseCharacter.AKAs.original.value, xlsxCharacterName)
    return {
      score,
      franchiseCharacter,
    }
  }).sort(matchSort)

  return {
    ...accum,
    [xlsxCharacterName]: {
      xlsxRole,
      scores,
    },
  }
}

export const reducerRankTalentSearches = ({
  xlsxRolesByTalentName,
  talentSearchesByQuery,
}: ReducerRankTalentSearchesParams) => (accum: LoadedXlsxTalentCandidateByNameMap, xlsxTalentName: string): LoadedXlsxTalentCandidateByNameMap => {
  const xlsxRole = xlsxRolesByTalentName[xlsxTalentName][0]

  const talentSearchResults: Talent[] = drillDown(talentSearchesByQuery, [xlsxTalentName, 0, 'result']) || []

  const unwoundSearchResultAkas: TalentUnwoundByAKAs[] = unwindByKey(talentSearchResults, ['AKAs'])

  // M x 1
  const scores: TalentCandidate[] = unwoundSearchResultAkas.map(talent => {
    const score = stringSimilarity(talent.AKAs.value, xlsxTalentName)
    return {
      score,
      talent,
    }
  }).sort(matchSort)

  return {
    ...accum,
    [xlsxTalentName]: {
      xlsxRole,
      scores,
    },
  }
}

const mergeXlsxImportState = (accum: ImportingBehavior, updates: ImportingBehaviorUpdates): ImportingBehavior => ({
  ...accum,
  credits: [
    ...accum.credits,
    ...updates.credits,
  ],
  charactersByName: {
    ...accum.charactersByName,
    ...updates.charactersByName,
  },
  talentsByName: {
    ...accum.talentsByName,
    ...updates.talentsByName,
  },
})

export const stringToInt = (val: string | number): number => {
  if (typeof val === 'number') {
    return val
  }

  return parseInt(val, 10)
}

export const reducerXlsxToImportBehavior = ({
  matchScoresFranchiseCharacterByXlsxCharacterName,
  matchScoresTalentSearchByXlsxTalentName,
  minScoreCharacter,
  minScoreTalent,
}: ReducerXlsxToImportBehaviorParams) => (accum: ImportingBehavior, xlsxRole: LoadedXlsxRole): ImportingBehavior => {
  // console.log('ImportReview/useEffect, role:', xlsxRole)

  const isCharacter = (xlsxRole.isCharacter)
  // const isVoice = (xlsxRole.type === RoleTypes.DUBBING_VOICE && !xlsxRole.isCharacter)
  // const isCrew = !isCharacter && !isVoice

  const credit: ImportBehaviorCredit = {
    role: xlsxRole.type,
    xlsxRole,
    ...(!xlsxRole.billing ? {} : {
      billingOrder: stringToInt(xlsxRole.billing),
    }),
  }

  const updates: ImportingBehaviorUpdates = {
    credits: [credit],
    charactersByName: {},
    talentsByName: {},
  }

  if (isCharacter) {
    if (xlsxRole.originalCharacterName) {
      if (!accum.charactersByName[xlsxRole.originalCharacterName]) {
        // check if we have a franchise character for that name already
        const franchise = matchScoresFranchiseCharacterByXlsxCharacterName[xlsxRole.originalCharacterName]
        if (franchise.scores && franchise.scores.length > 0) {
          const best = franchise.scores[0]
          if (best.score >= minScoreCharacter) {
            const entity = {
              id: best.franchiseCharacter.id,
              aka: best.franchiseCharacter.AKAs.id,
            }
            updates.charactersByName[xlsxRole.originalCharacterName] = entity
            credit.character = {
              mode: BehaviorMode.USE_EXISTING,
              entity,
            }
          } else {
            const entity = {
              id: uuidV4(),
              aka: uuidV4(),
            }
            updates.charactersByName[xlsxRole.originalCharacterName] = entity
            credit.character = {
              mode: BehaviorMode.CREATE_NEW,
              entity,
            }
          }
        } else {
          const entity = {
            id: uuidV4(),
            aka: uuidV4(),
          }
          updates.charactersByName[xlsxRole.originalCharacterName] = entity
          credit.character = {
            mode: BehaviorMode.CREATE_NEW,
            entity,
          }
        }
      } else {
        const entity = accum.charactersByName[xlsxRole.originalCharacterName]
        credit.character = {
          mode: BehaviorMode.USE_EXISTING,
          entity,
        }
      }
    }
  }

  if (!accum.talentsByName[xlsxRole.talentName]) {
    const searchResultScores = matchScoresTalentSearchByXlsxTalentName[xlsxRole.talentName].scores || []
    if (searchResultScores.length > 0) {
      const best = searchResultScores[0]
      if (best.score >= minScoreTalent) {
        const entity = {
          id: best.talent.id,
          aka: best.talent.AKAs.id,
        }
        updates.talentsByName[xlsxRole.talentName] = entity

        credit.talent = {
          mode: BehaviorMode.USE_EXISTING,
          entity,
        }
      } else {
        const entity = {
          id: uuidV4(),
          aka: uuidV4(),
        }
        updates.talentsByName[xlsxRole.talentName] = entity

        credit.talent = {
          mode: BehaviorMode.CREATE_NEW,
          entity,
        }
      }
    } else {
      const entity = {
        id: uuidV4(),
        aka: uuidV4(),
      }
      updates.talentsByName[xlsxRole.talentName] = entity
      credit.talent = {
        mode: BehaviorMode.CREATE_NEW,
        entity,
      }
    }
  } else {
    const entity = accum.talentsByName[xlsxRole.talentName]
    credit.talent = {
      mode: BehaviorMode.USE_EXISTING,
      entity,
    }
  }

  // if (credit.character) {
  //   console.log('reducerXlsxToImportBehavior: character name', credit.xlsxRole.originalCharacterName, credit.character.mode)
  // }
  // console.log('reducerXlsxToImportBehavior: talent name', credit.xlsxRole.talentName, credit.talent.mode)
  return mergeXlsxImportState(accum, updates)
}

const computeImportXlsxBehaviorsSetup = ({
  importedXlsx,
  unwoundFranchiseCharacterAKAs,
}: ComputeImportXlsxBehaviorsSetupParams): ComputeImportXlsxBehaviorSetupOutput => {

  // length N
  const xlsxRoles = (importedXlsx && importedXlsx.data && importedXlsx.data.roles) || []
  const xlsxRolesByCharacterName = indexByKey(xlsxRoles.filter(r => (r.isCharacter && r.originalCharacterName)), ['originalCharacterName'])
  const xlsxRolesByTalentName = indexByKey(xlsxRoles.filter(r => r.talentName), ['talentName'])

  // complexity: N x M
  const matchScoresFranchiseCharacterByXlsxCharacterName = Object.keys(xlsxRolesByCharacterName)
    .reduce(reducerRankFranchiseCharacters({
      xlsxRolesByCharacterName,
      unwoundFranchiseCharacterAKAs,
    }), {})

  return {
    xlsxRoles,
    xlsxRolesByTalentName,
    matchScoresFranchiseCharacterByXlsxCharacterName,
  }
}

export const computeImportXlsxBehaviorsTalentSearchHandler = ({
  xlsxRoles,
  xlsxRolesByTalentName,
  matchScoresFranchiseCharacterByXlsxCharacterName,
  minScoreCharacter,
  minScoreTalent,
}: ComputeImportXlsxBehaviorsTalentSearchHandlerParams) => (talentSearchResults: ComputeImportXlsxBehaviorsTalentSearchResponse[]): ImportingBehavior => {

  // length T[key]
  const talentSearchesByQuery = indexByKey(talentSearchResults, ['query'])

  // complexity: N x T[key]
  const matchScoresTalentSearchByXlsxTalentName = Object.keys(xlsxRolesByTalentName)
    .reduce(reducerRankTalentSearches({
      xlsxRolesByTalentName,
      talentSearchesByQuery,
    }), {})

  // complexity: N x 1
  const initBehaviors: ImportingBehavior = {credits: [], charactersByName: {}, talentsByName: {}, matchScoresTalentSearchByXlsxTalentName}
  const behaviors: ImportingBehavior = xlsxRoles.reduce(reducerXlsxToImportBehavior({
    matchScoresTalentSearchByXlsxTalentName,
    matchScoresFranchiseCharacterByXlsxCharacterName,
    minScoreCharacter,
    minScoreTalent,
  }), initBehaviors)

  return behaviors
}

export const computeImportXlsxBehaviorsInit = ({
  unwoundFranchiseCharacterAKAs, // length M
  importedXlsx,
  minScoreCharacter,
  minScoreTalent,
}: ComputeImportXlsxBehaviorsInitParams): ImportingBehavior => {
  const {
    xlsxRoles,
    xlsxRolesByTalentName,
    matchScoresFranchiseCharacterByXlsxCharacterName,
  } = computeImportXlsxBehaviorsSetup({ importedXlsx, unwoundFranchiseCharacterAKAs })

  // defer search call in order to initialize state with correct structure
  const talentSearchResults: ComputeImportXlsxBehaviorsTalentSearchResponse[] = []

  const result: ImportingBehavior = computeImportXlsxBehaviorsTalentSearchHandler({
    xlsxRoles,
    xlsxRolesByTalentName,
    matchScoresFranchiseCharacterByXlsxCharacterName,
    minScoreCharacter,
    minScoreTalent,
  })(talentSearchResults)

  return result
}

export const computeImportXlsxBehaviors = async ({
  unwoundFranchiseCharacterAKAs, // length M
  importedXlsx,
  minScoreCharacter,
  minScoreTalent,
  searchTalent,
}: ComputeImportXlsxBehaviorsParams): Promise<ImportingBehavior> => {

  const {
    xlsxRoles,
    xlsxRolesByTalentName,
    matchScoresFranchiseCharacterByXlsxCharacterName,
  } = computeImportXlsxBehaviorsSetup({ importedXlsx, unwoundFranchiseCharacterAKAs })

  const talentSearches: Promise<ComputeImportXlsxBehaviorsTalentSearchResponse>[] = Object.keys(xlsxRolesByTalentName)
    .map(xlsxTalentName => searchTalent({akaValue: xlsxTalentName})
      .then(result => ({ result, query: xlsxTalentName })))

  // search in parallel because each lambda will search against mongo atlas,
  // and atlas should be able to handle the concurrency.
  const talentSearchResults: ComputeImportXlsxBehaviorsTalentSearchResponse[] = await Promise.all(talentSearches)

  const result: ImportingBehavior = computeImportXlsxBehaviorsTalentSearchHandler({
    xlsxRoles,
    xlsxRolesByTalentName,
    matchScoresFranchiseCharacterByXlsxCharacterName,
    minScoreCharacter,
    minScoreTalent,
  })(talentSearchResults)

  return result
}

export const reducerXlsxCreditToImportApi = (accum: ImportCreditsFromXlsxParams, creditInfo: ImportBehaviorCredit): ImportCreditsFromXlsxParams => {
  if (creditInfo.ignore) {
    return accum
  }

  const credit: InputImportCredit = {
    role: creditInfo.role,
    talent: creditInfo.talent,
    ...(creditInfo?.character ? {character: creditInfo.character} : {}),
    ...(creditInfo?.billingOrder ? {billingOrder: creditInfo.billingOrder} : {}),
  }

  const characters: ImportEntityInfo[] = []

  if (
    (creditInfo?.character?.mode === BehaviorMode.CREATE_NEW)
    && (!!creditInfo.xlsxRole.originalCharacterName)
  ) {
    characters.push({
      name: creditInfo.xlsxRole.originalCharacterName,
      entity: creditInfo.character.entity,
    })
  }

  const talents: ImportEntityInfo[] = []

  if (creditInfo?.talent?.mode === BehaviorMode.CREATE_NEW) {
    talents.push({
      name: creditInfo.xlsxRole.talentName,
      entity: creditInfo.talent.entity,
    })
  }

  const result: ImportCreditsFromXlsxParams = {
    ...accum,
    ...((characters.length === 0)
      ? {characters: accum.characters}
      : {characters: [...accum.characters, ...characters]}
    ),
    ...((talents.length === 0)
      ? {talents: accum.talents}
      : {talents: [...accum.talents, ...talents]}
    ),
    credits: [
      ...accum.credits,
      credit,
    ],
  }

  return result
}


// const regexCrew = /Crew/
const regexRole = /Role/
const regexVoicesMain = /Voices - Main/
const regexLocalizedActor = /Localized Actor/

const findCrewBegin = (rows: string[][]) => {
  // console.log('findCrewBegin, rows', rows)
  const indexRole = rows.findIndex(row => regexRole.test(row[0]))
  if (indexRole > -1) {
    return indexRole + 1
  }
  const indexByRole = rows.findIndex(row => isRole(row[0]))
  return indexByRole
}

const findCrewEnd = (rows: string[][]) => {
  const indexByNextHeading = rows.findIndex(row => regexVoicesMain.test(row[0]))
  if (indexByNextHeading > 0) {
    return indexByNextHeading - 1
  }

  const indexByNextColumnHeader = rows.findIndex(row => regexLocalizedActor.test(row[0]))
  if (indexByNextColumnHeader > 0) {
    return indexByNextColumnHeader - 1
  }

  return -1
}

export const sheetToCreditsModel = (rows: string[][]): LoadedXlsx => {
  const titleKeyRow = rows.find(row => row[0] === 'mpm')
  const languageRow = rows.find(row => row[0] === 'language')

  const crewRowIndexBegin = findCrewBegin(rows)
  const crewRowIndexEnd = findCrewEnd(rows)
  const crewRows = (crewRowIndexBegin > -1 && crewRowIndexEnd > crewRowIndexBegin)
    ? rows.slice(crewRowIndexBegin, crewRowIndexEnd + 1)
    : []
  const crew = crewRows.filter(c => c[0] && c[1])
  // console.log('crewRowIndexBegin', crewRowIndexBegin)
  const charactersRowIndex = rows.findIndex(row => row[0] === 'Localized Actor')
  const voicesOtherRowIndex = rows.findIndex(row => row[0] === 'Voices - Other')
  const characters = charactersRowIndex === -1 ? [] : rows.slice(1 + charactersRowIndex, voicesOtherRowIndex !== -1 ? voicesOtherRowIndex : undefined).filter(row => {
    return (row.length > 0) && (row[0] || row[1])
  })
  const voicesOther = voicesOtherRowIndex === -1 ? [] : rows.slice(1 + voicesOtherRowIndex).filter(row => {
    return (row.length > 0) && row[0]
  })

  if (!languageRow || !languageRow[1]) {
    throw new Error('not able to determine language when parsing imported data')
  }

  if (!titleKeyRow || !titleKeyRow[1]) {
    throw new Error('not able to determine title when parsing imported data')
  }

  const language = languageRow[1]
  const mpm = titleKeyRow[1]

  return {
    mpm,
    language,
    roles: [
      ...(crew.map(c => ({
        type: c[0],
        talentName: c[1],
        billing: c[2],
      }))),
      ...(characters.map(c => ({
        type: RoleTypes.DUBBING_VOICE,
        talentName: c[0],
        localizedCharacterName: c[1],
        billing: c[2],
        originalCharacterName: c[3],
        isCharacter: true,
        // originalCharacterId: c[4],
        // showRole: c[5],
      }))),
      ...(voicesOther.map(c => ({
        type: RoleTypes.DUBBING_VOICE,
        talentName: c[0],
        billing: c[2],
        // localizedCharacterName: c[1],
        // originalCharacterName: c[3],
        // originalCharacterId: c[4],
        // showRole: c[5],
      }))),
    ],
  }
}
