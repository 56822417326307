
import {
  makeStyles,
  Typography
} from '@material-ui/core'

const useStyles = makeStyles(theme => ({
  ErrorDisplay: {
    height: '100%',
    overflow: 'auto',
    color: theme.palette.error.main,
  },
}))

const ErrorDisplay = ({
  message,
}) => {
  const classes = useStyles()

  return (
    <div className={classes.ErrorDisplay}>
      <div>
        <Typography variant="h4">ERROR</Typography>
      </div>
      <div>
        <Typography>{message}</Typography>
      </div>
    </div>
  )
}

export default ErrorDisplay
