import { useCallback, useEffect, useState } from "react"

import { Autocomplete } from "@material-ui/lab"
import { makeStyles, TextField } from "@material-ui/core"

// import ComboBox from "../ComboBox"

import clsx from 'clsx'

import { useDebounce } from "../../lib/hooks"
import { unwindByKey } from "deepdown"

import {bulk} from 'dubcard'
const {BehaviorMode} = bulk

const reasonInput = 'input'
const reasonClear = 'clear'

const useStylesAutoComplete = makeStyles(theme => ({
  root: {
    flex: 1,
    minWidth: 160,
    // '& MuiFormControl-root': {
    //   paddingTop: theme.spacing(1),
    // },
    // '& label': {
    //   paddingLeft: theme.spacing(1),
    // },
    // '& MuiInputBase-formControl': {
    //   paddingLeft: theme.spacing(1),
    // },
  },
}))

const ImportingExistingTalent = ({
  credit,
  importBehaviors,
  talents,
  searchTalent,
  onChangeTalent,
  className,
}) => {

  const classes = useStylesAutoComplete()

  const [searchState, setSearchState] = useState({
    fetching: false,
    unwoundResults: (importBehaviors?.matchScoresTalentSearchByXlsxTalentName[credit.xlsxRole.talentName]?.scores || []).map(({talent}) => talent),
    error: null,
  })
  const mode = credit?.talent?.mode
  const talent = talents && credit && credit?.talent?.entity?.id && talents[credit.talent.entity.id]
  // console.log('ImportingExistingTalent talentName', credit.xlsxRole.talentName, 'talent', talent)
  const unwoundTalentAkas = (talent && unwindByKey([talent], ['AKAs'])) || []
  const unwoundTalentAka = unwoundTalentAkas.find(t => t.AKAs.id === credit?.talent?.entity?.aka)

  // const [value, setValue] = useState(aka || null)
  const value = unwoundTalentAka || null
  // console.log('ImportingExistingTalent talentName', credit.xlsxRole.talentName, 'value', value)
  const [inputValue, setInputValue] = useState(unwoundTalentAka?.AKAs?.value ? unwoundTalentAka?.AKAs?.value : '')
  const [inputValueReason, setInputValueReason] = useState(null)
  const debouncedInputValue = useDebounce(inputValue, 500)

  useEffect(() => {
    if (inputValueReason !== reasonInput) {
      // console.log('ImportingExistintTalent/useEffect, inputValueReason', inputValueReason)
      return
    }
    console.log('ImportingExistintTalent/useEffect, debouncedInputValue', debouncedInputValue)

    if (!debouncedInputValue || debouncedInputValue.length < 3) {
      return
    }

    let needsCancel = false

    setSearchState({fetching: true, query: debouncedInputValue, unwoundResults: null})
    searchTalent({akaValue: debouncedInputValue}).then(results => {
      if (needsCancel) {
        return
      }

      const unwoundResults = unwindByKey(results, ['AKAs'])
      setSearchState(ss => ({...ss, unwoundResults, fetching: false}))
    }).catch(error => {
      if (needsCancel) {
        return
      }

      setSearchState(ss => ({...ss, error, fetching: false}))
    })

    return () => {
      needsCancel = true
    }
  }, [debouncedInputValue, inputValueReason, searchTalent])

  const onInputChange = useCallback((event, inputV, reason) => {
    // console.log('*** ImportingExistingTalent/onInputChange reason', reason, 'input value', inputV)
    setInputValue(inputV)
    setInputValueReason(reason)
  }, [])

  const onChangeValue = useCallback((event, newValue, reason) => {
    console.log('ImportingExistingTalent/onChangeValue - newValue', newValue)
    if (reason === reasonClear || newValue === null) {
      return onChangeTalent(null)
    }
    onChangeTalent({id: newValue.id, aka: newValue.AKAs.id})
  }, [onChangeTalent])

  const renderInput = useCallback(params => {
    return (
      <TextField
        {...params}
        label={(mode !== BehaviorMode.USE_EXISTING) ? "Not Using" : (value?.id) ? "Using Talent" : "Choose"}
      />
    )
  }, [value, mode])

  const getOptionSelected = useCallback((o, v) => {
    return (o.id === v.id) && (o?.AKAs?.id === v?.AKAs?.id)
  }, [])

  const getOptionLabel = useCallback(option => {
    return option?.AKAs?.value || (typeof option === 'string' ? option : 'error')
  }, [])

  return (
    <Autocomplete
      classes={{root: clsx(className, classes.root)}}
      options={searchState.unwoundResults || []}
      renderInput={renderInput}
      getOptionLabel={getOptionLabel}
      getOptionSelected={getOptionSelected}
      value={value}
      onChange={onChangeValue}
      freeSolo
      disabled={mode !== BehaviorMode.USE_EXISTING}
      inputValue={inputValue}
      onInputChange={onInputChange}
    />
  )
}

export default ImportingExistingTalent
