import React from 'react'

const inlineMedia = image => ({
  backgroundImage: `url(${image})`,
})

const DivBgImg = ({children, className, onLoad, onError, image, alt='not visible'}) => {
  const styleHiddenImg = {display: 'none'}
  return (
    <div className={className} style={inlineMedia(image)}>
      <img src={image} onError={onError} onLoad={onLoad} style={styleHiddenImg} alt={alt} />
      {children}
    </div>
  )
}

export default DivBgImg
