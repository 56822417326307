"use strict";
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.matchCredit = exports.organizeCreditsForEditor = exports.getSortedRoles = exports.billingSort = exports.defaultOverrideHash = exports.defaultOverrideHashCreditElements = exports.defaultOverrideHashOverrideElements = exports.defaultFallbackHash = exports.reduceCharactersByMpm = exports.filterRoles = exports.indexByHash = exports.reducerCharacters = exports.reducerTalents = exports.reducerTitles = void 0;
const deepdown_1 = require("deepdown");
const role_types_1 = require("./role-types");
const reducerTitles = (accum, t) => (Object.assign(Object.assign({}, accum), { [t.mpm]: t }));
exports.reducerTitles = reducerTitles;
const reducerTalents = (accum, t) => (Object.assign(Object.assign({}, accum), { [t.id]: t }));
exports.reducerTalents = reducerTalents;
const reducerCharacters = (accum, c) => (Object.assign(Object.assign({}, accum), { [c.id]: c }));
exports.reducerCharacters = reducerCharacters;
const indexByHash = (array, hash) => {
    const index = array.reduce((accum, elem) => {
        const h = hash(elem);
        if (!accum[h]) {
            // add new array
            return Object.assign(Object.assign({}, accum), { [h]: [elem] });
        }
        // append existing array
        return Object.assign(Object.assign({}, accum), { [h]: [...accum[h], elem] });
    }, {});
    return index;
};
exports.indexByHash = indexByHash;
const filterRoles = ({ includes, excludes, checkCharacter }) => (credit) => {
    return (includes ? includes.includes(credit.role) : true)
        && (excludes ? !excludes.includes(credit.role) : true)
        && (checkCharacter ? ((!!(0, deepdown_1.drillDown)(credit, ['character', 'id'])) === checkCharacter.value) : true);
};
exports.filterRoles = filterRoles;
const reducerCharactersByMpm = ({ charAkasGroupedByMpm, charsGroupedById }) => (accum, mpm) => {
    const existingCharsAtMpm = accum[mpm] || [];
    const finalCharsAtMpm = [...existingCharsAtMpm];
    const existingGroupedById = (0, deepdown_1.indexByKey)(existingCharsAtMpm, ['id']);
    const charAkasCandidatesAtMpm = charAkasGroupedByMpm[mpm];
    const candidatesAtMpmGroupedById = (0, deepdown_1.indexByKey)(charAkasCandidatesAtMpm, ['id']);
    Object.keys(candidatesAtMpmGroupedById).forEach((id) => {
        if (!existingGroupedById[id]) {
            const needToPush = charsGroupedById[id];
            needToPush.forEach(needTo => {
                finalCharsAtMpm.push(needTo);
            });
        }
    });
    return Object.assign(Object.assign({}, accum), { [mpm]: finalCharsAtMpm });
};
const reduceCharactersByMpm = (charactersByMpm, characters) => {
    const charsGroupedById = (0, deepdown_1.indexByKey)(characters, ['id']);
    const unwoundCharAkas = (0, deepdown_1.unwindByKey)(characters, ['AKAs']);
    const unwoundCharacterAkasMpms = (0, deepdown_1.unwindByKey)(unwoundCharAkas, ['AKAs', 'mpm']);
    const charAkasGroupedByMpm = (0, deepdown_1.indexByKey)(unwoundCharacterAkasMpms, ['AKAs', 'mpm']);
    return Object.keys(charAkasGroupedByMpm).reduce(reducerCharactersByMpm({ charAkasGroupedByMpm, charsGroupedById }), charactersByMpm);
};
exports.reduceCharactersByMpm = reduceCharactersByMpm;
const defaultFallbackHash = (credit) => {
    return (0, deepdown_1.drillDown)(credit, ['talent', 'id']);
};
exports.defaultFallbackHash = defaultFallbackHash;
const defaultOverrideHashSeparator = '/';
const defaultOverrideHashKeyValSep = '::';
exports.defaultOverrideHashOverrideElements = [
    { key: 'role', path: 'match.role'.split('.') },
    { key: 'talent', path: 'match.talent.id'.split('.') },
    { key: 'character', path: 'match.character.id'.split('.') },
];
exports.defaultOverrideHashCreditElements = [
    { key: 'role', path: 'role'.split('.') },
    { key: 'talent', path: 'talent.id'.split('.') },
    { key: 'character', path: 'character.id'.split('.') },
];
const defaultOverrideHash = (elementsToCheck = exports.defaultOverrideHashCreditElements) => (overrideCredit) => {
    return elementsToCheck
        .map((elem) => elem.key + defaultOverrideHashKeyValSep + (0, deepdown_1.drillDown)(overrideCredit, elem.path))
        .join(defaultOverrideHashSeparator);
};
exports.defaultOverrideHash = defaultOverrideHash;
const hasCharacter = credit => {
    return !!(0, deepdown_1.drillDown)(credit, ['character', 'id']);
};
// sort the credits
//
// the value of role is not important;
// assume credits are within the same group for sorting,
// e.g. "all crew roles", or "all cast roles"
const billingSort = ({ ascending = true, 
// hash function used to populate indexOverrides
overrideHash = (0, exports.defaultOverrideHash)(), indexOverrides = {}, 
// hash function used to populate indexFallbacks
fallbackHash = exports.defaultFallbackHash, indexFallbacks = {}, } = {}) => (a, b) => {
    // https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Array/sort
    /*
    - If compareFunction(a, b) returns less than 0, leave a and b unchanged.
    - If compareFunction(a, b) returns 0, leave a and b unchanged with respect to each other, but sorted with respect to all different elements.
    - If compareFunction(a, b) returns greater than 0, sort b before a.
    */
    const aOverride = (0, deepdown_1.drillDown)(indexOverrides, [overrideHash(a), 0]);
    const bOverride = (0, deepdown_1.drillDown)(indexOverrides, [overrideHash(b), 0]);
    const aFallback = (0, deepdown_1.drillDown)(indexFallbacks, [fallbackHash(a), 0]);
    const bFallback = (0, deepdown_1.drillDown)(indexFallbacks, [fallbackHash(b), 0]);
    // specificity, left to right
    const aBilling = (aOverride && aOverride.value && aOverride.value.billingOrder)
        || a.billingOrder
        || (aFallback && aFallback.billingOrder);
    const bBilling = (bOverride && bOverride.value && bOverride.value.billingOrder)
        || b.billingOrder
        || (bFallback && bFallback.billingOrder);
    if (!aBilling && !bBilling) {
        return 0;
    }
    if (!aBilling) {
        return ascending ? 1 : -1;
    }
    if (!bBilling) {
        return ascending ? -1 : 1;
        // return 0
    }
    if (aBilling === bBilling) {
        return 0;
    }
    return (aBilling < bBilling)
        ? (ascending) ? -1 : 1
        : (ascending) ? 1 : -1;
};
exports.billingSort = billingSort;
const preventHidden = ({ indexOverrideMatches = {}, overrideHashFn = (0, exports.defaultOverrideHash)(exports.defaultOverrideHashCreditElements) } = {}) => credit => {
    const hidden = indexOverrideMatches && (0, deepdown_1.drillDown)(indexOverrideMatches, [overrideHashFn(credit), 0, 'value', 'hidden']);
    return !hidden;
};
const swapOverrideTalent = ({ indexOverrideMatches = {}, overrideHashFn = (0, exports.defaultOverrideHash)(exports.defaultOverrideHashCreditElements) } = {}) => credit => {
    const talent = indexOverrideMatches && (0, deepdown_1.drillDown)(indexOverrideMatches, [overrideHashFn(credit), 0, 'value', 'talent']);
    if (talent) {
        const { talent: omitted } = credit, role = __rest(credit, ["talent"]);
        return Object.assign(Object.assign({}, role), { talent });
    }
    return credit;
};
const getSortedRoles = (editorLists) => {
    return [
        // parent
        ...((editorLists.season ? editorLists.season.sortedLocalizedCrew : [])
            .filter(preventHidden(editorLists.season || {}))),
        ...((editorLists.season ? editorLists.season.sortedLocalizedCast : [])
            .filter(preventHidden(editorLists.season || {}))
            .map(swapOverrideTalent(editorLists.season || {}))),
        ...((editorLists.season ? editorLists.season.sortedLocalizedVoices : [])
            .filter(preventHidden(editorLists.season || {}))),
        // title
        ...(editorLists.title.sortedLocalizedCrew || []),
        ...(editorLists.title.sortedLocalizedCast || []),
        ...(editorLists.title.sortedLocalizedVoices || []),
    ];
};
exports.getSortedRoles = getSortedRoles;
const organizeTitleCredits = (metadata, lang, child) => {
    var _a;
    const localizedDocs = (metadata && metadata.localized) || [];
    const originalDoc = localizedDocs.find(ldoc => ldoc.original);
    const indexLocalizedByLang = (0, deepdown_1.indexByKey)(localizedDocs, ['language']);
    const originalLanguage = ((_a = metadata.original) === null || _a === void 0 ? void 0 : _a.language) || (originalDoc === null || originalDoc === void 0 ? void 0 : originalDoc.language);
    if (!originalLanguage) {
        return {};
    }
    const fallbackHash = c => (0, deepdown_1.drillDown)(c, ['character', 'id']);
    const originalCredits = (0, deepdown_1.drillDown)(indexLocalizedByLang, [originalLanguage, 0, 'credits']) || [];
    const indexFallbacks = (0, deepdown_1.indexByKey)(originalCredits, ['character', 'id']);
    const childLocalized = (child && child.metadata && child.metadata.localized && child.metadata.localized.find(loc => loc.language === lang));
    const override = childLocalized && childLocalized.overrides && childLocalized.overrides.find(ov => ov.mpm === metadata.mpm);
    const overrideCredits = ((override && override.credits) || []);
    const indexOverrideMatches = (0, exports.indexByHash)(overrideCredits, (0, exports.defaultOverrideHash)(exports.defaultOverrideHashOverrideElements));
    const overrideHashFn = (0, exports.defaultOverrideHash)(exports.defaultOverrideHashCreditElements);
    const localizedCredits = (0, deepdown_1.drillDown)(indexLocalizedByLang, [lang, '0', 'credits']) || [];
    const localizedCreditsWithCharacter = localizedCredits.filter(hasCharacter);
    const indexLocalizedByOriginalCharacter = (0, deepdown_1.indexByKey)(localizedCreditsWithCharacter, ['character', 'id']);
    const localizedCreditsGroupedByRole = (0, deepdown_1.indexByKey)(localizedCredits || [], ['role']);
    const sortedLocalizedPerformances = Object.keys(localizedCreditsGroupedByRole)
        .filter(role => (0, role_types_1.isCastRole)(role))
        .map(role => localizedCreditsGroupedByRole[role])
        .reduce((accum, list) => [...accum, ...list], []);
    const sortedLocalizedCast = sortedLocalizedPerformances
        .filter(credit => hasCharacter(credit))
        .sort((0, exports.billingSort)({
        indexFallbacks,
        fallbackHash,
        indexOverrides: indexOverrideMatches,
        overrideHash: overrideHashFn,
    }));
    const sortedLocalizedVoices = sortedLocalizedPerformances
        .filter(credit => !hasCharacter(credit))
        .sort((0, exports.billingSort)({
        indexOverrides: indexOverrideMatches,
        overrideHash: overrideHashFn,
    }));
    // to make a spot in the editor for each character
    const sortedOriginalCharacters = [...originalCredits]
        .filter(hasCharacter)
        .sort((0, exports.billingSort)());
    const sortedLocalizedCrew = Object.keys(localizedCreditsGroupedByRole)
        .filter(role => !(0, role_types_1.isCastRole)(role))
        .map(role => localizedCreditsGroupedByRole[role])
        .reduce((accum, list) => [...accum, ...list], [])
        .sort((0, exports.billingSort)({
        indexOverrides: indexOverrideMatches,
        overrideHash: overrideHashFn,
    }));
    return {
        metadata,
        indexLocalizedByOriginalCharacter,
        indexOverrideMatches,
        overrideHashFn,
        sortedOriginalCharacters,
        sortedLocalizedCrew,
        sortedLocalizedCast,
        sortedLocalizedVoices,
    };
};
const organizeCreditsForEditor = (mpm, titles, lang) => {
    if (!titles[mpm]) {
        return {};
    }
    const currentTitle = titles[mpm];
    const seasonTitle = currentTitle && currentTitle.mpmProductNumber && titles[currentTitle.mpmProductNumber];
    // const seriesTitle = currentTitle && (currentTitle.mpmFamilyNumber ? titles[currentTitle.mpmFamilyNumber] : (seasonTitle && seasonTitle.mpmFamilyNumber && titles[seasonTitle.mpmFamilyNumber]))
    const title = organizeTitleCredits(currentTitle, lang);
    const season = seasonTitle && organizeTitleCredits(seasonTitle, lang, title);
    return {
        title,
        season,
    };
};
exports.organizeCreditsForEditor = organizeCreditsForEditor;
// --- utility --- //
// `matchCredit` should be used like: `array.find(matchCredit(criteria))`.
//
// it attempts to find a match with the least criteria, similar to how
// mongo would return the first match it finds within an array.
//
// however, if `restrict` is enabled, additional criteria will be added
// to prvent character credits from being identified as non-character credits,
// mimicking the extra DML required to specify non-character credits only.
const matchCredit = (toMatch, restrict = true) => candidate => {
    return (candidate.role === toMatch.role)
        && (candidate.talent.id === toMatch.talent.id)
        // && (candidate.talent.aka === toMatch.talent.aka)
        && (!toMatch.character ? true : (candidate.character && (candidate.character.id === toMatch.character.id)))
        // && (!toMatch.character ? true : (candidate.character.aka === toMatch.character.aka))
        && (!restrict ? true : (!candidate.character) ? true : (toMatch.character && (candidate.character.id === toMatch.character.id)));
};
exports.matchCredit = matchCredit;
