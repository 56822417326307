import { FC } from 'react'

import {
  alpha,
  Button,
  Grid,
  makeStyles,
  Tabs,
  Tab,
  GridJustification,
  GridItemsAlignment
} from '@material-ui/core'

import Bcp47Chip from '../Bcp47Chip'

import {
  FetchStateComputeImportBehavior,
  FetchStateGetLocalizedTitle,
  ImportedXlsx,
  LMTLanguageByBcp47Map,
  OnChangeTabSelection
} from '../../types'

import Filler from '../Filler'
import LanguageSelector from '../LanguageSelector'
import clsx from 'clsx'
import { SelectInputProps } from '@material-ui/core/Select/SelectInput'
import { LocalizedTitle } from 'dubcard'
// import { BCP47Languages } from 'dubcard'

const useStyles = makeStyles(theme => ({
  button: {
    minWidth: 100,
  },
  ViewTitle: {
    whiteSpace: 'nowrap',
  },
  DocumentActions: {
    padding: `0 ${theme.spacing(1)}px`,
    gap: theme.spacing(1),
    // padding: theme.spacing(1),
  },
  ImportHeader: {
    paddingTop: theme.spacing(1),
  },
  // indicates no choice has been made yet
  LanguageSelectorMissing: {
    backgroundColor: alpha(theme.palette.warning.main, 0.25),
  },
  // indicates a user choice was required
  LanguageSelectorChoice: {
    backgroundColor: alpha(theme.palette.secondary.main, 0.25),
  },
}))

interface RowGroupParams {
  className?: string | undefined
  justifyContent?: GridJustification
  alignItems?: GridItemsAlignment
}

const RowGroup: FC<RowGroupParams> = ({
  children,
  className,
  justifyContent = "flex-start",
  alignItems = "center",
}) => {
  return (
    <Grid container direction="row" alignItems={alignItems} justifyContent={justifyContent} wrap="nowrap" className={className}>
      {children}
    </Grid>
  )
}

export interface ImportReviewHeaderProps {
  importedXlsx: ImportedXlsx
  LMTBcp47ToDescription: LMTLanguageByBcp47Map
  enableImport: boolean
  selectedTab: number
  targetDetailsState: FetchStateGetLocalizedTitle
  onClickSaveImport: any
  onClickCancelImport: any
  onChangeTabSelection: OnChangeTabSelection
  onChangeOriginalLanguage: SelectInputProps['onChange']
  // selectedOriginalLanguage: BCP47
  behaviorsState: FetchStateComputeImportBehavior
  title: LocalizedTitle
  originalLanguage: string | undefined
  selectedOriginalLanguage: string
}

const ImportHeader: FC<ImportReviewHeaderProps> = ({
  importedXlsx,
  enableImport,
  targetDetailsState,
  behaviorsState,
  onClickSaveImport,
  onClickCancelImport,
  LMTBcp47ToDescription,
  selectedTab,
  onChangeTabSelection,
  onChangeOriginalLanguage,
  title,
  originalLanguage,
  selectedOriginalLanguage,
}) => {
  const classes = useStyles()
  const language = importedXlsx.data.language

  return (
    <RowGroup alignItems="flex-end" className={classes.ImportHeader}>

      <RowGroup className={classes.DocumentActions}>
        <Tabs
          value={selectedTab}
          indicatorColor="primary"
          textColor="primary"
          onChange={onChangeTabSelection}
        >
          <Tab label="Importing Credits" />
          {/* <Tab label="Existing Credits" /> */}
        </Tabs>
      </RowGroup>

      <RowGroup justifyContent="center" className={classes.DocumentActions}>
        <RowGroup justifyContent="center">
          <LanguageSelector {...{
            disabled: !!originalLanguage || !title || targetDetailsState.fetching,
            label: "Original Language",
            LMTBcp47ToDescription,
            onChangeLanguage: onChangeOriginalLanguage,
            lang: originalLanguage || selectedOriginalLanguage,
            id: 'import-language-selector',
            className: clsx(!originalLanguage
              ? !selectedOriginalLanguage
                ? classes.LanguageSelectorMissing
                : classes.LanguageSelectorChoice
              : null
            ),
          }} />
        </RowGroup>
      </RowGroup>

      <RowGroup justifyContent="flex-end" className={classes.DocumentActions}>
        <Filler />
        <Bcp47Chip code={language} description={(!language || !LMTBcp47ToDescription[language]) ? 'unknown language' : LMTBcp47ToDescription[language]} />
        <Button id="import-review-save" onClick={onClickSaveImport} variant="contained" color="secondary" disabled={!enableImport} className={classes.button}>save</Button>
        <Button id="import-review-cancel" onClick={onClickCancelImport} variant="text" color="default" className={classes.button}>cancel</Button>
      </RowGroup>
    </RowGroup>
  )
}

export default ImportHeader
