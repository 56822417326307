import { useCallback, useState } from 'react'

import { drillDown } from 'deepdown'
import { Grid, IconButton, makeStyles, Typography } from '@material-ui/core'

import TitleRoleEditor from './TitleRoleEditor'
import { ExpandLess, ExpandMore } from '@material-ui/icons'

const useStyles = makeStyles(theme => ({
  divider: {
    marginTop: theme.spacing(1),
    backgroundColor: theme.palette.grey[300],
  },
  expand: {
    flex: 1,
  },
}))

const CreditsGroup = ({
  isSeason,
  isCharacter,
  season,
  title,
  lang,
  talents,
  characters,
  updateBillingForLocalizedCredit,
  updateLocalizationToCharacterAka,
  onClickDeleteCredit,
  onClickMask,
  onClickDeleteOverride,
  onClickSelectTalentForCharacter,
  elementKey,
  heading,
}) => {
  const classes = useStyles()
  const elements = (isSeason ? (season && season[elementKey]) : (title && title[elementKey])) || []

  const [expanded, setExpanded] = useState(true)

  const onClickExpand = useCallback(() => {
    setExpanded(exp => !exp)
  }, [setExpanded])

  return (
    <>
      {(elements.length > 0) && (
        <Grid container direction="row" wrap="nowrap" className={classes.divider}>
          <Grid container direction="row" wrap="nowrap" alignItems="center" className={classes.expand}></Grid>
          <Grid container direction="row" justifyContent="center" alignItems="center" wrap="nowrap" className={classes.expand}>
            <Typography align="center">{heading}</Typography>
          </Grid>
          <Grid container direction="row" justifyContent="flex-end" wrap="nowrap" alignItems="center" className={classes.expand}>
            <IconButton onClick={onClickExpand}>
              {expanded ? <ExpandMore /> : <ExpandLess />}
            </IconButton>
          </Grid>
        </Grid>
      )}
      {expanded && elements.map(info => {
        const original = isCharacter && info
        const originalCharacterId = original && drillDown(original, ['character', 'id'])
        const localized = !isCharacter
          ? info
          : isSeason
            ? (originalCharacterId && drillDown(season.indexLocalizedByOriginalCharacter, [originalCharacterId, 0]))
            : (originalCharacterId && drillDown(title.indexLocalizedByOriginalCharacter, [originalCharacterId, 0]))

        const localizedOverride = isSeason && localized && title.overrideHashFn && drillDown(season.indexOverrideMatches, [title.overrideHashFn(localized), 0])
        const localizedTalentId = localized && localized?.talent?.id

        return (
          <TitleRoleEditor
            key={`/talent-editors/${heading}/${original ? originalCharacterId: localized.role}/${localizedTalentId}`}
            {...{
              readOnly: isSeason,
              lang,
              talents,
              characters,
              original,
              localized,
              localizedOverride,
              title: title.metadata,
              updateBillingForLocalizedCredit,
              updateLocalizationToCharacterAka,
              onClickDeleteCredit: onClickDeleteCredit && onClickDeleteCredit({ localized }),
              onClickMask: onClickMask && onClickMask({original, localized, localizedOverride}),
              onClickSelectTalent: onClickSelectTalentForCharacter && onClickSelectTalentForCharacter({localized, localizedOverride, original, isOverride: isSeason }),
              onClickDeleteOverride: onClickDeleteOverride && onClickDeleteOverride({localized, localizedOverride}),
            }}
          />)
      }
      )}
    </>
  )
}

const SeasonCrew = props => <CreditsGroup {...{...props, ...{elementKey: 'sortedLocalizedCrew', heading: `Season Studio/Crew`, isSeason: true}}} />

const TitleCrew = props => <CreditsGroup {...{...props, ...{elementKey: 'sortedLocalizedCrew', heading: `${props.season ? 'Episode ' : ''}Studio/Crew`}}} />

const SeasonCast = props => <CreditsGroup {...{...props, ...{elementKey: 'sortedOriginalCharacters', heading: `Season Cast`, isSeason: true, isCharacter: true}}} />

const TitleCast = props => <CreditsGroup {...{...props, ...{elementKey: 'sortedOriginalCharacters', heading: `${props.season ? 'Episode ' : ''}Cast`, isCharacter: true}}} />

const SeasonVoices = props => <CreditsGroup {...{...props, ...{elementKey: 'sortedLocalizedVoices', heading: `Season Voices`, isSeason: true}}} />

const TitleVoices = props => <CreditsGroup {...{...props, ...{elementKey: 'sortedLocalizedVoices', heading: `${props.season ? 'Episode ' : ''}Voices`}}} />


const TabCredits = ({
  lang,
  characters,
  talents,
  editorLists,
  updateLocalizationToCharacterAka,
  updateBillingForLocalizedCredit,
  onClickSelectTalentForCharacter,
  onClickMask,
  onClickDeleteOverride,
  onClickDeleteCredit,
}) => {
  // console.log('---- TabTitle, lang', lang)

  const { title, season } = editorLists

  // console.log('TabTitle, sortedOriginalCharacters', sortedOriginalCharacters)

  return (
    <>
      <SeasonCrew   {...{season, title, lang, talents, onClickDeleteOverride, updateBillingForLocalizedCredit, onClickMask}} />
      <TitleCrew    {...{season, title, lang, talents, onClickDeleteCredit, updateBillingForLocalizedCredit}} />
      <SeasonCast   {...{season, title, lang, talents, characters, onClickSelectTalentForCharacter, updateBillingForLocalizedCredit, onClickMask, onClickDeleteOverride}} />
      <TitleCast    {...{season, title, lang, talents, characters, onClickSelectTalentForCharacter, updateBillingForLocalizedCredit, updateLocalizationToCharacterAka, onClickDeleteCredit}} />
      <SeasonVoices {...{season, title, lang, talents, updateBillingForLocalizedCredit, onClickMask}} />
      <TitleVoices  {...{season, title, lang, talents, updateBillingForLocalizedCredit, onClickDeleteCredit }} />
    </>
  )
}

export default TabCredits
