import { Grid, IconButton, makeStyles } from "@material-ui/core"
import { Visibility, VisibilityOff } from "@material-ui/icons"

const useStyles = makeStyles(theme => ({
  Actions: {
    maxWidth: 64,
  },
}))

const ImportingActions = ({credit, onClickIgnore}) => {
  const classes = useStyles()
  return (
    <Grid container direction="row" alignItems="center" justifyContent="flex-end" wrap="nowrap" className={classes.Actions}>
      <IconButton onClick={onClickIgnore}>
        {credit.ignore ? <VisibilityOff color="secondary" /> : <Visibility />}
      </IconButton>
    </Grid>
  )
}

export default ImportingActions
