import { useCallback } from "react"
import { Grid, IconButton, makeStyles, Tooltip } from "@material-ui/core"
import { Close, Share } from "@material-ui/icons"
import Filler from '../Filler'
import TalentAka from './TalentAka'
import TalentAkaInputBar from './TalentAkaInputBar'

const useStyles = makeStyles(theme => ({
  TalentDetails: {
    height: '100%',
  },
  TalentDetailsContent: {
    flex: 1,
    overflow: 'auto',
    padding: theme.spacing(1),
  },
}))

const ShareEntity = () => {
  const onClick = useCallback(() => {
    const value = window.location.href
    navigator.clipboard.writeText(value)
  }, [])

  return (
    <Tooltip title="Copy URL to clipboard">
      <IconButton onClick={onClick}>
        <Share color="secondary" />
      </IconButton>
    </Tooltip>
  )
}

const CloseDetails = ({
  onClickCloseDetails,
}) => {
  return (
    <Tooltip title="Close talent details">
      <IconButton onClick={onClickCloseDetails}>
        <Close />
      </IconButton>
    </Tooltip>
  )
}

const DetailsBar = ({
  onClickCloseDetails,
}) => {
  return (
    <Grid container direction="row" wrap="nowrap">
      <CloseDetails {...{onClickCloseDetails}} />
      <Filler />
      <ShareEntity />
    </Grid>
  )
}

const TalentDetailsHeader = ({
  onClickCloseDetails,
  selected,
  addAkasToTalent,
}) => {
  return (
    <div>
      <DetailsBar {...{onClickCloseDetails}} />
      <TalentAkaInputBar {...{selected, addAkasToTalent}} />
    </div>
  )
}

const TalentDetailsContent = ({
  talent,
  updateTalentAka,
}) => {
  const classes = useStyles()
  return (
    <div className={classes.TalentDetailsContent}>
      {((talent && talent.AKAs) || []).map(aka => (
        <TalentAka key={`/talent/${talent.id}/aka/${aka.id}`} {...{talent, aka, updateTalentAka}}  />
      ))}
    </div>
  )
}

const TalentDetails = ({
  selected,
  onClickCloseDetails,
  updateTalentAka,
  addAkasToTalent,
}) => {
  const classes = useStyles()

  if (!selected) {
    return null
  }

  return (
    <Grid container direction="column" wrap="nowrap" className={classes.TalentDetails}>
      <TalentDetailsHeader {...{
        onClickCloseDetails,
        selected,
        addAkasToTalent,
      }} />
      <TalentDetailsContent {...{
        talent: selected,
        updateTalentAka,
      }} />
    </Grid>
  )
}

export default TalentDetails
