
import React, { useState, useCallback, useEffect } from 'react'

import {
  makeStyles,
  Button,
  Grid,
  Typography
} from '@material-ui/core'

import DropDown from '../DropDown'
import TalentSelectForm from './TalentSelectForm'

import {roles} from 'dubcard'

import {useDebounce} from '../../lib/hooks'
import {getSortedTalent} from '../../lib/models'

const { RoleTypes: EnumCreditRole} = roles

const roleOptions = Object.keys({ '': 'Choose', ...EnumCreditRole }).map(key => ({
  value: EnumCreditRole[key],
  display: EnumCreditRole[key],
}))

const useStyles = makeStyles(theme => ({
  expand: {
    flex: 1,
  },
  AddRoleForm: {
    padding: theme.spacing(2),
  },
}))

const AddRoleHeader = ({
  roleOptions,
  selectedRole,
  onChangeRole,
  onClickAddRoleCancel,
}) => {
  const classes = useStyles()

  return (
    <div>
      <Grid container direction="row" alignItems="center" wrap="nowrap">
        <Grid item>
          <Typography variant="h5">ADD A ROLE</Typography>
        </Grid>
        <Grid item className={classes.expand}></Grid>
        <Grid item>
          <Button variant="text" onClick={onClickAddRoleCancel}>CANCEL</Button>
        </Grid>
      </Grid>
      <Grid container direction="row" alignItems="center">
        <DropDown label="Role Types" value={selectedRole || ''} options={roleOptions} onChange={onChangeRole} className={classes.expand} />
      </Grid>
    </div>
  )
}

const AddRoleForm = ({
  createTalent,
  searchTalent,
  addCreditsToLocalizedTitle,
  onClickAddRoleCancel,
  debounceMs=500,
}) => {
  const classes = useStyles()
  // const [credit, setCredit] = useState({role: null, talent: {id: null, aka: null}, character: null})
  // const isValid = validCredit(credit)
  const [selectedRole, setSelectedRole] = useState(null)
  const [inputValue, setInputValue] = useState('')
  const debouncedInputValue = useDebounce(inputValue, debounceMs)
  const [searchState, setSearchState] = useState({fetching: false, query: null, results: [], error: null})
  const sortedTalent = getSortedTalent({talents: searchState.results || []})

  const onChangeInputValue = useCallback((e) => {
    // console.log('AddRoleForm/onChangeInputValue', e.target.value)
    setInputValue(e.target.value)
  }, [setInputValue])

  const onChangeRole = useCallback(e => {
    // console.log('AddRoleForm/onChangeRole', e.target.value)
    setSelectedRole(e.target.value)
  }, [setSelectedRole])

  const onClickCreateTalent = useCallback(() => {
    const model = {
      AKAs: [
        {
          value: inputValue,
          // language:
        },
      ],
    }
    createTalent(model).catch(error => {
      console.error('AddRoleForm/onClickCreate/createTalent, error : ', error)
    }).then(result => {
      console.log('AddRoleForm/onClickCreate/createTalent, result : ', result)
      return addCreditsToLocalizedTitle({role: selectedRole, talent: result})
    }).catch(error => {
      console.error('AddRoleForm/addCreditsToLocalizedTitle/createTalent, error : ', error)
    })
  }, [inputValue, selectedRole, createTalent, addCreditsToLocalizedTitle])

  const onClickSelectExistingTalentForRole = useCallback(({talent}) => () => {
    console.log('AddRoleForm/onClickSelectExistingTalentForCharacter', {talent})
    addCreditsToLocalizedTitle({talent, role: selectedRole}).catch(error => {
      console.error('AddRoleForm/onClickSelectExistingTalentForCharacter, error', error)
    })
  }, [selectedRole, addCreditsToLocalizedTitle])

  useEffect(() => {
    if (!debouncedInputValue || (debouncedInputValue.length < 3)) {
      return
    }

    let mounted = true

    const query = {akaValue: debouncedInputValue}
    const before = {query, fetching: true}
    setSearchState(before)
    searchTalent(query).then(results => {
      if (!mounted) {
        return
      }

      console.log('AddRoleForm/useEffect/searchTalent - results:', results)
      setSearchState({...before, fetching: false, results})
    }).catch(error => {
      console.error('AddRoleForm/useEffect/searchTalent - error:', error)
      setSearchState({...before, fetching: false, results: null, error})
    })

    return () => {
      mounted = false
    }
  }, [debouncedInputValue, setSearchState, searchTalent])

  return (
    <div className={classes.AddRoleForm}>
      <AddRoleHeader {...{
        selectedRole,
        roleOptions,
        onChangeRole,
        onClickAddRoleCancel,
      }} />
      <TalentSelectForm {...{
        disabled: !selectedRole,
        onClickSelectExistingTalent: onClickSelectExistingTalentForRole,
        onClickCreateTalent,
        onChangeInputValue,
        inputValue,
        sortedTalent,
        searchState,
      }} />
    </div>
  )
}

export default AddRoleForm
