import {FC, Fragment, useCallback, useEffect, useState} from 'react'

import {
  Grid,
  makeStyles
} from '@material-ui/core'

import TitleCharacter from './TitleCharacter'
import AtomImportContent from './AtomImportContent'
import CharacterViewHeader from './CharacterViewHeader'

import ExpandContainer from '../ExpandContainer'
import EmptyList from '../EmptyList'

import { drillDown, unwindByKey } from 'deepdown'

import {
  reducerRankMatchCandidates,
  reducerComputeBehavior,
  reducerImportedAtomCharactersToApiBehaviors,
  changeCharacterAkaOriginalValue,
  makeDefaultCreateCharacterParams
} from '../../lib/models'

import {
  InputAddTitlesToCharacterAka,
  AddTitlesToCharacterAkaResponse,
  bulk,
  InputCreateCharacter,
  CreateCharacterResponse,
  TitleCredit,
  GetAtomCharactersResponse,
  AtomCharacter,
  InputImportCharactersFromAtomResponse,
  InputImportCharactersFromAtom
} from 'dubcard'

import FranchiseCharacters from './FranchiseCharacters'
import { compile } from '../../lib/schema-utils'
import { definitions } from '../../lib/schema/original-language-character-view.json'

import {
  AddTitlesToCharacterAka,
  AtomCharacterCandidatesByNameMap,
  AtomCharacterImportBehaviorByNameMap,
  CharacterUnwoundBySeries,
  CreateCharacter,
  FetchStateCreateCharacter,
  FetchStateGetAtomCharacters,
  FetchStateImportAtomCharacters,
  GetAtomCharacters,
  ImportCharactersFromAtom,
  RemoveTitlesFromCharacterAka,
  UpdateBillingForOriginalCharacterCredit
} from '../../types'

const { BehaviorMode } = bulk

const selectCharacterIdFromCandidates = ['character', 'id']

const useStyles = makeStyles(theme => ({
  PageSection: {
    margin: theme.spacing(1),
  },
}))


const validateAddCharacter = compile({
  $ref: "#/definitions/AddCharacter",
  definitions,
})

interface TitleCharactersProps {
  characterCredits: TitleCredit[]
  title: any
  characters: any
  updateBillingForOriginalCharacterCredit: any
  removeTitlesFromCharacterAka: any
}

const TitleCharacters: FC<TitleCharactersProps> = ({
  characterCredits,
  title,
  characters,
  updateBillingForOriginalCharacterCredit,
  removeTitlesFromCharacterAka,
}) => {
  return (
    <Fragment>
      {
        characterCredits.map((credit) => {
          const charId = credit && drillDown( credit, ['character', 'id'])
          const character = characters && characters[charId]
          return (
            <TitleCharacter {...{
              key: `title/original/character/${charId}`,
              title,
              credit,
              character,
              updateBillingForOriginalCharacterCredit,
              removeTitlesFromCharacterAka,
            }} />)
        })
      }
    </Fragment>
  )
}

export interface CharacterViewProps {
  LMTBcp47ToDescription: any
  editorLists: any
  characters: any
  franchiseCharacters: CharacterUnwoundBySeries[]
  title: any
  original: any
  minScore: number

  // api
  createCharacter: CreateCharacter
  addTitlesToCharacterAka: AddTitlesToCharacterAka
  removeTitlesFromCharacterAka: RemoveTitlesFromCharacterAka
  updateBillingForOriginalCharacterCredit: UpdateBillingForOriginalCharacterCredit
  getAtomCharacters: GetAtomCharacters
  importCharactersFromAtom: ImportCharactersFromAtom
}

const CharacterView: FC<CharacterViewProps> = ({
  LMTBcp47ToDescription,
  editorLists,
  characters,
  franchiseCharacters,
  title,
  original,
  createCharacter,
  addTitlesToCharacterAka,
  removeTitlesFromCharacterAka,
  updateBillingForOriginalCharacterCredit,
  getAtomCharacters,
  importCharactersFromAtom,
  minScore = 0.6,
}) => {

  const classes = useStyles()
  const originalLanguage = title?.original?.language || original?.language
  const [selectedOriginalLanguage, setSelectedOriginalLanguage] = useState('')
  const [addCharacterFilter, setAddCharacterFilter] = useState<FetchStateCreateCharacter>({
    fetching: false,
    query: makeDefaultCreateCharacterParams({
      mpm: [title.mpm],
      language: originalLanguage || selectedOriginalLanguage,
    }),
  })
  const [showFranchise, setShowFranchise] = useState(false)
  const [showAtomImport, setShowAtomImport] = useState(false)
  const [atomImportData, setAtomImportData] = useState<AtomCharacterImportBehaviorByNameMap>({})
  const [matchCandidates, setMatchCandidates] = useState<AtomCharacterCandidatesByNameMap>({})
  const [atomCharacters, setAtomCharacters] = useState<FetchStateGetAtomCharacters>({fetching: false})
  const [atomImport, setAtomImport] = useState<FetchStateImportAtomCharacters>({fetching: false})
  const hasOriginalLanguage = !!originalLanguage || !!selectedOriginalLanguage

  const atomImportNeedsInput = ((atomCharacters.response) || []).reduce((accum, atomCharacter) => {
    return accum || (
      (drillDown(atomImportData, [atomCharacter.characterName, 'ignore']) !== true)
      && (drillDown(atomImportData, [atomCharacter.characterName, 'mode']) === BehaviorMode.USE_EXISTING)
      && !drillDown(atomImportData, [atomCharacter.characterName, 'character', 'id'])
    )
  }, false)

  const atomImportDataEmpty = Object.keys(atomImportData).reduce((accum: boolean, characterName: string): boolean => {
    const {ignore} = atomImportData[characterName]
    return accum && !!ignore
  }, true)

  const characterCredits: TitleCredit[] = (editorLists?.title?.sortedOriginalCharacters || [])
  const atomImportDisabled = atomImportNeedsInput || atomImport.fetching || atomImportDataEmpty || !hasOriginalLanguage

  // const filterMatchesFranchiseAka = false
  //!filter.query || filter.query.length===0 || filterMatchesFranchiseAka
  const addCharacterDisabled = !validateAddCharacter({
    originalCharacterName: addCharacterFilter.query?.AKAs[0].original.value,
    titleOriginalLanguage: originalLanguage,
    ...(!selectedOriginalLanguage ? {} : {selectedOriginalLanguage}),
  }) || addCharacterFilter.fetching

  useEffect(() => {
    // length M
    const unwoundAkas = (franchiseCharacters && unwindByKey(franchiseCharacters, ['AKAs'])) || []

    // N x M complexity
    const candidateMatches = (atomCharacters.response || []).reduce(reducerRankMatchCandidates(unwoundAkas), {})
    setMatchCandidates(candidateMatches)

    // N x 1 complexity
    const importData = (atomCharacters.response || []).reduce(reducerComputeBehavior({matchCandidates: candidateMatches, minScore}), {})

    setAtomImportData(importData)
  }, [atomCharacters.response, franchiseCharacters, minScore])

  useEffect(() => {
    const query = {mpm: [title.mpm]}

    let mounted = true

    setAtomCharacters({fetching: true, query})
    getAtomCharacters(query).then((response: GetAtomCharactersResponse) => {
      if (!mounted) {
        return
      }
      console.log(`CharacterView/useEffect/getAtomCharacters, response: ${JSON.stringify(response)}`)
      setAtomCharacters(state => ({...state, fetching: false, response}))
    }).catch((error: any) => {
      if (!mounted) {
        return
      }

      console.error(`CharacterView/useEffect/getAtomCharacters, query (${JSON.stringify(query)}), error: ${JSON.stringify(error)}`)
      setAtomCharacters(state => ({...state, fetching: false, error}))
    })

    return () => {
      mounted = false
    }
  }, [title.mpm, getAtomCharacters, setAtomCharacters])

  const onClickIgnoreItem = useCallback(data => () => {
    const key = data.characterName

    setAtomImportData(x => ({
      ...x,
      [key]: {
        ...(x[key] || {}),
        ignore: x[key] ? !x[key].ignore : true,
      },
    }))
  }, [setAtomImportData])

  const onChangeMode = useCallback(data => (e: React.ChangeEvent<{ name?: string; value: unknown }>) => {
    const key = data.characterName

    // console.log(`OriginalTitleView/CharacterView/onChangeMode[${key}], value =`, e.target.value)

    setAtomImportData(x => ({
      ...x,
      [key]: {
        ...(x[key] || {}),
        mode: e.target.value,
      },
    }))
  }, [setAtomImportData])

  const onChangeCharacter = useCallback((data: AtomCharacter) => (e: React.ChangeEvent<{ name?: string; value: unknown }>) => {
    const key = data.characterName

    // console.log(`OriginalTitleView/CharacterView/onChangeCharacter[${key}], value =`, e.target.value)
    const candidates = matchCandidates[key]

    const selected = candidates.find(c => drillDown(c, selectCharacterIdFromCandidates) === e.target.value)
    if (!selected) {
      console.error(`OriginalTitleView/CharacterView/onChangeCharacter - did not identify user choice [${e.target.value}] from candidates: ${JSON.stringify(candidates)}`)
      return
    }

    const {character} = selected

    console.log('onChangeCharacter, selected character', character)

    debugger
    // setAtomImportData(state => ({
    //   ...state,
    //   [key]: {
    //     ...(state[key] || {}),
    //     character,
    //   },
    // }))
  }, [matchCandidates])

  const onChangeFilterQuery = useCallback(e => {
    setAddCharacterFilter(previous => ({
      ...previous,
      query: changeCharacterAkaOriginalValue(previous.query
        || makeDefaultCreateCharacterParams({
          mpm: [title.mpm],
          language: originalLanguage || selectedOriginalLanguage,
        }), 0, e.target.value),
    }))
  }, [setAddCharacterFilter, title.mpm, originalLanguage, selectedOriginalLanguage])

  const onClickShowAtomImport = useCallback(() => {
    setShowAtomImport(true)
  }, [setShowAtomImport])

  const onClickCancelAtomImport = useCallback(() => {
    setShowAtomImport(false)
    setAtomImport({fetching: false})
  }, [setShowAtomImport])

  const onClickShowFranchise = useCallback(() => {
    setShowFranchise(true)
  }, [setShowFranchise])

  const onClickCancelAddCharacter = useCallback(() => {
    setShowFranchise(false)
    setAddCharacterFilter(previous => ({
      ...previous,
      query: makeDefaultCreateCharacterParams({
        mpm: [title.mpm],
        language: '',
      }),
    }))
  }, [setShowFranchise, setAddCharacterFilter, title])

  const onClickImportFromAtom = useCallback(() => {
    console.log('CharacterView/onClickImportFromAtom', atomImportData)

    const input: InputImportCharactersFromAtom = {
      mpm: title.mpm,
      behaviors: Object.keys(atomImportData).reduce(reducerImportedAtomCharactersToApiBehaviors(atomImportData), []),
    }

    if (!originalLanguage && selectedOriginalLanguage) {
      input.titleOriginalLanguage = selectedOriginalLanguage
    }

    setAtomImport({fetching: true, query: input})
    importCharactersFromAtom(input).then((results: InputImportCharactersFromAtomResponse) => {
      console.log('CharacterView/onClickImportFromAtom/importCharactersFromAtom, results', results)
      setAtomImport(d => ({...d, fetching: false, results, error: null}))
      setShowAtomImport(false)
    }).catch((error: any) => {
      console.log('CharacterView/onClickImportFromAtom/importCharactersFromAtom, error', error)
      setAtomImport(d => ({...d, fetching: false, results: null, error}))
    // setShowAtomImport(false)
    })
  }, [title.mpm, atomImportData, importCharactersFromAtom, setAtomImport, selectedOriginalLanguage, originalLanguage])

  const onClickAddNewCharacter = useCallback(() => {
    if (!addCharacterFilter.query) {
      return
    }

    const model: InputCreateCharacter = {AKAs: addCharacterFilter.query.AKAs}

    if (!originalLanguage && !!selectedOriginalLanguage) {
      // specify title original language
      model.titleOriginalLanguage = selectedOriginalLanguage

      // specify character AKA language
      model.AKAs[0].original.language = selectedOriginalLanguage
    }

    console.log('onClickAddNewCharacter', model)
    setAddCharacterFilter(previous => ({...previous, fetching: true}))
    createCharacter(model).then((response: CreateCharacterResponse) => {
      console.log('CharacterView/onClickAddNewCharacter/createCharacter, result:', response)
      setAddCharacterFilter({fetching: false})
      setShowFranchise(false)
    }).catch((error: any) => {
      console.error('CharacterView/onClickAddNewCharacter/createCharacter, error', error)
      setAddCharacterFilter(previous => ({...previous, fetching: false, error}))
    })
  }, [originalLanguage, addCharacterFilter, setAddCharacterFilter, setShowFranchise, createCharacter, selectedOriginalLanguage])

  const onClickSelectExistingCharacter = useCallback(character => () => {
    console.log('CharacterView/onClickSelectExistingCharacter', character)
    const model: InputAddTitlesToCharacterAka = {
      id: character.id,
      akaId: character.AKAs[0].id,
      mpm: [title.mpm],
    }
    setAddCharacterFilter({fetching: true, ...model})
    addTitlesToCharacterAka(model).then((result: AddTitlesToCharacterAkaResponse) => {
      console.log('CharacterView/onClickSelectExistingCharacter, result : ', result)
      setAddCharacterFilter({fetching: false})
      setShowFranchise(false)
    }).catch((error: any) => {
      console.error('CharacterView/onClickSelectExistingCharacter, error : ', error)
    })
  }, [addTitlesToCharacterAka, title.mpm])

  const onChangeSelectedOriginalLanguage = useCallback((e) => {
    const value = e.target.value
    console.log('CharacterView/onChangeSelectedOriginalLanguage', value)
    setSelectedOriginalLanguage(value)
  }, [setSelectedOriginalLanguage])

  return (
    <Grid container direction="column" wrap="nowrap" className={classes.PageSection}>
      <CharacterViewHeader {...{
        atomImportDisabled,
        addCharacterDisabled,
        onClickImportFromAtom,
        characterCredits,
        addCharacterFilter,
        showFranchise,
        showAtomImport,
        onClickShowAtomImport,
        onClickCancelAtomImport,
        onChangeFilterQuery,
        onClickAddNewCharacter,
        onClickShowFranchise,
        onClickCancelAddCharacter,
      }} />
      <ExpandContainer>
        {(showAtomImport)
          ? (
            <AtomImportContent {...{
              onChangeSelectedOriginalLanguage,
              selectedOriginalLanguage,
              originalLanguage,
              LMTBcp47ToDescription,
              franchiseCharacters,
              atomCharacters,
              atomImport,
              minScore,
              atomImportData,
              onClickIgnoreItem,
              onChangeMode,
              onChangeCharacter,
            }} />
          )
          : (showFranchise)
            ? <FranchiseCharacters {...{
              original: title?.original,
              originalLanguage,
              selectedOriginalLanguage,
              franchiseCharacters,
              onClickSelectExistingCharacter,
              onChangeSelectedOriginalLanguage,
              LMTBcp47ToDescription,
            }} />
            : (characterCredits.length === 0)
              ? <EmptyList label="No Characters Yet" />
              : <TitleCharacters {...{
                characterCredits,
                title,
                characters,
                updateBillingForOriginalCharacterCredit,
                removeTitlesFromCharacterAka,
              }} />
        }
      </ExpandContainer>
    </Grid>
  )
}

export default CharacterView
