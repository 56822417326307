
import DropDown from '../DropDown'

import {bulk} from 'dubcard'

const {BehaviorMode} = bulk

const options = [
  {display: BehaviorMode.USE_EXISTING, value: BehaviorMode.USE_EXISTING},
  {display: BehaviorMode.CREATE_NEW, value: BehaviorMode.CREATE_NEW},
]

const ImportingBehavior = ({
  mode,
  className,
  onChangeBehavior,
}) => {
  return (
    <DropDown
      onChange={onChangeBehavior}
      options={options}
      value={mode}
      className={className}
    />
  )
}

export default ImportingBehavior
