import { makeStyles} from "@material-ui/core"
import ImportingCharacter from './ImportingCharacter'
import ImportingName from "./ImportingName"
import { roles } from 'dubcard'

const {castRoles} = roles

const useStylesRole = makeStyles(theme => ({
  Role: {
    padding: `0 ${theme.spacing(1)}px`,
    gap: theme.spacing(1),
  },
  ImportingBehavior: {
    minWidth: 160,
    maxWidth: 160,
    width: 160,
    height: 24,
  },
  ImportingExistingCharacter: {
    width: 180,
    height: 24,
  },
  // expand: {
  //   flex: 1,
  //   minWidth: 160,
  //   height: 20,
  // },
}))


const Role = ({role}) => {
  const classes = useStylesRole()

  return (
    <>
      <ImportingName label="Role" value={role} />
      <span className={classes.ImportingBehavior} />
      <span className={classes.ImportingExistingCharacter} />
    </>
  )
}

const ImportingRole = ({
  credit,
  characters,
  classesBehavior,
  unwoundFranchiseCharacterAKAs,
  onChangeCharacter,
  onChangeBehaviorCharacter,
}) => {
  return (
    <>
      {(castRoles.includes(credit.role) && credit.character)
        ? <ImportingCharacter {...{credit, characters, classesBehavior, unwoundFranchiseCharacterAKAs, onChangeCharacter, onChangeBehaviorCharacter}} />
        : <Role {...{role: credit.role}} />
      }
    </>
  )
}

export default ImportingRole
