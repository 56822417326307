import React from 'react'
import TalentSelectHeader from './TalentSelectHeader'
import TalentSelectContent from './TalentSelectContent'

const TalentSelectForm = ({
  disabled,
  onClickSelectExistingTalent,
  onClickCreateTalent,
  onChangeInputValue,
  inputValue,
  sortedTalent,
  original,
  searchState,
}) => {

  return (
    <div>
      <TalentSelectHeader {...{ disabled, inputValue, onChangeInputValue, onClickCreateTalent, searchState }} />
      <TalentSelectContent {...{ disabled, sortedTalent, onClickSelectExistingTalent, original }} />
    </div>
  )
}

export default TalentSelectForm
