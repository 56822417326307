import React from 'react'

import { makeStyles } from '@material-ui/core'

const useStylesFiller = makeStyles(theme => ({
  expand: {
    flex: 1,
  },
}))

const Filler = () => {
  const classes = useStylesFiller()
  return <span className={classes.expand} />
}

export default Filler
