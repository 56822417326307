/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const searchTalent = /* GraphQL */ `
  query SearchTalent($input: InputSearchTalent!) {
    searchTalent(input: $input) {
      id
      AKAs {
        id
        value
        language
      }
    }
  }
`;
export const searchCharacter = /* GraphQL */ `
  query SearchCharacter($input: InputSearchCharacter!) {
    searchCharacter(input: $input) {
      id
      series
      AKAs {
        id
        mpm
        original {
          language
          value
        }
        localizations {
          language
          value
        }
      }
    }
  }
`;
export const findCharactersByFranchiseId = /* GraphQL */ `
  query FindCharactersByFranchiseId($input: InputFindCharactersByFranchiseId!) {
    findCharactersByFranchiseId(input: $input) {
      id
      series
      AKAs {
        id
        mpm
        original {
          language
          value
        }
        localizations {
          language
          value
        }
      }
    }
  }
`;
export const getAtomCharacters = /* GraphQL */ `
  query GetAtomCharacters($input: InputGetAtomCharacters!) {
    getAtomCharacters(input: $input) {
      characterName
      akaId
      role
      billingOrder
      talent {
        label
        uri
        objectUri
      }
    }
  }
`;
export const getTitleMetadata = /* GraphQL */ `
  query GetTitleMetadata($input: InputGetTitleMetadata!) {
    getTitleMetadata(input: $input) {
      hits {
        mpm
      }
      title {
        mpm
        mpmProductNumber
        mpmFamilyNumber
        type
        originallyAiredAs
        originalReleaseYear
        original {
          mpm
          language
          title
          credits {
            role
            talentName
            characterName
            billingOrder
          }
        }
        localized {
          mpm
          language
          original
          title
          credits {
            role
            talent {
              id
              aka
            }
            character {
              id
              aka
            }
            billingOrder
          }
          overrides {
            mpm
            language
            credits {
              match {
                role
                talent {
                  id
                }
                character {
                  id
                }
              }
              value {
                billingOrder
                talent {
                  id
                  aka
                }
                hidden
              }
            }
          }
        }
        poster {
          assetId
          assetSubType
          copyrightNotice
          renditions {
            renditionName
            url
          }
          usageTerritory
        }
        parentTitles {
          parentMpmNumber
          parentContentType
          parentOriginallyAiredAs
          isDefault
        }
      }
      character {
        id
        series
        AKAs {
          id
          mpm
          original {
            language
            value
          }
          localizations {
            language
            value
          }
        }
      }
      talent {
        id
        AKAs {
          id
          value
          language
        }
      }
    }
  }
`;
export const searchTitles = /* GraphQL */ `
  query SearchTitles($input: InputSearchTitles!) {
    searchTitles(input: $input) {
      hits {
        mpm
      }
      title {
        mpm
        mpmProductNumber
        mpmFamilyNumber
        type
        originallyAiredAs
        originalReleaseYear
        original {
          mpm
          language
          title
          credits {
            role
            talentName
            characterName
            billingOrder
          }
        }
        localized {
          mpm
          language
          original
          title
          credits {
            role
            talent {
              id
              aka
            }
            character {
              id
              aka
            }
            billingOrder
          }
          overrides {
            mpm
            language
            credits {
              match {
                role
                talent {
                  id
                }
                character {
                  id
                }
              }
              value {
                billingOrder
                talent {
                  id
                  aka
                }
                hidden
              }
            }
          }
        }
        poster {
          assetId
          assetSubType
          copyrightNotice
          renditions {
            renditionName
            url
          }
          usageTerritory
        }
        parentTitles {
          parentMpmNumber
          parentContentType
          parentOriginallyAiredAs
          isDefault
        }
      }
      character {
        id
        series
        AKAs {
          id
          mpm
          original {
            language
            value
          }
          localizations {
            language
            value
          }
        }
      }
      talent {
        id
        AKAs {
          id
          value
          language
        }
      }
    }
  }
`;
export const getRecentTitleUpdates = /* GraphQL */ `
  query GetRecentTitleUpdates {
    getRecentTitleUpdates {
      hits {
        mpm
      }
      title {
        mpm
        mpmProductNumber
        mpmFamilyNumber
        type
        originallyAiredAs
        originalReleaseYear
        original {
          mpm
          language
          title
          credits {
            role
            talentName
            characterName
            billingOrder
          }
        }
        localized {
          mpm
          language
          original
          title
          credits {
            role
            talent {
              id
              aka
            }
            character {
              id
              aka
            }
            billingOrder
          }
          overrides {
            mpm
            language
            credits {
              match {
                role
                talent {
                  id
                }
                character {
                  id
                }
              }
              value {
                billingOrder
                talent {
                  id
                  aka
                }
                hidden
              }
            }
          }
        }
        poster {
          assetId
          assetSubType
          copyrightNotice
          renditions {
            renditionName
            url
          }
          usageTerritory
        }
        parentTitles {
          parentMpmNumber
          parentContentType
          parentOriginallyAiredAs
          isDefault
        }
      }
      character {
        id
        series
        AKAs {
          id
          mpm
          original {
            language
            value
          }
          localizations {
            language
            value
          }
        }
      }
      talent {
        id
        AKAs {
          id
          value
          language
        }
      }
    }
  }
`;
export const listDeliverySettings = /* GraphQL */ `
  query ListDeliverySettings {
    listDeliverySettings {
      platform
      format
      width
      height
      bgFill
      layout {
        label
        height
        width
        roleSep
        headingFontSize
        talentFontSize
        roleVerticalPad
        verticalPad
        headingVerticalPad
      }
      bucket
      prefix
      filesTemplate
    }
  }
`;
export const getDeliverySettings = /* GraphQL */ `
  query GetDeliverySettings($input: InputGetDeliverySettingsForPlatform) {
    getDeliverySettings(input: $input) {
      platform
      format
      width
      height
      bgFill
      layout {
        label
        height
        width
        roleSep
        headingFontSize
        talentFontSize
        roleVerticalPad
        verticalPad
        headingVerticalPad
      }
      bucket
      prefix
      filesTemplate
    }
  }
`;
export const getDeliveries = /* GraphQL */ `
  query GetDeliveries($input: DeliveryQuery!) {
    getDeliveries(input: $input) {
      id
      createdOn
      submittedByUserId
      updatedOn
      status
      message
      platform
      settings {
        format
        width
        height
      }
      items {
        index
        svg
        storage {
          bucket
          path
        }
      }
    }
  }
`;
export const getUserAttributes = /* GraphQL */ `
  query GetUserAttributes($input: InputGetUserAttributes!) {
    getUserAttributes(input: $input) {
      Name
      Value
    }
  }
`;
