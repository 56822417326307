import { Grid, IconButton, makeStyles, TextField } from "@material-ui/core"
import LinkIcon from '@material-ui/icons/Link'
import {Link as RouterLink} from 'react-router-dom'
import { formatTitle } from "../../lib/models"

const useStyles = makeStyles(theme => ({
  AkaTitle: {},
  AkaTitleTextField: {
    flex: 1,
  },
}))

const AkaTitle = ({
  mpm,
  titles,
}) => {
  const classes = useStyles()

  return (
    <Grid container direction="row" wrap="nowrap" alignItems="center" className={classes.AkaTitle}>
      <IconButton component={RouterLink} to={'/mpm/' + mpm}>
        <LinkIcon />
      </IconButton>
      <TextField
        label={`MPM ${mpm}`}
        value={formatTitle(mpm, titles).join(' ')}
        disabled={true}
        InputProps={{ disableUnderline: true }}
        classes={{root: classes.AkaTitleTextField}}
      />
    </Grid>
  )
}

export default AkaTitle
