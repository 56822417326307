import Ajv from 'ajv'
// const keywords = require("ajv-keywords")
import AjvKeywords from 'ajv-keywords'
const addFormats = require('ajv-formats')

export const compile = (schema) => {
  const ajv = new Ajv({allErrors: true})

  // to support things like:
  // "instanceof": "Date"
  AjvKeywords(ajv, ["regexp"])

  // to support things like:
  // "format": "uuid"
  addFormats(ajv)

  return ajv.compile(schema)
}
