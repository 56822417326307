import React from 'react'
import { makeStyles, ListItem, ListItemText, Typography } from '@material-ui/core'
// import { Edit, FileCopy } from '@material-ui/icons'
import clsx from 'clsx'

const useStyles = makeStyles(theme => ({
  TalentItemSelected: {
    backgroundColor: theme.palette.secondary.light,
  },
  TalentItem: {

  },
  TalentItemActions: {
    textAlign: 'right',
    flex: 1,
  },
}))

const TalentSearchResultItem = ({
  talent,
  onClickSelect,
  isSelected,
}) => {
  const classes = useStyles()

  return (
    <ListItem
      button
      onClick={onClickSelect}
      classes={{
        root: clsx(classes.TalentItem, isSelected && classes.TalentItemSelected),
      }}
    >
      <ListItemText
        primary={( <Typography>{talent.AKAs[0].value}</Typography> )}
      />
    </ListItem>
  )

//   return (
// <Grid container direction="row" alignItems="center" className={clsx(classes.TalentItem, isSelected && classes.TalentItemSelected)}>
//   <Grid item>
//     <Tooltip title="Copy ID">
//       <IconButton onClick={onClickCopy(talent.id)}><FileCopy /></IconButton>
//     </Tooltip>
//   </Grid>
//   <Grid item>
//     <Typography>{talent.AKAs[0].value}</Typography>
//   </Grid>
// </Grid>
//   )
}

export default TalentSearchResultItem