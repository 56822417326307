import { FC } from "react"
import { List, ListItem, ListItemText } from "@material-ui/core"
import drillDown, { indexByKey } from "deepdown"
import { CharacterUnwoundBySeries, LMTLanguageByBcp47Map } from "../../types"
import SelectOriginalLanguage, { SelectOriginalLanguageProps } from "./SelectOriginalLanguage"
import { TitleOriginal } from "dubcard"

interface ExistingCharacterProps {
  character: CharacterUnwoundBySeries
  disabled: boolean
  onClickSelectExistingCharacter: any
}

const ExistingCharacter: FC<ExistingCharacterProps> = ({
  character,
  disabled,
  onClickSelectExistingCharacter,
}) => {
  const aka = character.AKAs[0]

  return (
    <ListItem button onClick={onClickSelectExistingCharacter} disabled={disabled}>
      <ListItemText primary={aka.original.value} />
    </ListItem>
  )
}

export interface FranchiseCharactersProps {
  original: TitleOriginal
  originalLanguage: string
  selectedOriginalLanguage: string
  franchiseCharacters: CharacterUnwoundBySeries[]
  onClickSelectExistingCharacter: any
  LMTBcp47ToDescription: LMTLanguageByBcp47Map
  onChangeSelectedOriginalLanguage: SelectOriginalLanguageProps['onChangeLanguage']
}

const FranchiseCharacters: FC<FranchiseCharactersProps> = ({
  original,
  originalLanguage,
  selectedOriginalLanguage,
  franchiseCharacters,
  onClickSelectExistingCharacter,
  LMTBcp47ToDescription,
  onChangeSelectedOriginalLanguage,
}) => {
  const creditsByCharacterId = (original && indexByKey(original.credits, ['character', 'id'])) || {}
  return (
    <>
      {!originalLanguage && <SelectOriginalLanguage {...{
        lang: selectedOriginalLanguage,
        LMTBcp47ToDescription,
        onChangeLanguage: onChangeSelectedOriginalLanguage,
      }} />}
      <List>
        {(franchiseCharacters || []).map((character: CharacterUnwoundBySeries) => {
          const disabled = !!drillDown(creditsByCharacterId, [character.id])
          return (
            <ExistingCharacter
              key={`/detail/original/existing-character/${character.id}`}
              disabled={disabled}
              onClickSelectExistingCharacter={onClickSelectExistingCharacter(character)}
              character={character}
            />
          )
        })}
      </List>
    </>
  )
}

export default FranchiseCharacters
