import { useCallback, useEffect, useRef, useState } from "react"
import { Grid, IconButton, makeStyles, TextField, Tooltip } from "@material-ui/core"
import { Clear, Delete, Lock, LockOpen } from "@material-ui/icons"
import languageDescription from '../../lib/languages.json'
import Filler from "../Filler"

const formatLanguage = (language, descriptions) => (`[${language}]` + (descriptions[language] ? ` ${descriptions[language]}` : ''))

const useStyles = makeStyles(theme => ({
  AkaLocalization: {
    padding: `${theme.spacing(1)}px 0`,
    maxHeight: 180,
    overflow: 'auto',
  },
  LanguageValue: {
    minWidth: 150,
  },
}))

const AkaLocalization = ({
  localization,
  nameLabel,
  saveMethod,
  onClickDelete,
}) => {

  const classes = useStyles()
  const inputRef = useRef()
  const [editing, setEditing] = useState(false)
  const [akaValue, setAkaValue] = useState(localization?.value || '')
  const valueEqual = ((localization?.value) === akaValue)
  const {language} = localization

  useEffect(() => {
    if (editing) {
      inputRef.current.focus()
    }
  }, [editing])

  const onClickEnableEditing = useCallback(() => {
    setEditing(true)
  }, [setEditing])

  const onChangeAka = useCallback(e => {
    setAkaValue(e.target.value)
  }, [setAkaValue])

  const onClickRevertChanges = useCallback(() => {
    if (localization) {
      setAkaValue(localization?.value)
      setEditing(false)
    }
  }, [localization, setAkaValue, setEditing])

  const onClickSaveEdits = useCallback(()=> {
    saveMethod({akaValue, language}).then(result => {
      console.log(`AkaLocalization/onClickSaveEdits/saveMethod, result:`, result)
      setEditing(false)
    }).catch(error => {
      console.error(`AkaLocalization/onClickSaveEdits/saveMethod, error:`, error)
    })
  }, [saveMethod, akaValue, language])

  return (
    <Grid container direction="row" wrap="nowrap" alignItems="center" className={classes.AkaLocalization}>
      <Tooltip title={languageDescription[language]} disabled={!languageDescription[language]}>
        <TextField label="Language"
          value={formatLanguage(language, languageDescription)}
          InputProps={{ disableUnderline: true }}
          disabled={true}
          classes={{root: classes.LanguageValue}}
        />
      </Tooltip>
      <Tooltip title={editing ? `Click to ${valueEqual ? 'protect' : 'save'}` : "Unlock to edit"}>
        <IconButton
          onClick={editing ? onClickSaveEdits : onClickEnableEditing}
          color={editing ? (valueEqual ? "primary" : "secondary") : "default"}
        >
          {editing ? <LockOpen /> : <Lock />}
        </IconButton>
      </Tooltip>
      <TextField
        fullWidth
        inputRef={inputRef}
        disabled={!editing}
        label={nameLabel}
        value={akaValue}
        onChange={onChangeAka}
        InputProps={{
          disableUnderline: !editing,
          endAdornment: editing && (
            <Tooltip title={valueEqual ? "Close editing" : `Revert to: ${localization?.value || ''}`}>
              <span>
                <IconButton
                  disabled={!editing || !localization}
                  onClick={onClickRevertChanges}
                  color={editing ? (valueEqual ? "primary" : "secondary") : "default"}
                >
                  <Clear />
                </IconButton>
              </span>
            </Tooltip>
          ),
        }}
      />
      <Filler />
      {onClickDelete && (
        <Tooltip title={`Delete ${language}`}>
          <IconButton
            onClick={onClickDelete}
            color={editing ? (valueEqual ? "primary" : "secondary") : "default"}
          >
            <Delete />
          </IconButton>
        </Tooltip>
      )}
    </Grid>
  )
}

export default AkaLocalization
