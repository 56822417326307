import React from 'react'
import { Link as RouterLink } from 'react-router-dom'
import { Chip, Grid, Tooltip } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import clsx from 'clsx'
import languagesPreferred from '../lib/languages-preferred.json'
import languageDescription from '../lib/languages.json'

const useStyles = makeStyles(theme => ({
  avatarContainer: {
    margin: theme.spacing(0, 0, 0, 1),
  },
  avatar: {
    textTransform: 'uppercase',
  },
  chipContainer: {
    margin: '0 2px',
  },
  LanguageList: {
    padding: theme.spacing(1),
  },
}))

const LanguageList = ({ title, indexLocalizedByLang, onClickLang, selectedLang, preferredLanguages = languagesPreferred }) => {
  const classes = useStyles()

  const mpm = title && title.mpm
  const oLang = title && title.original && title.original.language

  return (
    <Grid container direction="row" className={classes.LanguageList}>
      {preferredLanguages.map(lang => {
        const ldoc = indexLocalizedByLang[lang]
        // console.log('ldoc', ldoc)
        const isOriginal = (lang === oLang)
        const isSelected = (lang === selectedLang)
        const hasData = !!ldoc
        return (
          <Grid key={`/${mpm}/${lang}`} item className={classes.chipContainer}>
            <Tooltip title={languageDescription[lang] + (!isOriginal ? '' : ' (Original)')}>
              <Chip
                component={RouterLink}
                to={isOriginal ? `/mpm/${mpm}` : `/mpm/${mpm}?lang=${lang}`}
                variant={isOriginal ? 'default' : !isSelected ? 'outlined' : 'default'}
                color={isOriginal ? 'default' : hasData ? 'primary' : 'secondary'}
                label={lang}
                size="small"
                onClick={onClickLang}
                className={clsx(classes.avatar, isOriginal && classes.original)} />
            </Tooltip>
          </Grid>
        )
      })}
    </Grid>
  )
}

export default LanguageList
