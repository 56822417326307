"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.SvgTextAnchor = exports.RenderSectionTemplateName = exports.BCP47Languages = exports.TitleContentType = void 0;
var TitleContentType;
(function (TitleContentType) {
    TitleContentType["Series"] = "Series";
    // Season = "Season",
})(TitleContentType = exports.TitleContentType || (exports.TitleContentType = {}));
var BCP47Languages;
(function (BCP47Languages) {
    BCP47Languages["en"] = "en";
})(BCP47Languages = exports.BCP47Languages || (exports.BCP47Languages = {}));
var RenderSectionTemplateName;
(function (RenderSectionTemplateName) {
    RenderSectionTemplateName["STUDIO"] = "Studio";
    RenderSectionTemplateName["CHARACTERS"] = "Characters";
    RenderSectionTemplateName["UNNAMED"] = "Unnamed";
})(RenderSectionTemplateName = exports.RenderSectionTemplateName || (exports.RenderSectionTemplateName = {}));
var SvgTextAnchor;
(function (SvgTextAnchor) {
    SvgTextAnchor["start"] = "start";
    SvgTextAnchor["middle"] = "middle";
    SvgTextAnchor["end"] = "end";
})(SvgTextAnchor = exports.SvgTextAnchor || (exports.SvgTextAnchor = {}));
