import { useEffect } from "react"
import { Grid, makeStyles } from "@material-ui/core"
import CharacterAka from './CharacterAka'
import CharacterDetailsHeader from "./CharacterDetailsHeader"

const useStyles = makeStyles(theme => ({
  CharacterDetails: {
    height: '100%',
  },
  CharacterDetailsContent: {
    flex: 1,
    overflow: 'auto',
    padding: theme.spacing(1),
  },
}))

const flattenField = fieldName => (accum, aka) => {
  return [...accum, ...aka[fieldName]]
}

const reduceUniqueSet = (set, element) => {
  if (set[element]) {
    return set
  }
  return {
    ...set,
    [element]: true,
  }
}

const CharacterDetails = ({
  selected,
  onClickCloseDetails,
  updateCharacterAkaOriginal,
  updateLocalizationToCharacterAka,
  addAkasToCharacter,
  titles,
  getLocalizedTitle,
}) => {
  const classes = useStyles()

  useEffect(() => {
    if (!selected) {
      return
    }

    const mpm = Object.keys((selected.AKAs || [])
      .reduce(flattenField('mpm'), [])
      .reduce(reduceUniqueSet, {}))
    getLocalizedTitle({mpm})
  }, [selected, getLocalizedTitle])

  if (!selected) {
    return null
  }

  return (
    <Grid container direction="column" wrap="nowrap" className={classes.CharacterDetails}>
      <CharacterDetailsHeader {...{
        onClickCloseDetails,
        selected,
        addAkasToCharacter,
      }} />
      <div className={classes.CharacterDetailsContent}>
        {((selected && selected.AKAs) || []).map(aka => (
          <CharacterAka
            key={`/character/${selected.id}/aka/${aka.id}`}
            {...{
              character: selected,
              aka,
              updateCharacterAkaOriginal,
              updateLocalizationToCharacterAka,
              titles,
              getLocalizedTitle,
            }} />
        ))}
      </div>
    </Grid>
  )
}

export default CharacterDetails
