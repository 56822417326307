import { FC, useEffect, useRef } from 'react'

import {
  Button,
  makeStyles,
  TextField
} from '@material-ui/core'
import { FetchStateCreateCharacter } from '../../types'

const useStyles = makeStyles(theme => ({
  HeaderButton: {
    margin: theme.spacing(1),
  },
}))

interface CharacterFilterInputProps {
  addCharacterFilter: FetchStateCreateCharacter
  onChangeFilterQuery: any
}

const CharacterFilterInput: FC<CharacterFilterInputProps> = ({
  addCharacterFilter,
  onChangeFilterQuery,
}) => {
  const inputRef = useRef<HTMLInputElement>()

  useEffect(() => {
    if (!!inputRef.current) {
      inputRef.current.focus()
    }
  }, [inputRef])

  return (
    <TextField
      id="add-character-filter"
      label="Find Character"
      inputRef={inputRef}
      value={addCharacterFilter.query?.AKAs[0].original.value || ''}
      onChange={onChangeFilterQuery}
    />
  )
}

export interface FranchiseCharacterHeaderProps {
  addCharacterFilter: FetchStateCreateCharacter
  addCharacterDisabled: boolean
  onChangeFilterQuery: any
  onClickAddNewCharacter: any
  onClickCancelAddCharacter: any
}

const FranchiseCharacterHeader: FC<FranchiseCharacterHeaderProps> = ({
  addCharacterFilter,
  onChangeFilterQuery,
  addCharacterDisabled,
  onClickAddNewCharacter,
  onClickCancelAddCharacter,
}) => {
  const classes = useStyles()
  return (
    <>
      <CharacterFilterInput {...{addCharacterFilter, onChangeFilterQuery}} />
      <Button
        id="add-new-character"
        variant="contained"
        color="secondary"
        disabled={addCharacterDisabled}
        onClick={onClickAddNewCharacter}
        size="small"
        className={classes.HeaderButton}
      >+ADD</Button>
      <Button
        id="cancel-add-character"
        variant="text"
        onClick={onClickCancelAddCharacter}
        size="small"
        className={classes.HeaderButton}
      >CANCEL</Button>
    </>
  )
}

export default FranchiseCharacterHeader
