import {useCallback, useEffect, useState, useMemo} from 'react'

import {
  makeStyles,
  Typography
} from '@material-ui/core'

import ImportingCredit from './ImportingCredit'
// import RoleExisting from './RoleExisting'
import TabPanel from '../TabPanel'
import ImportHeader from './ImportHeader'
import Loading from '../Loading'

import {drillDown, unwindByKey} from 'deepdown'
import { useHistory } from 'react-router-dom'

import {
  computeImportXlsxBehaviors,
  computeImportXlsxBehaviorsInit,
  getFranchiseCharacters,
  reducerXlsxCreditToImportApi
} from '../../lib/models'

import {compile} from '../../lib/schema-utils'
import definitionsXlsx from '../../lib/schema-xlsx-state.json'

import { v4 as uuidV4 } from 'uuid'
import {
  Character,
  Talent,
  bulk,
  ImportCreditsFromXlsxParams,
  CharactersByIdMap,
  LocalizedTitle
} from 'dubcard'
import { ErrorObject } from 'ajv'
import {
  EventOnChange,
  FetchStateComputeImportBehavior,
  FetchStateGetLocalizedTitle,
  FetchStateImportCreditsFromXlsx,
  GetLocalizedTitle,
  GetTitlesForTalent,
  ImportCreditsFromXlsx,
  ImportedXlsx,
  LMTLanguageByBcp47Map,
  ParsedErrorInfo,
  SearchTalent,
  TalentsByIdMap,
  TitleByIdMap
} from '../../types'
import ImportErrorDisplay from './ImportErrorDisplay'

const {BehaviorMode} = bulk

const validImport = compile({
  definitions: definitionsXlsx,
  "$ref": "#/definitions/XlsxImport",
})

const regexCreditIndex = /credits\/(\d+)\/(\w+)\/.*/

const reducerParseImportSchemaErrors = (accum: ParsedErrorInfo, error: ErrorObject): ParsedErrorInfo => {
  const creditMatches = error.instancePath.match(regexCreditIndex)
  // console.log('reducerParseImportSchemaErrors, credit matches', creditMatches)
  if (creditMatches && creditMatches.length > 1) {
    const creditIndex = creditMatches[1]
    return {
      ...accum,
      creditsByIndex: {
        ...accum.creditsByIndex,
        [creditIndex]: [
          ...(accum.creditsByIndex[creditIndex] || []),
          error,
        ],
      },
    }
  }
  return accum
}

const useStyles = makeStyles(theme => ({
  root: {
    height: '100%',
    width: '100%',
  },
  TabContainer: {
    height: '100%',
    width: '100%',
    padding: theme.spacing(1),
  },
  TabRoot: {
    height: '100%',
    width: '100%',
  },
  ImportHeader: {
    margin: theme.spacing(2),
  },
}))

const makeDefaultResponse = () => ({
  credits: [],
  charactersByName: {},
  talentsByName: {},
  matchScoresTalentSearchByXlsxTalentName: {},
})


export interface ImportReviewProps {
  importedXlsx: ImportedXlsx
  cancelImport: () => void
  importCreditsFromXlsx: ImportCreditsFromXlsx
  charactersByMpm: CharactersByIdMap
  charactersBySeries: CharactersByIdMap
  characters: CharactersByIdMap
  talents: TalentsByIdMap
  titles: TitleByIdMap
  LMTBcp47ToDescription: LMTLanguageByBcp47Map
  minScoreCharacter?: number
  minScoreTalent?: number

  // api
  searchTalent: SearchTalent
  getTitlesForTalent: GetTitlesForTalent
  getLocalizedTitle: GetLocalizedTitle
}

const ImportReview = ({
  importedXlsx,
  // importedData,
  cancelImport,
  importCreditsFromXlsx,
  charactersByMpm,
  charactersBySeries,
  characters,
  talents,
  titles,
  searchTalent,
  getTitlesForTalent,
  getLocalizedTitle,
  LMTBcp47ToDescription,
  minScoreCharacter = 0.6,
  minScoreTalent = 0.8,
}: ImportReviewProps) => {
  const classes = useStyles()
  const history = useHistory()
  const [selectedTab, setSelectedTab] = useState(0)

  const mpm = drillDown(importedXlsx, 'data.mpm'.split('.'))
  const language = drillDown(importedXlsx, 'data.language'.split('.'))
  // const language = drillDown(importedXlsx, 'data.language'.split('.'))
  // const dataRoles = drillDown(importedXlsx, 'data.roles'.split('.'))
  const title: LocalizedTitle = titles[mpm]

  const originalDoc = (title?.localized || []).find(loc => !!loc.original)
  const originalLanguage = title?.original.language || originalDoc?.language

  const [selectedOriginalLanguage, setSelectedOriginalLanguage] = useState('')
  // useMemo is needed to avoid useEffect from running each render due to ternary assignment.
  // const unwoundFranchiseCharacterAKAs = (!franchiseCharacters) ? [] : unwindByKey(franchiseCharacters, ['AKAs'])
  const unwoundFranchiseCharacterAKAs = useMemo(() => {
    const franchiseCharacters = getFranchiseCharacters({mpm, titles, charactersBySeries, charactersByMpm})
    return (franchiseCharacters && unwindByKey(franchiseCharacters, ['AKAs'])) || []
  }, [mpm, titles, charactersByMpm, charactersBySeries])

  const [saveState, setSaveState] = useState<FetchStateImportCreditsFromXlsx>({fetching: false})
  const [behaviorsState, setBehaviorsState] = useState<FetchStateComputeImportBehavior>({
    fetching: false,
    response: computeImportXlsxBehaviorsInit({
      importedXlsx,
      unwoundFranchiseCharacterAKAs,
      minScoreCharacter,
      minScoreTalent,
    }),
  })

  const [targetDetailsState, setTargetDetailsState] = useState<FetchStateGetLocalizedTitle>({
    fetching: false,
  })

  const creditsRequiringCharacterUpdates = (behaviorsState.response?.credits || [])
    .filter(credit => !!credit.character && !credit.ignore)

  const importedDataIsValid = validImport(behaviorsState.response) && (
    (creditsRequiringCharacterUpdates.length === 0)
      ? true
      : (!!originalLanguage || !!selectedOriginalLanguage)
  )
  // if (!schemaIsValid) {
  //   console.error("behaviors", importBehaviors, ", are not valid", validImport.errors)
  // }
  const parsedErrors = (validImport.errors || []).reduce(reducerParseImportSchemaErrors, {creditsByIndex: {}})
  const enableImport = !targetDetailsState.fetching && !behaviorsState.fetching && importedDataIsValid && !saveState.fetching
  const isLoading = targetDetailsState.fetching || behaviorsState.fetching

  console.log('creditsRequiringCharacterUpdates', creditsRequiringCharacterUpdates)
  console.log('title metadata', title)
  const onChangeTabSelection = useCallback((...args) => {
    setSelectedTab(args[1])
  }, [setSelectedTab])

  const onChangeTalent = useCallback((credit, index) => (entity: Talent) => {
    console.log('ImportReview/onChangeTalent:', entity)

    setBehaviorsState(behaviors => ({
      ...behaviors,
      response: {
        ...(behaviors.response || makeDefaultResponse()),
        credits: [
          ...(behaviors?.response?.credits || []).slice(0, index),
          {...credit, talent: {...credit.talent, entity, ...(!entity ? {} : {mode: BehaviorMode.USE_EXISTING})}},
          ...(behaviors?.response?.credits || []).slice(1 + index),
        ],
      },
    }))
  }, [setBehaviorsState])

  // const onChangeLocalizedCharacterName = useCallback(index => e => {
  //   console.log('ImportReview/onChangeLocalizedCharacterName', e.target.value)
  //   const role = {
  //     ...importedRoles[index],
  //     importedLocalizedCharacterName: e.target.value,
  //   }
  //   setImportedRoles([
  //     ...importedRoles.slice(0, index),
  //     role,
  //     ...importedRoles.slice(1 + index),
  //   ])
  // }, [importedRoles, setImportedRoles])

  // const onChangeForceCharacterLocalization = useCallback(index => e => {
  //   console.log('ImportReview/onChangeForceCharacterLocalization', e.target.value)
  //   const role = {
  //     ...importedRoles[index],
  //     forceCharacterLocalization: e.target.value,
  //   }
  //   setImportedRoles([
  //     ...importedRoles.slice(0, index),
  //     role,
  //     ...importedRoles.slice(1 + index),
  //   ])
  // }, [importedRoles, setImportedRoles])

  const onChangeBehaviorCharacter = useCallback((credit, index) => (e: EventOnChange) => {
    const mode = e.target.value
    console.log('onChangeBehaviorCharacter, args', mode)
    setBehaviorsState(behaviors => ({
      ...behaviors,
      response: {
        ...(behaviors.response || makeDefaultResponse()),
        credits: [
          ...(behaviors?.response?.credits || []).slice(0, index),
          {...credit, character: {...credit.character, mode}},
          ...(behaviors?.response?.credits || []).slice(1 + index),
        ],
      },
    }))
  }, [setBehaviorsState])

  const onChangeBehaviorTalent = useCallback((credit, index) => (e: EventOnChange) => {
    const mode = e.target.value
    console.log('onChangeBehaviorTalent, args', mode)

    setBehaviorsState(behaviors => {
      let entity
      let newTalent
      let omitTalentName : string | undefined

      if (mode === BehaviorMode.USE_EXISTING) {
        // check if there is an obvious match?
        // or just force user to pick a talent?

        // either way, we should probably remove the entity that had been added to the CREATE batch,
        // unless another credit needs the same character

        // check if any other credit uses the SAME TALENT NAME, if not, then remove from CREATE batch.
        const filteredCreditsMatchTalentName = (behaviors?.response?.credits || []).filter(c => c.xlsxRole.talentName === credit.xlsxRole.talentName)
        if (filteredCreditsMatchTalentName.length < 2) {
          omitTalentName = credit.xlsxRole.talentName
          entity = null  // null forces the user to pick a talent
        } else {
          // assume the same entity as the other credit with the SAME TALENT NAME
          const otherCredit = filteredCreditsMatchTalentName.find(c => (
            (c.role !== credit.role) ||
            (c.character?.entity.id !== credit.character?.entity?.id)
          ))

          entity = otherCredit?.talent?.entity
        }
      } else { // CREATE_NEW
        // this branch is to tell the system to use a NEW ENTITY,
        // but there is a small chance an existing entity already exists, i.e. another credit has the SAME TALENT NAME.
        // assuming another credit has the SAME TALENT NAME,
        //   if it needed to be CREATED, then an entity would have already been added to the CREATE batch.
        // assuming no other credit has the SAME TALENT NAME,
        //   then no entity should exist in the CREATE batch.

        // check if CREATE batch has an entity for this credit's talentName,
        // which means another credit exists for the same talentName
        if (!behaviors?.response?.talentsByName[credit.xlsxRole.talentName]) {
          newTalent = {id: uuidV4(), aka: uuidV4()}
          entity = newTalent
        }
      }

      const talentsByName = (!!omitTalentName)
        // need to omit, dont need to add newTalent
        ? Object.keys(behaviors?.response?.talentsByName || {}).reduce((accum, key) => ({
          ...accum,
          ...(key === omitTalentName ? {} : {[key]: behaviors?.response?.talentsByName[key]}),
        }), {})
        // dont need to omit, possibly need to add newTalent
        : {
          ...(behaviors?.response?.talentsByName || {}),
          // insert new talent if needed
          ...(!newTalent ? {} : {[credit.xlsxRole.talentName]: newTalent}),
        }

      return {
        ...behaviors,
        response: {
          ...(behaviors.response || makeDefaultResponse()),
          talentsByName,
          credits: [
            ...(behaviors?.response?.credits || []).slice(0, index),
            {...credit, talent: {entity, mode}},
            ...(behaviors?.response?.credits || []).slice(1 + index),
          ],
        },
      }
    })
  }, [setBehaviorsState])

  const onClickIgnore = useCallback((credit, index) => () => {
    const ignore = !credit.ignore
    setBehaviorsState(behaviors => ({
      ...behaviors,
      response: {
        ...(behaviors.response || makeDefaultResponse()),
        credits: [
          ...(behaviors?.response?.credits || []).slice(0, index),
          {...credit, ignore},
          ...(behaviors?.response?.credits || []).slice(1 + index),
        ],
      },
    }))
  }, [setBehaviorsState])

  const onChangeCharacter = useCallback((credit, index) => (entity: Character) => {
    console.log('ImportReview/onChangeCharacter:', entity)
    setBehaviorsState(behaviors => ({
      ...behaviors,
      response: {
        ...(behaviors.response || makeDefaultResponse()),
        credits: [
          ...(behaviors?.response?.credits || []).slice(0, index),
          {...credit, character: {...credit.character, entity, ...(!entity ? {} : {mode: BehaviorMode.USE_EXISTING})}},
          ...(behaviors?.response?.credits || []).slice(1 + index),
        ],
      },
    }))
  }, [setBehaviorsState])

  const onClickCancelImport = useCallback(() => {
    cancelImport()
  }, [cancelImport])

  const onClickSaveImport = useCallback(() => {
    const initApi: ImportCreditsFromXlsxParams = {
      mpm: mpm,
      language: language,
      credits: [],
      characters: [],
      talents: [],
    }

    if (!originalLanguage && selectedOriginalLanguage) {
      initApi.titleOriginalLanguage = selectedOriginalLanguage
    }
    const importApi = (behaviorsState?.response?.credits || []).reduce(reducerXlsxCreditToImportApi, initApi)
    setSaveState({query: importApi, fetching: true})
    importCreditsFromXlsx(importApi).then(response => {
      console.log('ImportReview/onClickSaveImport/saveImport, response', response)
      setSaveState(previous => ({...previous, fetching: false, response}))
      const route = `/mpm/${importApi.mpm}?lang=${importApi.language}`
      console.log('ImportReview/onClickSaveImport/saveImport, navigating to route', route)
      history.push(route)
    }).catch(error => {
      console.error('ImportReview/onClickSaveImport/saveImport, error', error)
      setSaveState(previous => ({...previous, fetching: false, error}))
      // TODO: display the error
    })
  }, [mpm, language, behaviorsState.response, history, importCreditsFromXlsx, originalLanguage, selectedOriginalLanguage])

  useEffect(() => {
    if (!title) {
      return
    }

    let isMounted = true

    setBehaviorsState(bs => ({...bs, fetching: true}))

    computeImportXlsxBehaviors({
      unwoundFranchiseCharacterAKAs,
      importedXlsx,
      minScoreCharacter,
      minScoreTalent,
      searchTalent,
    }).then((response) => {
      if (!isMounted) {
        return
      }

      setBehaviorsState(bs => ({...bs, fetching: false, response}))
    }).catch((error: any) => {
      if (!isMounted) {
        return
      }

      setBehaviorsState(bs => ({...bs, fetching: false, error}))
    })

    return () => {
      isMounted = false
    }
  }, [title, importedXlsx, unwoundFranchiseCharacterAKAs, minScoreCharacter, minScoreTalent, searchTalent])

  useEffect(() => {
    let mounted = true

    // fetch title details in case it is not in global app cache
    const before = {query: {mpm: [mpm]}, fetching: true}
    setTargetDetailsState(before)
    getLocalizedTitle(before.query).then(response => {
      if (!mounted) {
        return
      }

      console.log('ImportReview/getLocalizedTitle, response', response)
      setTargetDetailsState({...before, fetching: false, response})
      // const title = (response.title || []).find(t => t.mpm === mpm)
      // title && setExistingRoles(drillDown(title, ['original', 'roles']))
    }).catch(error => {
      if (!mounted) {
        return
      }

      console.error('ImportReview/getLocalizedTitle, error:', error)
      setTargetDetailsState({...before, fetching: false, error})
    })

    return () => {
      mounted = false
    }
  }, [getLocalizedTitle, mpm, setTargetDetailsState])

  const onChangeOriginalLanguage = useCallback((e) => {
    setSelectedOriginalLanguage(e.target.value)
  }, [setSelectedOriginalLanguage])

  return (
    <div id="import-review-container" className={classes.root}>
      <ImportHeader {...{
        title,
        importedXlsx,
        behaviorsState,
        enableImport,
        selectedTab,
        targetDetailsState,
        onClickSaveImport,
        onClickCancelImport,
        LMTBcp47ToDescription,
        onChangeTabSelection,
        onChangeOriginalLanguage,
        originalLanguage,
        selectedOriginalLanguage,
      }} />

      {!!targetDetailsState.error
        ? <ImportErrorDisplay {...{subheading: 'target title could not be fetched'}} />
        : !!behaviorsState.error && (
          <ImportErrorDisplay {...{
            subheading: `could not determine auto-select import behaviors`,
          }} />
        )
      }

      <div className={classes.TabContainer}>
        <TabPanel selectedIndex={selectedTab} index={0}>
          <div className={classes.TabRoot}>
            {isLoading && (
              <Loading />
            )}
            {!isLoading && (behaviorsState.response?.credits || []).map((credit, i) => (
              <ImportingCredit
                key={`/import/credit/${i}`}
                {...{
                  credit,
                  errors: parsedErrors.creditsByIndex[i],
                  importBehaviors: behaviorsState.response,
                  // talentsByName,
                  characters,
                  talents,
                  unwoundFranchiseCharacterAKAs,
                  // onChangeLocalizedCharacterName: onChangeLocalizedCharacterName(i),
                  // onChangeForceCharacterLocalization: onChangeForceCharacterLocalization(i),
                  onChangeBehaviorCharacter: onChangeBehaviorCharacter(credit, i),
                  onChangeBehaviorTalent: onChangeBehaviorTalent(credit, i),
                  onChangeCharacter: onChangeCharacter(credit, i),
                  onChangeTalent: onChangeTalent(credit, i),
                  onClickIgnore: onClickIgnore(credit, i),
                  getTitlesForTalent,
                  searchTalent,
                }}
              />
            ))}
          </div>
        </TabPanel>

        <TabPanel selectedIndex={selectedTab} index={1}>
          <div className={classes.TabRoot}>
            <Typography>TODO: display existing credits?</Typography>
            {/* {existingRoles.map((role, i) => (
            <RoleExisting
              key={`/import/existing-role/${i}`}
              {...{
                role,
                talents,
                // onChangeKeep: onChangeKeep(i),
              }} />
            ))} */}
          </div>
        </TabPanel>
      </div>

    </div>
  )
}

export default ImportReview
