import React, { useCallback, useState } from 'react'
import { Grid, Typography, IconButton, makeStyles, Tooltip } from '@material-ui/core'
import DropDown from '../DropDown'
import PreviewSettings from './PreviewSettings'
import PageSelector from '../PageSelector'
import { Settings as SettingsIcon } from '@material-ui/icons'
import PreviewExports from './PreviewExports'


const useStyles = makeStyles(theme => ({
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120,
  },
  PreviewControls: {
    margin: theme.spacing(1),
  },
  ExportControls: {
    paddingRight: theme.spacing(1),
  },
}))

const PreviewControls = ({
  onClickExportImage,
  exportState,
  lang,
  settings, setSettings,
  pages, selectedPage, setSelectedPage,
  cardTypeOptions, selectedCardType, onChangeCardType,
  resolutionOptions, selectedResolutionIndex, onChangeResolutions, 
  fontOptions, selectedFont, onChangeFont,
}) => {
  const classes = useStyles()
  const [showSettings, setShowSettings] = useState(false)

  const onClickToggleSettings = useCallback(() => setShowSettings(show => !show), [setShowSettings])

  return (
    <Grid container direction="column" wrap="nowrap" className={classes.PreviewControls}>
      <Grid container direction="row" wrap="nowrap" className={classes.PreviewControlsDropDowns}>
        <Grid item> <DropDown label="Card" options={cardTypeOptions} value={selectedCardType} onChange={onChangeCardType} className={classes.formControl} /> </Grid>
        <Grid item> <DropDown label="Format" options={resolutionOptions} value={selectedResolutionIndex} onChange={onChangeResolutions} className={classes.formControl} /> </Grid>
        <Grid item> <DropDown label="Font" options={fontOptions} value={selectedFont} onChange={onChangeFont} className={classes.formControl} /> </Grid>
      </Grid>
      {showSettings && <PreviewSettings settings={settings} setSettings={setSettings} />}
      <Grid container direction="row" justifyContent="flex-start" alignItems="center" wrap="wrap" className={classes.PreviewControlsExportsContainer}>
        <Grid item xs={12} container direction="row" wrap="nowrap" justifyContent="flex-end" alignItems="center" className={classes.ExportControls}>
          <Grid item className={classes.select}>
            <Tooltip title="toggle settings">
              <span>
                <IconButton variant="contained" size="small" color="default" onClick={onClickToggleSettings}><SettingsIcon /></IconButton>
              </span>
            </Tooltip>
          </Grid>
          <PreviewExports {...{onClickExportImage, exportState, pages, lang }} />
        </Grid>
        <Grid item xs={12} container direction="row" justifyContent="center" alignItems="center" wrap="nowrap">
          <Grid item xs={3}> <Typography variant="h5">Preview</Typography> </Grid>
          <Grid item xs={6}>
            <PageSelector disabled={pages.length < 2} numPages={pages.length} selectedPage={selectedPage} setSelectedPage={setSelectedPage} />
          </Grid>
          <Grid item xs={3}></Grid>
        </Grid>
      </Grid>
    </Grid>
  )
}

export default PreviewControls
