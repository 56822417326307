import React from 'react'
import { Grid, makeStyles } from '@material-ui/core'

const useStyles = makeStyles(theme => ({
}))

const Preview = ({svgString}) => {
  const classes = useStyles()
  return (
    <Grid item xs={12} className={classes.renderContainer} dangerouslySetInnerHTML={{__html: svgString}}></Grid>
  )
}

export default Preview
