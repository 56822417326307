import React from 'react'

import { makeStyles } from '@material-ui/core'

const useStylesFullHeight = makeStyles(() => ({
  fullHeight: {
    height: '100%',
  },
}))

const Filler = ({children}) => {
  const classes = useStylesFullHeight()
  return (
    <div className={classes.fullHeight}>
      {children}
    </div>
  )
}

export default Filler
