import { useCallback } from "react"
import { Close, Share } from "@material-ui/icons"
import { Grid, IconButton, Tab, Tabs, Tooltip } from "@material-ui/core"
import Filler from '../Filler'

const ShareEntity = () => {
  const onClick = useCallback(() => {
    const value = window.location.href
    navigator.clipboard.writeText(value)
  }, [])

  return (
    <Tooltip title="Copy URL to clipboard">
      <IconButton onClick={onClick}>
        <Share color="secondary" />
      </IconButton>
    </Tooltip>
  )
}

const CloseDetails = ({
  onClickCloseDetails,
}) => {
  return (
    <Tooltip title="Close character details">
      <IconButton onClick={onClickCloseDetails}>
        <Close />
      </IconButton>
    </Tooltip>
  )
}

const CharacterDetailsHeader = ({
  onClickCloseDetails,
  // selected,
  // addAkasToCharacter,
}) => {
  return (
    <Grid container direction="row" wrap="nowrap">
      <Tabs
        value={0}
        indicatorColor="primary"
        textColor="primary"
      >
        <Tab label="AKAs" />
      </Tabs>
      <Filler />
      <ShareEntity />
      <CloseDetails {...{ onClickCloseDetails }} />
    </Grid>
  )
}

export default CharacterDetailsHeader
