import { makeStyles, TextField } from "@material-ui/core"

const useStyles = makeStyles(theme => ({
  root: {
    width: 180,
  },
}))

const ImportingName = ({
  label,
  value,
}) => {
  const classes = useStyles()
  return (
    <TextField
      classes={classes}
      label={label}
      value={value}
      inputProps={{
        readOnly: true,
        disabled: true,
      }}
    />
  )
}

export default ImportingName
