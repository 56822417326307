
import {
  makeStyles
} from '@material-ui/core'

import Loading from '../Loading'
import EmptyList from '../EmptyList'
import ErrorDisplay from '../ErrorDisplay'

import AtomCharacterListItem from './AtomCharacterListItem'

import { formatGraphQlError } from '../../lib/models'
import SelectOriginalLanguage, { SelectOriginalLanguageProps } from './SelectOriginalLanguage'
import { FC } from 'react'
import { ClosureHandler, FetchStateGetAtomCharacters, LMTLanguageByBcp47Map, SelectOnChange } from '../../types'
import { AtomCharacter } from 'dubcard'


const useStyles = makeStyles(({
  AtomImportContent: {
    height: '100%',
    overflow: 'auto',
  },
}))


const billingOrderSort = (a: AtomCharacter, b: AtomCharacter) => {
  return a.billingOrder - b.billingOrder
}

export interface AtomImportContentProps {
  originalLanguage: string
  selectedOriginalLanguage: string
  LMTBcp47ToDescription: LMTLanguageByBcp47Map
  atomImport: any
  atomCharacters: FetchStateGetAtomCharacters
  franchiseCharacters: any[]
  atomImportData: any
  onClickIgnoreItem: any
  onChangeMode: any
  onChangeCharacter: ClosureHandler<AtomCharacter, SelectOnChange>
  onChangeSelectedOriginalLanguage: SelectOriginalLanguageProps['onChangeLanguage']
}

const AtomImportContent : FC<AtomImportContentProps> = ({
  onChangeSelectedOriginalLanguage,
  selectedOriginalLanguage,
  originalLanguage,
  LMTBcp47ToDescription,
  atomImport,
  atomCharacters,
  franchiseCharacters,
  atomImportData,
  onClickIgnoreItem,
  onChangeMode,
  onChangeCharacter,
}) => {
  const classes = useStyles()

  return (
    <div className={classes.AtomImportContent}>
      {(atomImport.error)
        ? <ErrorDisplay message={formatGraphQlError(atomImport.error)} />
        : (atomCharacters.fetching)
          ? <Loading />
          : (atomCharacters.error)
            ? <ErrorDisplay message={atomCharacters.error.message || JSON.stringify(atomCharacters.error)} />
            : (!atomCharacters.response || (atomCharacters.response.length === 0))
              ? <EmptyList label="Atom provided no characters" />
              : (
                <>
                  {!originalLanguage && <SelectOriginalLanguage {...{
                    LMTBcp47ToDescription,
                    onChangeLanguage: onChangeSelectedOriginalLanguage,
                    lang: selectedOriginalLanguage,
                  }} />}
                  {atomCharacters.response.sort(billingOrderSort).map(data => {
                    const behavior = atomImportData[data.characterName]

                    return (
                      <AtomCharacterListItem
                        key={`/original/atom/${data.characterName}`}
                        {...{
                          data,
                          franchiseCharacters,
                          behavior,
                          onClickIgnoreItem: onClickIgnoreItem(data),
                          onChangeMode: onChangeMode(data),
                          onChangeCharacter: onChangeCharacter(data),
                        }}
                      />)
                  })}

                </>
              )
      }
    </div>
  )
}

export default AtomImportContent
