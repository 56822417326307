import React, { Fragment, useCallback, useState, useEffect } from 'react'
import { Link as RouterLink, useHistory, useParams } from 'react-router-dom'
import Editor from '../components/Editor'

import Layout from '../components/Layout'
import { parseParams } from '../lib/query-params'
import LoadingScreen from '../components/Loading'
import LanguageSelector from '../components/LanguageSelector'
import { exportDubsheet, getXlsxFilename } from '../lib/file-utils'

import { saveAs } from 'file-saver'
import clsx from 'clsx'
import {
  creditsToArrays
} from '../lib/models'

import { models } from 'dubcard'

import {
  CloudDownload,
  MovieFilter
  // Save as SaveIcon,
} from '@material-ui/icons'

import {
  makeStyles,
  IconButton,
  Tooltip
} from '@material-ui/core'

import OriginalTitleView from '../components/OriginalTitleView'

const { organizeCreditsForEditor } = models


const useStylesLanguage = makeStyles(theme => ({
  wrapper: {
    '& .MuiInput-underline:before': {
      borderColor: theme.palette.common.white,
    },
    '& .MuiInput-underline:hover:before': {
      borderColor: theme.palette.common.white,
    },
    '& .MuiInput-root': {
      color: theme.palette.common.white,
    },
    '& .MuiSelect-icon': {
      color: theme.palette.common.white,
    },
  },
}))


const useStyles = makeStyles(theme => ({
  buttonActive: {
    '& svg': {
      fill: theme.palette.common.white,
    },
  },
  buttonDisabled: {
    '& svg': {
      fill: theme.palette.grey[900],
    },
  },
  button: {
    whiteSpace: 'nowrap',
    // minWidth: 120,
    marginLeft: theme.spacing(1),
  },
}))

const Download = ({ onClick, disabled }) => {
  const classes = useStyles()
  return (
    <Tooltip title="Download spreadsheet for this title">
      <span>
        <IconButton
          id="xlsx-download"
          disabled={disabled}
          className={clsx(classes.button, disabled ? classes.buttonDisabled : classes.buttonActive)}
          variant="contained"
          size="small"
          onClick={onClick}
        >
          <CloudDownload />
        </IconButton>
      </span>
    </Tooltip>
  )
}

const LinkOriginal = ({disabled, mpm}) => {
  const classes = useStyles()
  return (
    <Tooltip title="Original Language">
      <span>
        <IconButton
          disabled={disabled}
          component={RouterLink}
          to={`/mpm/${mpm}`}
          size="small"
          className={clsx(disabled ? classes.buttonDisabled : classes.buttonActive)}
        >
          <MovieFilter />
        </IconButton>
      </span>
    </Tooltip>
  )
}

const Detail = (props) => {
  const {
    user,
    fonts,
    formats,
    location,
    titles,
    characters,
    charactersByMpm,
    charactersBySeries,
    createCharacter,
    addTitlesToCharacterAka,
    removeTitlesFromCharacterAka,
    updateLocalizationToCharacterAka,
    updateBillingForOriginalCharacterCredit,
    updateBillingForLocalizedCredit,
    createTalent,
    searchTalent,
    createDelivery,
    getLocalizedTitle,
    findCharactersByFranchiseId,
    addCreditsToLocalizedTitle,
    removeCreditFromLocalizedTitle,
    updateTalentForCharacterCredit,
    updateEpisodeOverrideForSeason,
    removeEpisodeOverrideForSeason,
    renderRasterBySvg,
    getDeliveries,
    deliverySettings,
    getUserAttributes,
    getAtomCharacters,
    importCharactersFromAtom,
    talents,
    LMTBcp47ToDescription,
  } = props
  const classesLanguage = useStylesLanguage()

  const history = useHistory()
  const routeParams = useParams()
  const [detailState, setDetailState] = useState({query: null, fetching: false, response: null, error: null})
  const [deliveryState, setDeliveryState] = useState({query: null, fetching: false, results: null, error: null})

  const mpm = routeParams.mpm
  const queryParams = (location.search && location.search.length > 1) ? parseParams(location.search.slice(1)) : {}
  const lang = queryParams.lang
  const title = mpm && titles && titles[mpm]
  const origDoc = (title?.localized || []).find(loc => loc.original)
  const oLang = title?.original?.language || origDoc?.language
  // console.log('---- Detail, lang', lang)
  const season = title && titles && titles[title.mpmProductNumber]
  const series = titles && ((season && titles[season.mpmFamilyNumber]) || (title && titles[title.mpmFamilyNumber]))
  const franchiseCharacters = (series && charactersBySeries && charactersBySeries[series.mpm]) || (title && charactersByMpm && charactersByMpm[title.mpm])

  const editorLists = organizeCreditsForEditor(mpm, titles, lang)

  useEffect(() => {
    // fetch delivery details in case it is not in global app cache
    if (!user) { // || ((drillDown(deliveryState, ['query', 'mpm']) === mpm) && (deliveryState.fetching || deliveryState.response || deliveryState.error))) {
      console.log(`pages/Detail/useEffect - NOT calling getDeliveries(${mpm})`)
      return
    }

    let mounted = true

    const query = {mpm}
    setDeliveryState({query, fetching: true})
    getDeliveries(query).then(results => {
      if (!mounted) {
        return
      }

      console.log('Detail/useEffect/getDeliveries - results', results)
      setDeliveryState(state => ({...state, fetching: false, results}))
    }).catch(error => {
      if (!mounted) {
        return
      }

      console.error('Detail/useEffect/getDeliveries - error', error)
      setDeliveryState(state => ({...state, fetching: false, error}))
    })

    return () => {
      mounted = false
    }
  }, [getDeliveries, setDeliveryState, mpm, user])

  useEffect(() => {
    // fetch title details in case it is not in global app cache
    if (!user || ((detailState.query === mpm) && (detailState.fetching || detailState.response || detailState.error))) {
      console.log(`pages/Detail/useEffect - NOT calling getLocalizedTitle(${mpm})`)
      return
    }

    let mounted = true

    console.log(`pages/Detail/useEffect - calling getLocalizedTitle(${mpm})`)
    const before = {query: mpm, fetching: true}
    setDetailState(before)
    getLocalizedTitle({mpm: [before.query]}).then(response => {
      if (!mounted) {
        return
      }
      console.log('Detail/getLocalizedTitle, response', response)
      setDetailState({...before, fetching: false, response})
    }).catch(error => {
      if (!mounted) {
        return
      }
      console.error('Detail/getLocalizedTitle, error:', error)
      setDetailState({...before, fetching: false, error})
    })

    return () => {
      mounted = false
    }
  }, [getLocalizedTitle, user, mpm, detailState, setDetailState])

  const createDeliveryWrapper = useCallback(input => {
    return createDelivery(input).then(result => {
      setDeliveryState(ds => ({
        ...ds,
        results: [
          result,
          ...(ds.results || []),
        ],
      }))
      return result
    })
  }, [createDelivery, setDeliveryState])

  const onClickDownload = () => {
    console.log('onClickDownload - title', titles[mpm])
    const title = titles[mpm]

    // get the file contents as arrays of arrays for spreadsheet formation
    const arrays = creditsToArrays({
      title: {
        ...title,
        atom: title.original,
        original: title.localized.find(loc => ((loc.language === title.original.language) || (!!loc.original))),
        localized: title.localized.find(ldoc => ldoc.language === lang) || { language: lang },
      },
      talents,
      characters,
    })

    // convert arrays of arrays into spreadsheet
    const xlsxData = exportDubsheet(arrays)
    const prefix = (process.env.REACT_APP_BUILD_ENV === 'prod') ? '' : `${process.env.REACT_APP_BUILD_ENV}-`
    saveAs(xlsxData, prefix + getXlsxFilename(mpm, titles, lang))
  }

  const onChangeLanguage = useCallback((ev/*, langOption*/) => {
    const langOption = ev.target.value
    const isOriginal = (oLang === langOption) || (langOption === origDoc?.language)
    const path = isOriginal ? `/mpm/${mpm}` : `/mpm/${mpm}?lang=${langOption}`
    history.push(path)
  }, [history, mpm, oLang, origDoc])

  return (
    <Layout {...{...props, ...{
      mpm,
      pageActions: (
        <Fragment>
          <Download disabled={!queryParams.lang || !routeParams.mpm} onClick={onClickDownload} />
          <LanguageSelector {...{
            className: classesLanguage.wrapper,
            LMTBcp47ToDescription,
            onChangeLanguage,
            lang: lang || oLang,
            id: 'language-selector',
          }} />
          <LinkOriginal disabled={!lang} mpm={mpm} />
        </Fragment>
      ),
    }}}>
      {(!titles[mpm])
        ? <LoadingScreen />
        : (!lang)
          ? <OriginalTitleView {...{
            title,
            characters,
            editorLists,
            franchiseCharacters,
            createCharacter,
            addTitlesToCharacterAka,
            removeTitlesFromCharacterAka,
            updateBillingForOriginalCharacterCredit,
            getLocalizedTitle,
            findCharactersByFranchiseId,
            getAtomCharacters,
            importCharactersFromAtom,
            LMTBcp47ToDescription,
          }}/>
          : <Editor {...{
            titles,
            talents,
            characters,
            queryParams,
            title,
            season,
            editorLists,
            updateLocalizationToCharacterAka,
            updateBillingForLocalizedCredit,
            updateEpisodeOverrideForSeason,
            removeEpisodeOverrideForSeason,
            createTalent,
            searchTalent,
            createDelivery: createDeliveryWrapper,
            addCreditsToLocalizedTitle,
            removeCreditFromLocalizedTitle,
            updateTalentForCharacterCredit,
            renderRasterBySvg,
            fonts,
            formats,
            deliverySettings,
            deliveryState,
            getUserAttributes,
          }} />
      }
    </Layout>
  )
}

export default Detail
