import { FC } from 'react'

import {
  Grid,
  Tab,
  Tabs
} from '@material-ui/core'

import Filler from '../Filler'

import AtomImportHeader from './AtomImportHeader'
import FranchiseCharacterHeader from './FranchiseCharacterHeader'
import StandardHeaderButtons from './StandardHeaderButtons'
import { TitleCredit } from 'dubcard'
import { FetchStateCreateCharacter } from '../../types'

export interface CharacterViewHeaderProps {
  characterCredits: TitleCredit[]
  addCharacterFilter: FetchStateCreateCharacter
  showFranchise: boolean
  showAtomImport: boolean
  atomImportDisabled: boolean
  addCharacterDisabled: boolean
  onClickShowAtomImport: any
  onClickCancelAtomImport: any
  onClickImportFromAtom: any
  onClickShowFranchise: any
  onClickCancelAddCharacter: any
  onClickAddNewCharacter: any
  onChangeFilterQuery: any
}

const CharacterViewHeader: FC<CharacterViewHeaderProps> = ({
  characterCredits,
  addCharacterFilter,
  showFranchise,
  showAtomImport,
  onClickShowAtomImport,
  onClickCancelAtomImport,
  onClickImportFromAtom,
  atomImportDisabled,
  addCharacterDisabled,
  onClickShowFranchise,
  onClickCancelAddCharacter,
  onClickAddNewCharacter,
  onChangeFilterQuery,
}) => {
  return (
    <Grid container direction="row" wrap="nowrap">
      <Tabs
        value={0}
        indicatorColor="primary"
        textColor="primary"
      >
        <Tab label="Characters" />
      </Tabs>
      <Filler />
      {showAtomImport
        ? (<AtomImportHeader {...{
          atomImportDisabled,
          onClickImportFromAtom,
          onClickCancelAtomImport,
        }} />)
        : showFranchise
          ? (<FranchiseCharacterHeader {...{
            addCharacterFilter,
            onChangeFilterQuery,
            addCharacterDisabled,
            onClickAddNewCharacter,
            onClickCancelAddCharacter,
          }} />)
          : (<StandardHeaderButtons {...{
            characterCredits,
            onClickShowAtomImport,
            onClickShowFranchise,
          }} />)
      }
    </Grid>
  )
}

export default CharacterViewHeader
