"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    Object.defineProperty(o, k2, { enumerable: true, get: function() { return m[k]; } });
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __exportStar = (this && this.__exportStar) || function(m, exports) {
    for (var p in m) if (p !== "default" && !Object.prototype.hasOwnProperty.call(exports, p)) __createBinding(exports, m, p);
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.vocabulary = exports.templates = exports.schema = exports.roles = exports.rendering = exports.models = exports.layout = exports.interpolate = exports.formats = exports.delivery = exports.bulk = void 0;
const delivery = require("./delivery-platforms");
exports.delivery = delivery;
const bulk = require("./bulk");
exports.bulk = bulk;
const formats = require("./format-settings");
exports.formats = formats;
const layout = require("./layout");
exports.layout = layout;
const models = require("./models");
exports.models = models;
const rendering = require("./rendering");
exports.rendering = rendering;
const schema = require("./schema.json");
exports.schema = schema;
// import svg from './svg'
const roles = require("./role-types");
exports.roles = roles;
const template_settings_1 = require("./template-settings");
exports.templates = template_settings_1.default;
const vocabulary = require("./vocabulary.json");
exports.vocabulary = vocabulary;
const interpolate_1 = require("./interpolate");
exports.interpolate = interpolate_1.default;
__exportStar(require("./types"), exports);
__exportStar(require("./api"), exports);
