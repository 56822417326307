import React from 'react'

import {
  makeStyles,
  Button,
  Grid,
  TextField,
  CircularProgress
} from '@material-ui/core'
import { Search } from '@material-ui/icons'

const useStyles = makeStyles(theme => ({

}))

const TalentSelectHeader = ({
  disabled,
  inputValue,
  onChangeInputValue,
  onClickCreateTalent,
  searchState,
}) => {
  const classes = useStyles()

  return (
    <Grid container direction="row" alignItems="center" justifyContent="flex-start" wrap="nowrap">
      {(searchState && searchState.fetching) ? <CircularProgress size={24} /> : <Search />}
      <TextField
        disabled={disabled}
        fullWidth
        margin="dense"
        value={inputValue || ''}
        placeholder="Search…"
        onChange={onChangeInputValue}
        InputProps={{
          classes: { root: classes.inputRoot, input: classes.inputInput },
        }}
      />
      <Button onClick={onClickCreateTalent} disabled={(disabled || !inputValue || (inputValue.length === 0))}>CREATE</Button>
    </Grid>
  )
}

export default TalentSelectHeader
