import XLSX from 'xlsx'
import { sheetToCreditsModel } from './models'

const parseSheets = workbook => (accum, sheetName) => {
  const roa = XLSX.utils.sheet_to_json(workbook.Sheets[sheetName], { header: 1 })
  if (roa.length) {
    return {
      ...accum,
      [sheetName]: roa,
    }
  }
  return accum
}

const onLoadedSheet = resolve => e => {
  console.log('loaded')
  // const data = new Uint8Array(e.target.result)
  const workbook = XLSX.read(e.target.result, { type: 'array' })
  const sheets = workbook.SheetNames.reduce(parseSheets(workbook), {})
  console.log('onLoadedSheet', sheets)
  resolve(sheetToCreditsModel(sheets[workbook.SheetNames[0]]))
}

const onLoadedError = reject => (e) => {
  console.log('onLoadedError', e.message)
  reject(e)
}

export const regularDubsheet = (file) => {
  const reader = new FileReader()

  return new Promise((resolve, reject) => {
    reader.onload = onLoadedSheet(resolve)
    reader.onerror = onLoadedError(reject)
    reader.readAsArrayBuffer(file)
  })
}

