import { useCallback, useEffect, useState } from "react"
import { Grid, IconButton, makeStyles, Paper, Typography } from "@material-ui/core"
import { ExpandMore, ExpandLess, FileCopy, GetAppOutlined } from "@material-ui/icons"
import {delivery} from 'dubcard'
import Filler from "../Filler"
import { getDOMURL } from '../../lib/file-utils'
import { saveAs } from 'file-saver'

const useStyles = makeStyles(theme => ({
  expand: {
    flex: 1,
    margin: theme.spacing(1),
  },
  IN_PROGRESS: {
    backgroundColor: theme.palette.secondary.main,
  },
  COMPLETED: {
    backgroundColor: theme.palette.primary.main,
  },
  ERROR: {
    backgroundColor: theme.palette.error.main,
  },
  DeliveryRecord: {
    margin: theme.spacing(1),
  },
  DeliveryRecordDetails: {
    padding: theme.spacing(1),
    backgroundColor: theme.palette.grey[300],
  },
  FileDelivery: {
    borderTop: `1px solid grey`,
  },
}))

const DeliveryRecordHeader = ({
  record,
  expanded,
  onClickToggleExpand,
}) => {
  const classes = useStyles()
  const statusClasses = {
    [delivery.status.IN_PROGRESS]: classes.IN_PROGRESS,
    [delivery.status.COMPLETED]: classes.COMPLETED,
    [delivery.status.ERROR]: classes.ERROR,
  }
  return (
    <Grid container direction="row" wrap="nowrap" className={statusClasses[record.status]}>
      <Grid container direction="row" wrap="nowrap" justifyContent="center" alignItems="center" className={classes.expand}>
        <Typography>{record.platform}</Typography>
      </Grid>
      <Grid container direction="row" wrap="nowrap" justifyContent="center" alignItems="center" className={classes.expand}>
        <Typography>{record.status}</Typography>
      </Grid>
      <Grid container direction="row" wrap="nowrap" justifyContent="flex-end" alignItems="center" className={classes.expand}>
        <Typography>{`${record.updatedOn} UTC`}</Typography>
        <IconButton onClick={onClickToggleExpand}>
          {expanded ? <ExpandMore /> : <ExpandLess />}
        </IconButton>
      </Grid>
    </Grid>
  )
}

const formatS3 = dest => `s3://${dest.bucket}/${dest.path}`

const FileDelivery = ({
  item,
}) => {
  const classes = useStyles()
  const formatted = item.storage && formatS3(item.storage)
  const DOMURL = getDOMURL()

  const onClickCopyS3 = useCallback(() => {
    navigator.clipboard.writeText(formatted)
  }, [formatted])

  const onClickDownloadSvg = useCallback(() => {
    // console.log('FileDelivery/onClickDownloadSvg', item.svg)
    const svgBlob = new Blob([item.svg], {type: 'image/svg+xml;charset=utf-8'})
    const url = DOMURL.createObjectURL(svgBlob)
    // console.log('svg url', url)
    const filepath = item.storage
      ? item.storage.path.split('.').slice(0, -1).join('.')
      : `dubcard-vector-${item.index + 1}.svg`
    saveAs(url, `${filepath}.svg`)
  }, [item, DOMURL])

  return (
    <div className={classes.FileDelivery}>
      {(item.storage) && (
        <Grid container direction="row" wrap="nowrap" alignItems="center">
          <IconButton onClick={onClickCopyS3}>
            <FileCopy />
          </IconButton>
          <Typography>{formatted}</Typography>
        </Grid>
      )}

      <Grid container direction="row" wrap="nowrap" alignItems="center">
        <IconButton onClick={onClickDownloadSvg}>
          <GetAppOutlined />
        </IconButton>
        <Typography>Vector Source</Typography>
      </Grid>
    </div>
  )
}

const ErrorMessage = ({message}) => {
  return (
    <Grid container direction="row">
      <Typography variant="body2" color="error">{message}</Typography>
    </Grid>
  )
}

const DeliveryRecordDetails = ({
  record,
  getUserAttributes,
}) => {
  const classes = useStyles()

  const [userEmail, setUserEmail] = useState('')

  const settings = (record && record.settings) || {}

  useEffect(() => {
    if ((userEmail === null) || (userEmail.length > 0)) {
      return
    }

    getUserAttributes({userId: record.submittedByUserId}).then(attributes => {
      const email = attributes.find(({Name}) => Name === 'email')
      if (email) {
        setUserEmail(email.Value)
      } else {
        setUserEmail(null)
      }
    })
  }, [getUserAttributes, userEmail, setUserEmail, record.submittedByUserId])

  return (
    <div className={classes.DeliveryRecordDetails}>
      {(record.message) && <ErrorMessage message={record.message} />}
      <Grid container direction="row" wrap="wrap">
        <Grid item xs={12} lg={6}><Typography>{userEmail || `User ID: ${record.submittedByUserId}`}</Typography></Grid>
        <Grid item xs={12} lg={6}><Typography>{`SUBMITTED: ${record.createdOn} UTC`}</Typography></Grid>
      </Grid>
      <Grid container direction="row" wrap="nowrap">
        <Filler />
        <Typography>{`${settings.format}, ${settings.width} x ${settings.height}, AR ${(settings.width / settings.height).toFixed(2)}`}</Typography>
      </Grid>
      <Grid container direction="row" wrap="nowrap">
        <Typography variant="h6">Files</Typography>
        <Filler />
      </Grid>
      {record.items.map(item => (
        <FileDelivery
          key={`${record.id}::${item.index}`}
          {...{item}}
        />
      ))}
    </div>
  )
}

const DeliveryRecord = ({
  record,
  expand,
  getUserAttributes,
}) => {
  const classes = useStyles()
  const [expanded, setExpanded] = useState(expand)

  const onClickToggleExpand = useCallback(() => {
    setExpanded(ex => !ex)
  }, [setExpanded])

  return (
    <Paper className={classes.DeliveryRecord}>
      <DeliveryRecordHeader {...{record, expanded, onClickToggleExpand}} />
      {expanded && (
        <DeliveryRecordDetails {...{record, getUserAttributes}} />
      )}
    </Paper>
  )
}

export default DeliveryRecord
