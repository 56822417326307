import React, { useState, useCallback } from 'react'
import { Button, Grid, Tab, Tabs, makeStyles } from '@material-ui/core'
// import { Autocomplete } from '@material-ui/lab'
import TabCredits from './TabTitle'
import TabPanel from '../TabPanel'
import AddRoleForm from './AddRoleForm'
import TalentSelectionForCharacter from './TalentSelectionForCharacter'
import DeliveryList from './DeliveryList'
import AddDeliveryForm from './AddDeliveryForm'
import { drillDown } from 'deepdown'
import TitleTypes from '../../lib/title-types.json'
import {roles} from 'dubcard'

const {RoleTypes: EnumCreditRole} = roles


const titleTypesRequiringDeliveries = [
  TitleTypes.Feature,
  TitleTypes.Episode,
  TitleTypes.Pilot,
  TitleTypes.MadeForVideo,
]

const useStyles = makeStyles(theme => ({
  EditorTabs: {
    [theme.breakpoints.up('md')]: {
      height: '100%',
    },
  },
  TabContainer: {
    [theme.breakpoints.up('md')]: {
      height: `calc(100% - 10px - 48px)`,
      overflow: 'auto',
    },
  },
}))

const makeOverrideMatch = ({localized, original, localizedOverride}) => ({
  role: localized.role,
  ...((!localized) ? {} : {talent: {id: localized.talent.id}}),
  ...((!original) ? {} : {character: {id: original.character.id}}),
})

const EditorTabs = ({
  title,
  season: seasonTitle,
  lang,
  pages,
  talents,
  characters,
  editorLists,
  updateLocalizationToCharacterAka,
  updateBillingForLocalizedCredit,
  createTalent,
  searchTalent,
  addCreditsToLocalizedTitle,
  updateTalentForCharacterCredit,
  removeCreditFromLocalizedTitle,
  updateEpisodeOverrideForSeason,
  removeEpisodeOverrideForSeason,
  createDelivery,
  deliveryState,
  deliverySettings,
  getUserAttributes,
}) => {
  const [selectedTab, setSelectedTab] = useState(0)
  const classes = useStyles()

  const [showAddDelivery, setShowAddDelivery] = useState(false)
  const [showAddRole, setShowAddRole] = useState(false)
  const [talentSelection, setTalentSelection] = useState(null)
  const [talentSelectionInputValue, setTalentSelectionInputValue] = useState('')

  const showDeliveries = title && titleTypesRequiringDeliveries.includes(title.type)

  const onClickAddRole = useCallback(() => {
    setShowAddRole(true)
  }, [setShowAddRole])

  const onClickAddDelivery = useCallback(() => {
    setShowAddDelivery(true)
  }, [setShowAddDelivery])

  const onClickAddRoleCancel = useCallback(() => {
    setShowAddRole(false)
  }, [setShowAddRole])

  const onChangeTab = useCallback((...args) => {
    setSelectedTab(args[1])
  }, [setSelectedTab])

  const onClickCancelSelectTalent = useCallback(() => {
    setTalentSelection(null)
  }, [setTalentSelection])

  const onClickSelectTalentForCharacter = useCallback(({ original, localized, localizedOverride, isOverride}) => () => {
    console.log('EditorTabs/onClickSelectTalentForCharacter - model, original', original, 'localized', localized, 'localizedOverride', localizedOverride)

    setTalentSelection({ original, localized, localizedOverride, isOverride })
  }, [setTalentSelection])

  const addCreditsToLocalizedTitleWrapper = useCallback(({role, original, talent}) => {
    const input = {
      mpm: title && title.mpm,
      language: lang,
      credits: [
        {
          role,
          talent: {id: talent.id, aka: talent.AKAs[0].id},
          ...(!original ? {} : {
            character: {
              id: original.character.id,
              aka: original.character.aka,
            },
          }),
        },
      ],
    }
    return addCreditsToLocalizedTitle(input).then(res => {
      console.log('EditorTabs/addCreditsToLocalizedTitleWrapper/addCreditsToLocalizedTitle - success', res)
      setTalentSelection(null)
      setShowAddRole(false)
      setTalentSelectionInputValue('')
    })
  }, [ addCreditsToLocalizedTitle, lang, title, setShowAddRole, setTalentSelection ])

  const updateTalentForCharacterCreditWrapper = useCallback(({credit, newCredit}) => {
    const input = {
      mpm: title && title.mpm,
      language: lang,
      credit,
      newCredit,
    }
    return updateTalentForCharacterCredit(input).then(res => {
      console.log('Editor/updateTalentForCharacterCreditWrapper/updateTalentForCharacterCredit - success', res)
      setTalentSelection(null)
      setShowAddRole(false)
      setTalentSelectionInputValue('')
    })
  }, [title, lang, updateTalentForCharacterCredit])

  const updateEpisodeOverrideForSeasonWrapper = useCallback((input) => {
    return updateEpisodeOverrideForSeason(input).then(res => {
      console.log('Editor/updateEpisodeOverrideForSeasonWrapper/updateEpisodeOverrideForSeason - success', res)
      setTalentSelection(null)
      setShowAddRole(false)
      setTalentSelectionInputValue('')
    })
  }, [updateEpisodeOverrideForSeason])

  const onClickCreateTalent = useCallback(() => {
    const {isOverride, localizedOverride, localized, original} = talentSelection
    console.log('onClickCreateTalent', isOverride, localizedOverride)

    const model = {
      AKAs: [
        {
          value: talentSelectionInputValue,
          // language:
        },
      ],
    }
    createTalent(model).catch(error => {
      console.error('TalentSelectionForCharacter/onClickCreate/createTalent, error : ', error)
    }).then(newTalent => {
      console.log('TalentSelectionForCharacter/onClickCreate/createTalent, result : ', newTalent)
      const command = isOverride
        ? () => {
          console.log('onClickCreateTalent/createTalent, need to add/update override', localizedOverride)
          const input = {
            episode: {
              mpm: title.mpm,
              language: lang,
            },
            season: {
              mpm: seasonTitle.mpm,
            },
            override: {
              match: makeOverrideMatch({localized, localizedOverride, original}),
              value: {
                talent: {id: newTalent.id, aka: newTalent.AKAs[0].id},
              },
            },
          }
          return updateEpisodeOverrideForSeasonWrapper(input)
        }
        : (localized)
          ? () => {
            const credit = {
              role: localized.role,
              talent: {
                id: localized.talent.id,
              },
              ...(!localized.character ? {} : {
                character: localized.character,
              }),
            }
            const newCredit = {
              talent: {
                id: newTalent.id,
                aka: newTalent.AKAs[0].id,
              },
            }
            return updateTalentForCharacterCreditWrapper({credit, newCredit})
          }
          : () => addCreditsToLocalizedTitleWrapper({role: EnumCreditRole.DUBBING_VOICE, talent: newTalent, original})
      return command()
    }).catch(error => {
      console.error('TalentSelectionForCharacter/addCreditsToLocalizedTitleWrapper/createTalent, error : ', error)
    })
  }, [
    title,
    seasonTitle,
    lang,
    talentSelection,
    talentSelectionInputValue,
    createTalent,
    updateEpisodeOverrideForSeasonWrapper,
    updateTalentForCharacterCreditWrapper,
    addCreditsToLocalizedTitleWrapper,
  ])

  const onClickSelectExistingTalent = useCallback(({talent}) => () => {
    const {isOverride, localizedOverride, localized, original} = talentSelection
    console.log('TalentSelectionForCharacter/onClickSelectExistingTalent', {talent, isOverride})

    const command = isOverride
      ? () => {
        console.log('TalentSelectionForCharacter/onClickSelectExistingTalent, localizedOverride', localizedOverride)
        const input = {
          episode: { mpm: title.mpm, language: lang },
          season: { mpm: seasonTitle.mpm },
          override: {
            match: makeOverrideMatch({localized, localizedOverride, original}),
            value: {
              talent: {
                id: talent.id,
                aka: talent.AKAs[0].id,
              },
            },
          },
        }
        return updateEpisodeOverrideForSeasonWrapper(input)
      }
      : (localized)
        ? () => {
          const credit = {
            role: localized.role,
            talent: {
              id: localized.talent.id,
            },
            ...(!localized.character ? {} : {
              character: {
                id: localized.character.id,
                aka: localized.character.aka,
              },
            }),
          }
          const newCredit = {
            talent: {
              id: talent.id,
              aka: talent.AKAs[0].id,
            },
          }
          return updateTalentForCharacterCreditWrapper({credit, newCredit})
        }
        : () => addCreditsToLocalizedTitleWrapper({role: EnumCreditRole.DUBBING_VOICE, talent, original})
    return command().catch(error => {
      console.error('TalentSelectionForCharacter/onClickSelectExistingTalent - error', error)
    })
  }, [talentSelection, title, seasonTitle, lang, updateEpisodeOverrideForSeasonWrapper, addCreditsToLocalizedTitleWrapper, updateTalentForCharacterCreditWrapper])

  const onClickMask = useCallback(({localized, original, localizedOverride}) => () => {
    const input = {
      episode: {mpm: title && title.mpm, language: lang},
      season: {mpm: seasonTitle.mpm},
      override: {
        match: makeOverrideMatch({localized, original, localizedOverride}),
        value: {
          // the update
          hidden: (!localizedOverride) ? true : !drillDown(localizedOverride, ['value', 'hidden']),
        },
      },
    }
    console.log('TabCredits/onClickMask', input)
    updateEpisodeOverrideForSeason(input).then(result => {
      console.log('TabTitle/onClickMask/updateEpisodeOverrideForSeason - result', result)
    }).catch(error => {
      console.error('TabTitle/onClickMask/updateEpisodeOverrideForSeason - error', error)
    })
  }, [title, lang, seasonTitle, updateEpisodeOverrideForSeason])

  const onClickDeleteCredit = useCallback(({original, localized}) => () => {
    console.log('onClickDeleteCredit', localized, original)
    if (!localized) {
      console.log('onClickDeleteCredit, localized not defined')
      return
    }

    const input = {
      mpm: title && title.mpm,
      language: lang,
      credit: {
        role: localized.role,
        talent: {id: localized.talent.id},
        ...(!localized.character ? {} : {character: {id: localized.character.id}}),
      },
    }
    removeCreditFromLocalizedTitle(input).then(result => {
      console.log('onClickDeleteCredit/removeCreditFromLocalizedTitle, result', result)
    }).catch(error => {
      console.log('onClickDeleteCredit/removeCreditFromLocalizedTitle, error', error)
    })
  }, [title, lang, removeCreditFromLocalizedTitle])

  const onClickDeleteOverride = useCallback(({localized, localizedOverride}) => () => {
    console.log('EditorTabs/onClickDeleteOverride, localizedOverride', localizedOverride)

    const input = {
      episode: {
        mpm: title.mpm,
        language: lang,
      },
      season: {
        mpm: seasonTitle.mpm,
      },
      match: localizedOverride.match,
    }
    console.log('EditorTabs/onClickDeleteOverride, input', input)
    removeEpisodeOverrideForSeason(input).catch(error => {
      console.error('EditorTabs/onClickDeleteOverride - error', error)
    })
  }, [lang, seasonTitle, title, removeEpisodeOverrideForSeason])

  return (
    <div className={classes.EditorTabs}>
      <Grid container direction="row" wrap="nowrap">
        <Grid item xs={10}>
          <Tabs
            value={selectedTab}
            indicatorColor="primary"
            textColor="primary"
            onChange={onChangeTab}
          >
            <Tab label="Credits" />
            {showDeliveries && <Tab label="Deliveries" />}
          </Tabs>
        </Grid>
        {(!showAddRole && !talentSelection) && (selectedTab === 0) && (
          <Grid item container direction="row" justifyContent="flex-end">
            <Button size="small" onClick={onClickAddRole}>+ROLE</Button>
          </Grid>
        )}
        {(showDeliveries && !showAddDelivery) && (selectedTab === 1) && (
          <Grid item container direction="row" justifyContent="flex-end">
            <Button size="small" onClick={onClickAddDelivery}>+DELIVERY</Button>
          </Grid>
        )}
      </Grid>
      <div className={classes.TabContainer}>
        <TabPanel selectedIndex={selectedTab} index={0}>
          {talentSelection && (
            <TalentSelectionForCharacter {...{
              ...(talentSelection || {}),
              characters,
              searchTalent,
              inputValue: talentSelectionInputValue,
              setInputValue: setTalentSelectionInputValue,
              onClickCreateTalent,
              onClickSelectExistingTalent,
              onClickCancelSelectTalent,
            }} />
          )}
          {showAddRole && (
            <AddRoleForm {...{
              createTalent,
              searchTalent,
              addCreditsToLocalizedTitle: addCreditsToLocalizedTitleWrapper,
              onClickAddRoleCancel,
            }} />
          )}
          {(!talentSelection && !showAddRole) && (
            <TabCredits {...{
              lang,
              showAddRole,
              characters,
              talents,
              season: seasonTitle,
              editorLists,
              updateLocalizationToCharacterAka,
              updateBillingForLocalizedCredit,
              onClickSelectTalentForCharacter,
              onClickMask,
              onClickDeleteOverride,
              onClickDeleteCredit,
            }} />
          )}
        </TabPanel>
        <TabPanel selectedIndex={selectedTab} index={1}>
          {(showAddDelivery)
            ? (
              <AddDeliveryForm {...{
                deliverySettings,
                setShowAddDelivery,
                createDelivery,
                title,
                language: lang,
                pages,
              }} />
            )
            : (
              <DeliveryList {...{deliveryState, getUserAttributes}} />
            )}
        </TabPanel>
      </div>
    </div>
  )
}

export default EditorTabs
