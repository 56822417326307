import React from 'react'

import {Link as RouterLink} from 'react-router-dom'

import { Button, makeStyles } from '@material-ui/core'
import { LinkOff as LinkOffIcon } from '@material-ui/icons'

import clsx from 'clsx'

const useStyles = makeStyles(theme => ({
  CharacterEditRoot: {
    width: '100%',
  },
  CharacterEditRootInherited: {
    backgroundColor: theme.palette.primary.dark,
  },
  CharacterEditLabel: {
    whiteSpace: 'nowrap',
  },
}))

const OriginalCharacter = ({ inherited, id, label }) => {
  const classes = useStyles()
  return (
    <Button
      disableElevation
      component={RouterLink}
      to={`/character?id=${id}`}
      size="small"
      classes={{
        root: clsx(classes.CharacterEditRoot, inherited && classes.CharacterEditRootInherited),
        label: classes.CharacterEditLabel,
      }}
      color={"primary"}
      variant="contained"
      endIcon={!id && <LinkOffIcon />}
    >{label}</Button>
  )
}

export default OriginalCharacter
