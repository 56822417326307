import { useState, useRef } from 'react'
import { Menu as MenuIcon, CloudUpload } from '@material-ui/icons'
import OpenInNewIcon from '@material-ui/icons/OpenInNew'

import {
  AppBar,
  Grid,
  Hidden,
  IconButton,
  SwipeableDrawer,
  Toolbar,
  Tooltip,
  Typography,
  makeStyles,
  Button
} from '@material-ui/core'

import SearchInput from './SearchInput'
import SideMenu from './SideMenu'
import Brand from './Brand'

import WMLoginButton from './WMLoginButton'
import clsx from 'clsx'
import { formatTitle } from '../lib/models'


const useStyles = makeStyles(theme => ({
  buttonActive: {
    '& svg': {
      fill: theme.palette.common.white,
    },
  },
  buttonDisabled: {
    '& svg': {
      fill: theme.palette.grey[900],
    },
  },
  button: {
    whiteSpace: 'nowrap',
    // minWidth: 120,
    marginLeft: theme.spacing(1),
  },
  grow: {
    flexGrow: 1,
  },
  menuButton: {
    color: theme.palette.common.white,
  },
  rightMenuIcons: {
  },
  title: {
    flex: 1,
    marginLeft: theme.spacing(1),
    textDecoration: 'none',
    [theme.breakpoints.up('sm')]: {
      display: 'block',
    },
  },
  toolbar: {
    justifyContent: 'space-between',
    overflow: 'hidden',
  },
}))

const toggleDrawer = (open, setState, state) => event => {
  if (event && event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
    return
  }

  setState({ ...state, drawer: open })
}

const Upload = ({ inputRef, onClickButton, onChangeInput, disabled=false }) => {
  const classes = useStyles()

  return (
    <Tooltip title={disabled ? "XLSX Upload temporarily disabled" : "Upload spreadsheet for any title"}>
      <span>
        <IconButton
          disabled={disabled}
          id="xlsx-upload"
          className={clsx(classes.button, !disabled && classes.buttonActive)}
          variant="contained"
          size="small"
          onClick={onClickButton}
        >
          <CloudUpload />
          <input id="xlsx-file" ref={inputRef} type="file" accept="*" style={{ display: "none" }} onChange={onChangeInput} />
        </IconButton>
      </span>
    </Tooltip>
  )
}

const Header = ({
  user,
  location,
  mpm,
  titles,
  showInput,
  uploadDisabled,
  importedXlsx,
  // importedData,
  onChangeImportUpload,
  filterTerm,
  onChangeTerm,
  onClickClearTerm,
  onClickExpandInput,
  pageActions,
}) => {
  const classes = useStyles()
  const refUpload = useRef(null)

  const importingMpm = importedXlsx?.data?.mpm
  const xlsxImportFormattedTitle = (!!importingMpm)
    ? formatTitle(importingMpm, titles, {showIpType: true}).join(' - ')
    : undefined

  const [state, setState] = useState({
    drawer: false,
  })

  const onClickUpload = () => {
    // console.log('onClickUpload', 'ref.current', refUpload.current)
    refUpload.current.click()
  }

  return (
    <div>
      <AppBar position="static">
        <Toolbar className={classes.toolbar}>
          <IconButton disabled={!!(importedXlsx && importedXlsx.data)} edge="start" className={classes.menuButton} color="inherit" aria-label="open drawer" onClick={toggleDrawer(true, setState, state)}>
            <MenuIcon />
          </IconButton>
          <Grid item className={classes.title}>
            {(importedXlsx && importedXlsx.data)
              ? <Typography variant="h6" noWrap>{`Import Review${(!!xlsxImportFormattedTitle) ? `: ${xlsxImportFormattedTitle}` : ''}`}</Typography>
              : <Brand {...{ titles, mpm }} />
            }
          </Grid>
          {(importedXlsx.data)
            ? (
              <Button
                variant="text"
                component="a"
                href={`/mpm/${importedXlsx?.data?.mpm}`}
                target="_blank"
                rel="noopener noreferrer"
                endIcon={<OpenInNewIcon />}
                className={classes.ViewTitle}
              >
                View Title
              </Button>
            )
            : (
              <Grid container direction="row" justifyContent="flex-end" alignItems="center" wrap="nowrap" className={classes.rightMenuIcons}>
                {!user ? null : <SearchInput {...{ showInput, filterTerm, onClickClearTerm, onChangeTerm, onClickExpandInput }} />}
                <Hidden xsDown>{!user ? null : <Grid item><Typography>DUBSHEET</Typography></Grid>}</Hidden>
                {!user ? null : <Upload inputRef={refUpload} disabled={uploadDisabled} onClickButton={onClickUpload} onChangeInput={onChangeImportUpload} />}
                {!user ? null : pageActions}
                {!user ? <WMLoginButton /> : null}
              </Grid>
            )}
        </Toolbar>
      </AppBar>
      <SwipeableDrawer open={state.drawer} onOpen={toggleDrawer(true, setState, state)} onClose={toggleDrawer(false, setState, state)} anchor="left">
        <SideMenu {...{ user, location, titles, mpm }} toggleDrawer={(open) => toggleDrawer(open, setState, state)} />
      </SwipeableDrawer>
    </div>
  )
}

export default Header
