import React, { FC } from 'react'

import {
  makeStyles
} from '@material-ui/core'

import clsx from 'clsx'

const useStyles = makeStyles(theme => ({
  expand: {
    flex: 1,
  },
}))

export interface ExpandContainerProps {
  className?: string
}

const ExpandContainer: FC<ExpandContainerProps> = ({
  children,
  className,
}) => {
  const classesInternal = useStyles()

  return (
    <div className={clsx(classesInternal.expand, className)}>
      {children}
    </div>
  )
}

export default ExpandContainer
