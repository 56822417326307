import { makeStyles } from "@material-ui/core"

import ImportingBehavior from "./ImportingBehavior"
import ImportingName from "./ImportingName"
import ImportingExistingTalent from "./ImportingExistingTalent"

import clsx from 'clsx'
import {bulk} from 'dubcard'

const {BehaviorMode} = bulk

const useStylesImportingTalent = makeStyles(theme => ({
  ImportingTalent: {
    padding: `0 ${theme.spacing(1)}px`,
    gap: theme.spacing(1),
  },
  ImportingBehavior: {
    minWidth: 160,
    maxWidth: 160,
    width: 160,
  },
}))


const ImportingTalent = ({
  credit,
  importBehaviors,
  talents,
  onChangeTalent,
  searchTalent,
  classesBehavior,
  onChangeBehaviorTalent,
}) => {
  const classes = useStylesImportingTalent()
  //, setTalentId, onChangeTalentName, onChangeForceNewTalent, titles, searchTalent, getTitlesForTalent}} />

  // console.log('ImportingTalent, credit', credit)
  const mode = credit?.talent?.mode

  const behaviorClass = clsx(
    (credit.ignore)
      ? classesBehavior.XlsxBehaviorIgnore
      : (mode === BehaviorMode.USE_EXISTING && !!credit?.talent?.entity?.id)
        ? classesBehavior.XlsxBehaviorUseExisting
        : classesBehavior.XlsxBehaviorCreateNew
  )

  return (
    <>
      <ImportingName label="Importing Talent" value={credit.xlsxRole.talentName} behaviorClass={behaviorClass} />
      <ImportingBehavior {...{mode, className: clsx(classes.ImportingBehavior, behaviorClass), onChangeBehavior: onChangeBehaviorTalent}} />
      <ImportingExistingTalent {...{credit, importBehaviors, talents, onChangeTalent, searchTalent, className: behaviorClass}} />
    </>
  )
}

export default ImportingTalent
