import { Tabs, Tab, makeStyles } from "@material-ui/core"
import { useState, useCallback } from 'react'

import AkaLocalization from "./AkaLocalization"
import AkaTitle from "./AkaTitle"
import TabPanel from "../TabPanel"

const useStyles = makeStyles(theme => ({
  CharacterAkaExpandedContent: {
    paddingTop: theme.spacing(2),
    minHeight: 250,
  },
  AkaLocalizations: {
    padding: theme.spacing(1),
  },
  AkaTitles: {
    padding: theme.spacing(1),
    // width: '100%',
  },
}))

const AkaLocalizations = ({
  aka,
  character,
  saveMethod,
  onClickDelete,
}) => {
  const classes = useStyles()
  return (
    <div className={classes.AkaLocalizations}>{
      (aka.localizations || []).map((localization) => {
        return (
          <AkaLocalization
            key={`AkaLocalization/AKA/${aka.id})/mpm/${localization.language}`}
            {...{
              aka,
              character,
              localization,
              nameLabel: "Localized Name",
              saveMethod,
              // onClickDelete: onClickDelete(localization),
            }}
          />
        )
      })
    }</div>
  )
}

const AkaTitles = ({
  aka,
  titles,
}) => {
  const classes = useStyles()
  return (
    <div className={classes.AkaTitles}>{
      aka.mpm.map((mpm) => {
        return (
          <AkaTitle
            key={`AkaTitles/AKA/${aka.id}/mpm/${mpm}`}
            {...{
              mpm,
              titles,
            }}
          />
        )
      })
    }</div>
  )  
}

const CharacterAkaExpandedContent = ({
  aka,
  character,
  titles,
  saveLocalized,
}) => {
  const classes = useStyles()

  const [selectedTab, setSelectedTab] = useState(0)
  const onChangeTab = useCallback((...args) => {
    setSelectedTab(args[1])
  }, [setSelectedTab])

  const onClickDelete = useCallback((localization) => () => {
    console.log(`onClickDelete (${localization}), localization:`, character.id, aka.id)
  }, [character, aka])

  return (
    <div className={classes.CharacterAkaExpandedContent}>
      <Tabs
        value={selectedTab}
        onChange={onChangeTab}
        textColor="primary"
        indicatorColor="primary"
      >
        <Tab label="LOCALIZATIONS" />
        <Tab label="TITLES" />
      </Tabs>
      <TabPanel index={0} selectedIndex={selectedTab}>
        <AkaLocalizations {...{
          nameLabel: "Localized Name",
          aka,
          character,
          saveMethod: saveLocalized,
          onClickDelete,
        }} />
      </TabPanel>

      <TabPanel index={1} selectedIndex={selectedTab}>
        <AkaTitles {...{
          aka,
          character,
          titles,
        }} />
      </TabPanel>
    </div>
  )
}

export default CharacterAkaExpandedContent