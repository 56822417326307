import React, { Fragment } from 'react'

import { CssBaseline } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import Header from './Header'
import ImportReview from './import/ImportReview'

import SearchResults from '../components/SearchResults'
import SplashLogin from '../components/SplashLogin'
import config from '../config'

const heightFn = theme => (accum, key) => {
  const height = theme.mixins.toolbar[key]

  return {
    ...accum,
    [key]: {
      height: `calc(100% - ${height})`,
    },
  }
}

const useStyles = makeStyles(theme => ({
  root: {
    height: `calc(100% - ${theme.mixins.toolbar.minHeight}px)`,
    ...(Object.keys(theme.mixins.toolbar).filter(key => key !== 'minHeight').reduce(heightFn(theme), {})),
  },
  fullHeight: {
    height: '100%',
  },
  item: {
    margin: theme.spacing(2),
    textAlign: 'center',
  },
  indent: {
    padding: 0,
    [theme.breakpoints.up("xs")]: {
      padding: `0 ${theme.spacing(1)}px`,
    },
  },
}))


const Layout = (props) => {
  const classes = useStyles()

  const {
    children,
    user,
    titles,
    importedXlsx,
    searchState,
    onClickClearTerm,
    signIn,
  } = props

  return (
    <Fragment>
      <CssBaseline />
      <Header {...props} />
      <main className={classes.root}>
        {(!user)
          ? <SplashLogin {...{ signIn, useLocalLogin: config.app.useLocalLogin }} />
          : (importedXlsx && importedXlsx.data)
            ? <ImportReview {...props} />
            : (searchState.term)
              ? <SearchResults {...{ titles, data: searchState, onClickClearTerm, heading: 'Search Results' }} />
              : children
        }
      </main>
    </Fragment>
  )
}

export default Layout
