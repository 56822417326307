import React, { useState } from 'react'
import { Link as RouterLink } from 'react-router-dom'
import { Grid, Typography, makeStyles, Paper } from '@material-ui/core'
import DivBgImg from './DivBgImg'
import CircularProgress from '@material-ui/core/CircularProgress'
import { poster } from '../lib/movie-details'
import config from '../config'
import LanguageList from './LanguageList'
import { formatTitle, getReleaseYear } from '../lib/models'
import { drillDown, indexByKey } from 'deepdown'

const rowHeight = 230
const imgHeight = 200

const useStyles = makeStyles(theme => ({
  divBgImg: {
    backgroundColor: 'purple',
  },
  imgContainer: {
    // flexBasis: 150,
    // flex: 1,
    width: '100%',
    height: imgHeight,
    marginTop: 'auto',
    marginBottom: 'auto',
    // backgroundColor: 'blue',
  },
  bgImage: {
    borderRadius: 4*theme.shape.borderRadius,
    height: '100%',
    backgroundPosition: 'center',
    backgroundSize: 'cover',
    'background-repeat': 'no-repeat',
  },
  titleBanner: {
    flexBasis: 30,
    // flex: 1,
    backgroundColor: 'lightgreen',
  },
  poster: {},
  SearchMetadata: {
    padding: theme.spacing(1),
    height: rowHeight,
    // backgroundColor: 'cyan',
  },
  SearchPoster: {
    margin: `0 ${theme.spacing(2)}px`,
    height: '100%',
    flexBasis: 150,
    minWidth: 132,
    // backgroundColor: 'yellow',
  },
  SearchResult: {
    // flexBasis: 200,
    height: rowHeight,
    margin: `${theme.spacing(1)}px 0`,
    // backgroundColor: theme.palette.grey[400], //'#424242'
  },
  SearchResultPaper: {
    marginLeft: 'auto',
    marginRight: 'auto',
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
    width: '100%',
    maxWidth: theme.breakpoints.values['md'],
  },
}))

const Poster = ({ to, onClick, image = '/logos/default-poster.jpg', fallbackImgUrl = '/logos/default-poster.jpg' }) => {
  const classes = useStyles()
  const [wasError, setWasError] = useState(!image)
  const [imgLoaded, setImgLoaded] = useState(false)

  const onError = e => {
    console.error(`bad poster url`, e.target.src)
    setWasError(true)
  }

  const onLoad = e => {
    e.preventDefault()
    console.log(`good poster url`, e.target.src)
    setImgLoaded(true)
  }

  return (
    <Grid
      item
      className={classes.imgContainer}
      component={RouterLink}
      to={to}
      onClick={onClick}
    >
      <DivBgImg {...{ onError, onLoad, className: classes.bgImage, image: wasError ? fallbackImgUrl : image }}>
        {(!wasError && !imgLoaded) && <CircularProgress  />}
      </DivBgImg>
    </Grid>
  )
}

// const TitleBanner = ({result}) => {
//   const classes = useStyles()
//   return (
// <Grid item xs={12} className={classes.titleBanner} container direction="row" alignItems="center" wrap="nowrap" justifyContent="center">
//   <Typography variant="h5" component="h2">{`${result.title} ${result.originalReleaseYear}`}</Typography>
// </Grid>
//   )
// }

const getPoster = (result, titles, preferredRendition=config.app.preferredRendition) => {
  const title = titles[result.mpm]
  let titlePoster = title && title.poster
  const type = title && title.type
  if (title && !titlePoster && ['Episode', 'Pilot'].includes(type) && title.mpmProductNumber) {
    titlePoster = drillDown(titles, [title.mpmProductNumber, 'poster'])
  } else if (title && !titlePoster && type === 'Series' && title.mpmFamilyNumber) {
    const titlesByFamily = indexByKey(Object.keys(titles).map(id => titles[id]), ['mpmFamilyNumber'])
    titlePoster = drillDown(titlesByFamily, [title.mpmFamilyNumber, '0', 'poster'])
  }
  const rendition = titlePoster && poster(titlePoster, preferredRendition)
  return rendition
}

const SearchPoster = ({ result, titles, onClickPoster }) => {
  const classes = useStyles()
  const rendition = getPoster(result, titles)
  // console.log('search poster preferredRendition', config.app.preferredRendition, rendition)

  return (
    <Grid 
      item xs={4} sm={3} md={2} lg={1} container direction="column" wrap="nowrap" alignItems="flex-start" justifyContent="flex-start" className={classes.SearchPoster}>
      <Poster image={rendition && rendition.url} to={`/mpm/${result.mpm}`} onClick={onClickPoster} />
      {/* <TitleBanner result={result} /> */}
    </Grid>
  )
}

const SearchMetadata = ({ result, titles, onClickLang }) => {
  // console.log('SearchMetadata - onClickLang', onClickLang)
  const classes = useStyles()
  const [strTitle, subTitle] = formatTitle(result.mpm, titles, {showReleaseYear: false})
  const title = titles[result.mpm]
  const year = getReleaseYear(title)
  const type = !title ? '' : (year && title.type ? ' - ' : '') + (title.type || '')
  const strInfo = year ? year + type : type
  const indexLocalizedByLang = !title ? {} : indexByKey(title.localized || [], ['language'])

  return (
    <Grid container direction="column" className={classes.SearchMetadata}>
      <Grid item><Typography variant="h5">{strTitle}</Typography></Grid>
      {!!subTitle && <Grid item><Typography variant="h6">{subTitle}</Typography></Grid>}
      <Grid item>
        {title && <Typography variant="body1" align="left">{strInfo}</Typography>}
      </Grid>
      <LanguageList {...{title: titles[result.mpm], indexLocalizedByLang, onClickLang}} />
    </Grid>
  )
}

const SearchResultItem = ({ result, titles, onClickPoster, onClickLang }) => {
  const classes = useStyles()

  return (
    <Paper className={classes.SearchResultPaper}>
      <Grid container direction="row" wrap="nowrap" justifyContent="flex-start" alignItems="flex-start" className={classes.SearchResult}>
        <SearchPoster result={result} titles={titles} onClickPoster={onClickPoster} />
        <SearchMetadata result={result} titles={titles} onClickLang={onClickLang} />
      </Grid>
    </Paper>
  )
}

export default SearchResultItem
