"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.defaultRasterSettings = exports.defaultLayout = exports.defaultRasterFormat = exports.RasterFormat = exports.defaultFont = exports.Fonts = void 0;
const format_settings_1 = require("./format-settings");
exports.Fonts = {
    Roboto: "Roboto",
    RobotoSlab: "Roboto Slab",
    Tangerine: "Tangerine",
};
exports.defaultFont = exports.Fonts.Roboto;
exports.RasterFormat = {
    PNG: "PNG",
    JPEG: "JPEG",
    TIFF: "TIFF",
};
exports.defaultRasterFormat = exports.RasterFormat.PNG;
exports.defaultLayout = 'HD';
exports.defaultRasterSettings = {
    width: format_settings_1.HD.width,
    height: format_settings_1.HD.height,
    font: exports.Fonts.Roboto,
    format: exports.RasterFormat.PNG,
};
