import { useCallback, useEffect, useRef, useState } from "react"

import { Grid, IconButton, makeStyles, Paper, TextField, Tooltip } from "@material-ui/core"
import { Clear, Delete, Lock, LockOpen } from "@material-ui/icons"

const useStyles = makeStyles(theme => ({
  TalentAka: {
    padding: theme.spacing(1),
    margin: `${theme.spacing(1)}px 0`,
  },
}))

const TalentAka = ({
  talent,
  aka,
  updateTalentAka,
}) => {
  // TODO: the aka is not being updated after calling API,
  // which causes CLEAR button to show wrong value

  const classes = useStyles()
  const inputRef = useRef()
  const [editing, setEditing] = useState(false)
  const [akaValue, setAkaValue] = useState(aka.value)
  // const [langValue, setLangValue] = useState(aka.language)
  const valueEqual = aka.value === akaValue

  useEffect(() => {
    if (editing) {
      inputRef.current.focus()
    }
  }, [editing])

  const onClickEnableEditing = useCallback(() => {
    setEditing(true)
  }, [setEditing])

  const onChangeAka = useCallback(e => {
    setAkaValue(e.target.value)
  }, [setAkaValue])

  const onClickRevertChanges = useCallback(() => {
    setAkaValue(aka.value)
    setEditing(false)
  }, [aka, setAkaValue])

  const onClickSaveEdits = useCallback(() => {
    if (akaValue === aka.value) {
      return setEditing(false)
    }

    const input = {
      id: talent.id,
      AKA: {
        id: aka.id,
        value: akaValue,
        ...(!aka.language ? {} : {language: aka.language}),
      },
    }
    updateTalentAka(input).then(result => {
      console.log(`/talent/TalentAka/onClickSaveEdits/updateTalentAka(${JSON.stringify(input)}), result`, result)
      setEditing(false)
    }).catch(error => {
      console.error(`/talent/TalentAka/onClickSaveEdits/updateTalentAka(${JSON.stringify(input)}), error`, error)
    })
  }, [updateTalentAka, talent, aka, akaValue])

  const onClickDeleteAka = useCallback(() => {
    console.log('TODO: /talent/aka/onClickDeleteAka', aka)
  }, [aka])

  return (
    <Paper className={classes.TalentAka}>
      <Grid container direction="row" wrap="nowrap" alignItems="center">
        <Tooltip title={editing ? `Click to ${valueEqual ? 'protect' : 'save'}` : "Unlock to edit"}>
          <IconButton
            onClick={editing ? onClickSaveEdits : onClickEnableEditing}
            color={editing ? (valueEqual ? "primary" : "secondary") : "default"}
          >
            {editing ? <LockOpen /> : <Lock />}
          </IconButton>
        </Tooltip>
        <TextField
          fullWidth
          inputRef={inputRef}
          disabled={!editing}
          value={akaValue}
          onChange={onChangeAka}
          InputProps={{
            endAdornment: editing && (
              <Tooltip title={valueEqual ? "Close editing" : `Revert to: ${aka.value}`}>
                <span>
                  <IconButton
                    disabled={!editing}
                    onClick={onClickRevertChanges}
                    color={editing ? (valueEqual ? "primary" : "secondary") : "default"}
                  >
                    <Clear />
                  </IconButton>
                </span>
              </Tooltip>
            ),
          }}
        />
        <Grid item xs={1}></Grid>
        {false && (
          <Tooltip title="Delete AKA">
            <span>
              <IconButton
                disabled={true}
                onClick={onClickDeleteAka}
              >
                <Delete />
              </IconButton>
            </span>
          </Tooltip>
        )}
      </Grid>
    </Paper>
  )
}

export default TalentAka
