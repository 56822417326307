import React, { Fragment } from 'react'
import { useLocation } from "react-router-dom"
import { makeStyles } from '@material-ui/core/styles'
import {
  Home as HomeIcon
  // RecordVoiceOver as RecordVoiceOverIcon,
} from '@material-ui/icons'

import {
  Grid,
  Hidden,
  Typography
} from '@material-ui/core'

import { formatTitle } from '../lib/models'
// import { getReleaseYear } from '../lib/models'
// import { displayName } from '../lib/movie-details'

const useStyles = makeStyles(theme => ({
  grow: {
    flexGrow: 1,
  },
  menuButton: {
    // marginRight: theme.spacing(2),
  },
  rightMenuIcons: {

  },

  icon: {
    color: theme.palette.primary.contrastText,
  },

  container: {
    flex: 1,
  },

  title: {
    color: theme.palette.primary.contrastText,
    marginLeft: theme.spacing(1),
    textDecoration: 'none',
    [theme.breakpoints.up('sm')]: {
      display: 'block',
    },
  },

  toolbar: {
    justifyContent: 'space-between',
  },
}))

const routeBrands = [
  {
    route: /^\/$/,
    controller: ({ classes }) => {
      const stuff = {
        icon: <HomeIcon />,
        shortTitle: 'Localization',
        longTitle: 'Localization: Associate voice talent with shows',
      }

      return (
        <Fragment>
          {!stuff.icon ? null : stuff.icon}
          <Hidden mdUp> <Typography className={classes.title} variant="h6" noWrap> {stuff.shortTitle} </Typography> </Hidden>
          <Hidden smDown> <Typography className={classes.title} variant="h6" noWrap> {stuff.longTitle} </Typography> </Hidden>
        </Fragment>
      )
    },
  },
  {
    route: /^\/mpm\/.*$/,
    controller: ({ titles, mpm, classes }) => {
      const longTitle = mpm && titles && formatTitle(mpm, titles, {defaultTitle: 'Title Credits', showIpType: true}).join(': ')
      const shortTitle = mpm && titles && formatTitle(mpm, titles, {defaultTitle: 'Title Credits', shortVersion: true}).join(': ')
      const stuff = {
        longTitle,
        shortTitle,
      }

      return (
        <Fragment>
          {!stuff.icon ? null : stuff.icon}
          <Hidden mdUp> <Typography className={classes.title} variant="h6" noWrap> {stuff.shortTitle} </Typography> </Hidden>
          <Hidden smDown> <Typography className={classes.title} variant="h6" noWrap> {stuff.longTitle} </Typography> </Hidden>
        </Fragment>
      )
    },
  },
  {
    route: /^\/character.*$/,
    controller: () => {
      return (
        <Typography variant="h6" noWrap>Character</Typography>
      )
    },
  },
  {
    route: /^\/talent.*$/,
    controller: () => {
      return (
        <Typography variant="h6" noWrap>Talent</Typography>
      )
    },
  },
]

const defaultBrandController = () => {
  return (
    <Typography variant="h6" noWrap>Missing Controller</Typography>
  )
}

const Brand = ({ titles, mpm }) => {
  const classes = useStyles()
  const location = useLocation()
  const foundBrand = routeBrands.find(rb => rb.route.test([location.pathname]))
  let brandController
  if (!foundBrand) {
    console.error('could not find brand route, ', location.pathname)
    brandController = defaultBrandController
  } else {
    brandController = foundBrand.controller
  }

  return (
    <Grid container direction="row" wrap="nowrap" alignItems="center" className={classes.container}>
      {brandController({ titles, mpm, classes })}
    </Grid>
  )
}

export default Brand
