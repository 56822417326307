// TODO: remove these self-hosted fonts eventually in favor of `loadFonts` below
import 'typeface-roboto'
import 'typeface-roboto-slab'
import 'typeface-tangerine'

// TODO: remove this mock and actually invoke API to get 5000 fonts?
const googleFontsResponse = [
  {
    "kind": "webfonts#webfont",
    "family": "Roboto",
    "category": "sans-serif",
    "variants": [
      "100",
      "100italic",
      "300",
      "300italic",
      "regular",
      "italic",
      "500",
      "500italic",
      "700",
      "700italic",
      "900",
      "900italic",
    ],
    "subsets": [
      "vietnamese",
      "latin",
      "greek-ext",
      "cyrillic-ext",
      "greek",
      "latin-ext",
      "cyrillic",
    ],
    "version": "v20",
    "lastModified": "2019-07-24",
    "files": {
      "100": "http://fonts.gstatic.com/s/roboto/v20/KFOkCnqEu92Fr1MmgWxPKTM1K9nz.ttf",
      "100italic": "http://fonts.gstatic.com/s/roboto/v20/KFOiCnqEu92Fr1Mu51QrIzcXLsnzjYk.ttf",
      "300": "http://fonts.gstatic.com/s/roboto/v20/KFOlCnqEu92Fr1MmSU5vAx05IsDqlA.ttf",
      "300italic": "http://fonts.gstatic.com/s/roboto/v20/KFOjCnqEu92Fr1Mu51TjARc9AMX6lJBP.ttf",
      "regular": "http://fonts.gstatic.com/s/roboto/v20/KFOmCnqEu92Fr1Me5WZLCzYlKw.ttf",
      "italic": "http://fonts.gstatic.com/s/roboto/v20/KFOkCnqEu92Fr1Mu52xPKTM1K9nz.ttf",
      "500": "http://fonts.gstatic.com/s/roboto/v20/KFOlCnqEu92Fr1MmEU9vAx05IsDqlA.ttf",
      "500italic": "http://fonts.gstatic.com/s/roboto/v20/KFOjCnqEu92Fr1Mu51S7ABc9AMX6lJBP.ttf",
      "700": "http://fonts.gstatic.com/s/roboto/v20/KFOlCnqEu92Fr1MmWUlvAx05IsDqlA.ttf",
      "700italic": "http://fonts.gstatic.com/s/roboto/v20/KFOjCnqEu92Fr1Mu51TzBhc9AMX6lJBP.ttf",
      "900": "http://fonts.gstatic.com/s/roboto/v20/KFOlCnqEu92Fr1MmYUtvAx05IsDqlA.ttf",
      "900italic": "http://fonts.gstatic.com/s/roboto/v20/KFOjCnqEu92Fr1Mu51TLBBc9AMX6lJBP.ttf",
    },
  },
  {
    "kind": "webfonts#webfont",
    "family": "Roboto Slab",
    "category": "serif",
    "variants": [
      "100",
      "200",
      "300",
      "regular",
      "500",
      "600",
      "700",
      "800",
      "900",
    ],
    "subsets": [
      "vietnamese",
      "latin",
      "greek-ext",
      "cyrillic-ext",
      "greek",
      "latin-ext",
      "cyrillic",
    ],
    "version": "v10",
    "lastModified": "2019-11-14",
    "files": {
      "100": "http://fonts.gstatic.com/s/robotoslab/v10/BngbUXZYTXPIvIBgJJSb6s3BzlRRfKOFbvjojIWWaG5iddG-1A.ttf",
      "200": "http://fonts.gstatic.com/s/robotoslab/v10/BngbUXZYTXPIvIBgJJSb6s3BzlRRfKOFbvjoDISWaG5iddG-1A.ttf",
      "300": "http://fonts.gstatic.com/s/robotoslab/v10/BngbUXZYTXPIvIBgJJSb6s3BzlRRfKOFbvjo0oSWaG5iddG-1A.ttf",
      "regular": "http://fonts.gstatic.com/s/robotoslab/v10/BngbUXZYTXPIvIBgJJSb6s3BzlRRfKOFbvjojISWaG5iddG-1A.ttf",
      "500": "http://fonts.gstatic.com/s/robotoslab/v10/BngbUXZYTXPIvIBgJJSb6s3BzlRRfKOFbvjovoSWaG5iddG-1A.ttf",
      "600": "http://fonts.gstatic.com/s/robotoslab/v10/BngbUXZYTXPIvIBgJJSb6s3BzlRRfKOFbvjoUoOWaG5iddG-1A.ttf",
      "700": "http://fonts.gstatic.com/s/robotoslab/v10/BngbUXZYTXPIvIBgJJSb6s3BzlRRfKOFbvjoa4OWaG5iddG-1A.ttf",
      "800": "http://fonts.gstatic.com/s/robotoslab/v10/BngbUXZYTXPIvIBgJJSb6s3BzlRRfKOFbvjoDIOWaG5iddG-1A.ttf",
      "900": "http://fonts.gstatic.com/s/robotoslab/v10/BngbUXZYTXPIvIBgJJSb6s3BzlRRfKOFbvjoJYOWaG5iddG-1A.ttf",
    },
  },
  {
    "family": "Tangerine",
    "category": "serif",
  },
]

// TODO: pull from API. will that be expensive? should we limit it to a few hand-picked fonts?
export const fetchFonts = async () => {
  // console.log('TODO: stop faking API call to google-fonts, instead use our proxy to protect our api key')
  return {
    data: {
      fetchFonts: googleFontsResponse,
    },
  }
}

export const loadFonts = async ({fonts}) => {
  // console.log('TODO: download and use the actual fonts: ', fonts)
  return Promise.all(fonts.map(font => new Promise((resolve, reject) => {
    // TODO: loading probably involves making some <link> tags or using the JS APIs
    // https://developer.mozilla.org/en-US/docs/Web/API/CSS_Font_Loading_API
    resolve(font)
  })))
}
