import React from 'react'

import {
  Grid,
  Tooltip,
  // makeStyles,
  IconButton
} from '@material-ui/core'

import {
  Clear,
  DeleteForever,
  VisibilityOff,
  Visibility
} from '@material-ui/icons'
import { drillDown } from 'deepdown'


// const useStyles = makeStyles(theme => ({
//   EditNav: {
//     textAlign: 'center',
//     paddingTop: theme.spacing(1),
//   },
// }))

const DeleteCredit = ({onClick}) => {
  return (
    <Tooltip title="Remove Credit">
      <IconButton onClick={onClick}> <DeleteForever /> </IconButton>
    </Tooltip>
  )
}

const ClearOverride = ({onClick}) => {
  return (
    <Tooltip title="clear override">
      <IconButton onClick={onClick} color="primary">
        <Clear />
      </IconButton>
    </Tooltip>
  )
}

const MaskButton = ({
  localized,
  localizedOverride,
  onClick,
}) => {
  const isMasked = localizedOverride && drillDown(localizedOverride, ['value', 'hidden'])
  return (
    <Tooltip title={isMasked ? "Hidden from credits": "Hide from credits"}>
      <span>
        <IconButton
          disabled={!localized}
          onClick={onClick}
          color={isMasked ? "secondary" : "default"}
        >
          {!isMasked ? <Visibility /> : <VisibilityOff />}
        </IconButton>
      </span>
    </Tooltip>
  )
}

const TalentAction = ({
  // linkData,
  localizedOverride,
  localized,
  readOnly,
  onClickDeleteCredit,
  onClickDeleteOverride,
  onClickMask,
}) => {
  const talentOverride = localizedOverride && localizedOverride.value && localizedOverride.value.talent
  return (
    <Grid container direction="column" wrap="nowrap" alignItems="center">
      {(!readOnly && localized) && (
        <DeleteCredit onClick={onClickDeleteCredit} />
      )}
      {!talentOverride && readOnly && (
        <MaskButton onClick={onClickMask} localized={localized} localizedOverride={localizedOverride} />
      )}
      {(talentOverride) && (
        <ClearOverride onClick={onClickDeleteOverride} />
      )}
    </Grid>
  )
}

export default TalentAction
