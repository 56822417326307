"use strict";
// inspired by:
// https://stackoverflow.com/a/41972664/1740008
Object.defineProperty(exports, "__esModule", { value: true });
const reg = /\$\{[^}]+\}/g;
function interpolate(template, map, fallback) {
    return template.replace(reg, (match) => match
        .slice(2, -1)
        .trim()
        .split(".")
        .reduce((searchObject, key) => searchObject.hasOwnProperty(key)
        ? searchObject[key]
        : fallback || match, map));
}
exports.default = interpolate;
