import XLSX from 'xlsx'
import JSZip from 'jszip'
import { padLeft } from './pad-left'
import { getReleaseYear } from './models'
import { rendering } from 'dubcard'
import { serial } from '../lib/async-utils'

const { computeSvg } = rendering

export const svg2img = (fontFamily, width, height, format, canvas, DOMURL, data) => {
  return new Promise((resolve, reject) => {
    var ctx = canvas.getContext('2d')
    var svgBlob = new Blob([data], {type: 'image/svg+xml;charset=utf-8'})
    var url = DOMURL.createObjectURL(svgBlob)

    // console.log('svg data: ', data)
    // console.log('svg2img url:', url)

    // var canvas = document.createElement('canvas')
    canvas.width = width
    canvas.height = height

    // var img = new Image();
    var img = document.createElement('img')
    img.onload = function () {
      ctx.font = fontFamily
      ctx.drawImage(img, 0, 0, width, height, 0, 0, width, height)
      DOMURL.revokeObjectURL(url)

      var imgURI = canvas.toDataURL(`image/${format}`).replace(`image/${format}`, 'image/octet-stream')
      // console.log('svg2img success')
      resolve(imgURI)
      // resolve(url)
    }

    img.onerror = function (error) {
      console.error('svg2img error: ', error.message || JSON.stringify(error))
      reject(error)
    }

    img.src = url
    // resolve(url)
  })
}

export const exportDubsheet = (arrays) => {
  console.log('---exportDubsheet invoked')
  const wb = XLSX.utils.book_new()
  const ws = XLSX.utils.aoa_to_sheet(arrays)
  XLSX.utils.book_append_sheet(wb, ws, 'Talent')
  const wopts = { bookType: 'xlsx', bookSST: false, type: 'array' }
  const wbout = XLSX.write(wb, wopts)
  console.log('---saving exported dubsheet')
  return new Blob([wbout],{type:"application/octet-stream"})
}

export const getXlsxFilename = (mpm, titles, lang) => {
  const title = titles[mpm]

  // define the filename in parts
  const parts = [
    'Warner_Media_dubsheet',
  ]
  if (['Pilot', 'Episode'].includes(title.type) && title.mpmProductNumber) {
    const parentTitle = titles[title.mpmProductNumber]
    if (parentTitle && parentTitle.originallyAiredAs) {
      parts.push(`${parentTitle.type}`)
      parts.push(`${parentTitle.originallyAiredAs}`)
    }
  }
  parts.push(`${title.type}`)
  if (['Pilot', 'Episode', 'Season'].includes(title.type) && title.originallyAiredAs) {
    parts.push(`${title.originallyAiredAs}`)
  }
  parts.push(`${title.original.title}`)

  const releaseYear = getReleaseYear(title)
  if (releaseYear) {  // Series don't have this
    parts.push(`${releaseYear}`)
  }

  parts.push(`${lang}.xlsx`)
  return parts.join('_')
}

export const getDOMURL = () => (window.URL || window.webkitURL || window)

export const exportNetflixZip = async ({
  pages,
  title,
  releaseYear,
  // formatSettings,
  // translations,
  // talent,
  // sections,
  fontFamily,
  settings,
  lang,
}) => {
  const { bgFill, width, height } = settings
  const format = 'png'
  const page2svg = page => computeSvg(page.elements || [], width, height, fontFamily, bgFill)
  const DOMURL = getDOMURL()
  const page2img = page => svg2img(fontFamily, width, height, format, document.createElement('canvas'), DOMURL, page2svg(page))
  const files = await Promise.all(pages.map(page2img))

  const zip = new JSZip()
  files.forEach((file, index) => zip.file(`${title}_${releaseYear}_${lang}_${padLeft(index + 1 + '', 2)}.${format}`, file.slice(`data:image/octet-stream;base64,`.length), { base64: true }))
  return zip.generateAsync({ type: 'blob' })
}

const makeSvgImage = (DOMURL) => async source => {
  const svgBlob = new Blob([source], {type: 'image/svg+xml;charset=utf-8'})
  const base64 = DOMURL.createObjectURL(svgBlob)

  console.log('svg', base64)
  return {base64}
}

const makeRasterImage = ({renderRasterBySvg, mime}) => async source => {
  const raster = {format: mime.toUpperCase()}
  return renderRasterBySvg({source, raster})
}

const page2img = ({
  mime,
  fontFamily,
  renderSettings,
  DOMURL,
  zip,
  renderRasterBySvg,
  formatFileName,
  onExportProgress,
}) => (page, pageIndex) => async () => {
  const svg2img = (mime === 'svg')
    ? makeSvgImage(DOMURL)
    : makeRasterImage({renderRasterBySvg, mime})

  const source = computeSvg(page.elements || [], fontFamily, renderSettings)

  const image = await svg2img(source)

  const data = image.base64
  // const file = `data:image/${mime};base64,${image.base64}`
  // const data = file.slice(`data:image/octet-stream;base64,`.length)
  const fileName = formatFileName(pageIndex)
  zip.file(fileName, data, { base64: true })
  onExportProgress(pageIndex)
}

export const imageExporter = async ({
  pages,
  renderRasterBySvg,
  renderSettings,
  mime,
  fontFamily,
  formatFileName,
  onExportProgress,
  DOMURL = getDOMURL(),
}) => {
  const zip = new JSZip()

  const makeImage = page2img({
    mime,
    fontFamily,
    renderSettings,
    DOMURL,
    zip,
    renderRasterBySvg,
    formatFileName,
    onExportProgress,
  })

  return serial(pages.map(makeImage)).then(() => {
    return zip.generateAsync({ type: 'blob' })
  })
}
