import React, { useCallback, useState } from 'react'
import { Grid } from '@material-ui/core'

import { imageExporter } from '../lib/file-utils'
import { makeStyles } from '@material-ui/core/styles'

import {
  templates as templateByName,
  rendering,
  vocabulary as translations,
  models,
  formats
} from 'dubcard'

import EditorTabs from './editor/EditorTabs'
import PreviewControls from './editor/PreviewControls'
import Preview from './editor/Preview'

import templateTypes from '../lib/template-types.json'

import { saveAs } from 'file-saver'
import { formatTitle } from '../lib/models'
import { padLeft } from '../lib/pad-left'
import { drillDown, indexByKey } from 'deepdown'

const {calculateLayout, computeSvg} = rendering
const {getSortedRoles} = models
const {reducerLayoutFormatFields} = formats


const cardTypeOptions = templateTypes.map(({displayName}) => ({
  value: displayName,
  display: displayName,
}))

const indexCardTemplatesByDisplayName = indexByKey(templateTypes, ['displayName'])

const useStyles = makeStyles(theme => ({
  editor: {
    height: '100%',
  },
  TalentDataContainer: {
    [theme.breakpoints.up('md')]: {
      height: '100%',
      padding: `0 ${theme.spacing(1)}px`,
    },
  },
  DubcardPreview: {
    [theme.breakpoints.up('md')]: {
      padding: `0 ${theme.spacing(1)}px`,
    },
  },
}))


const makeFontName = font => [`"${font.family}"`, font.category].join(', ')

const makeFileNameForPage = ({mpm, titleValue, lang, mime}) => (pageIndex) => {
  const numDigits = 2
  return `dubcard_${mpm}_${lang}_${titleValue}_${padLeft(pageIndex + 1 + '', numDigits)}.${mime}`
}


const defaultTemplateSettings = {
  bgFill: '#000000',
  textFill: '#ffffff',
}

const Editor = ({
  title,
  season,
  titles,
  talents,
  characters,
  queryParams,
  editorLists,
  updateLocalizationToCharacterAka,
  updateBillingForLocalizedCredit,
  createTalent,
  searchTalent,
  createDelivery,
  addCreditsToLocalizedTitle,
  removeCreditFromLocalizedTitle,
  updateTalentForCharacterCredit,
  updateEpisodeOverrideForSeason,
  removeEpisodeOverrideForSeason,
  renderRasterBySvg,
  fonts,
  formats = [],
  deliverySettings,
  deliveryState,
  getUserAttributes,
}) => {
  // console.log('EDITOR - FORMATS', formats)
  const classes = useStyles()

  const [selectedCardType, setSelectedCardType] = useState(templateTypes[0].displayName)
  const [selectedResolutionIndex, setResolutionIndex] = useState(formats.findIndex(f => f.key === 'HD'))
  const [settings, setSettings] = useState(Object.assign({}, defaultTemplateSettings, formats.find(f => f.label === 'HD')))
  const [fontIndex, setFontIndex] = useState(0)
  const [exportState ] = useState({})

  const [selectedPage, setSelectedPage] = useState(0)

  const fontOptions = (fonts || []).map((font, fontIndex) => ({
    value: fontIndex, //[`"${font.family}"`, font.category].join(', '),
    display: font.family,
    key: makeFontName(font),
  }))

  const resolutionOptions = formats.map((format, keyIndex) => ({
    value: keyIndex, //key,
    display: format.label,
    key: format.label,
  }))

  const lang = queryParams.lang
  // console.log('---- Editor, lang', lang)

  const cardSettings = drillDown(indexCardTemplatesByDisplayName, [selectedCardType, 0])
  const sections = (cardSettings?.sections || []).map(name => templateByName[name])
  const foundSelectedFont = fonts.find(f => fontOptions[fontIndex].key === makeFontName(f))
  const fontFamily = foundSelectedFont ? makeFontName(foundSelectedFont) : ''

  const sortedRoles = getSortedRoles(editorLists)

  const layoutSettings = Object.keys(settings).reduce(reducerLayoutFormatFields(settings), {})

  const pages = calculateLayout({
    settings: { layout: layoutSettings, locale: {}, card: cardSettings?.settings },
    characters,
    translations,
    lang,
    sortedRoles,
    sections,
    talentsById: talents,
  })
  const displayedPage = selectedPage < pages.length ? selectedPage : 0
  const renderSettings = Object.assign({}, defaultTemplateSettings, settings)
  const svgString = !settings ? '' : computeSvg(pages.length === 0 ? [] : pages[displayedPage].elements,
    fontFamily, renderSettings, 'preview')

  const onClickExportImage = useCallback(({mime}) => () => {
    const onExportProgress = (pageIndex) => {
      console.log('=== onExportProgress', pageIndex)
      // setExportState(exState => )
    }

    const titleValue = formatTitle(title.mpm, titles, {showReleaseYear: true}).join('_')
    const zipFileName = `warnermedia-dubcard-${title.mpm}-${lang}-${mime}-${titleValue}.zip`

    const renderSettings = Object.assign({}, defaultTemplateSettings, settings)
    console.log('exporting image format', mime)
    const formatFileName = makeFileNameForPage({mpm: title.mpm, titleValue, lang, mime})
    const exportProps = {pages, renderSettings, lang, mime, fontFamily, renderRasterBySvg, formatFileName, onExportProgress}

    imageExporter(exportProps).then(bytes => {
      return saveAs(bytes, zipFileName)
    }).catch(error => {
      const msg = error.message || JSON.stringify(error)
      console.error(msg)
      alert(error.message || `error occurred generating zip file: ${msg}`)
    })
  }, [lang, titles, fontFamily, settings, title.mpm, pages, renderRasterBySvg])

  const onChangeCardType = useCallback(e => {
    console.log('onChangeTemplate', e.target.value)
    setSelectedCardType(e.target.value)
  }, [setSelectedCardType])

  const onChangeResolutions = useCallback(e => {
    console.log('onChangeResolutions', e.target.value)
    const optionIndex = parseInt(e.target.value, 10)
    setResolutionIndex(optionIndex)

    const newFormat = formats[optionIndex]
    console.log('onChangeResolutions newFormat', newFormat)
    setSettings(newFormat)
  }, [setResolutionIndex, setSettings, formats])

  const onChangeFont = useCallback(e => {
    console.log('onChangeFont', e.target.value)
    const optionIndex = parseInt(e.target.value, 10)
    setFontIndex(optionIndex)
  }, [setFontIndex])

  return (
    <Grid container direction="row" wrap="wrap" justifyContent="flex-start" alignItems="flex-start" className={classes.editor}>
      <Grid item xs={12} md={6} container direction="column" wrap="nowrap" className={classes.TalentDataContainer}>
        <EditorTabs {...{
          deliveryState,
          pages,
          title,
          season,
          lang,
          talents,
          characters,
          editorLists,
          updateLocalizationToCharacterAka,
          updateBillingForLocalizedCredit,
          createTalent,
          searchTalent,
          createDelivery,
          addCreditsToLocalizedTitle,
          removeCreditFromLocalizedTitle,
          updateTalentForCharacterCredit,
          updateEpisodeOverrideForSeason,
          removeEpisodeOverrideForSeason,
          deliverySettings,
          getUserAttributes,
        }} />
      </Grid>
      <Grid item xs={12} md={6} container direction="column" wrap="nowrap" className={classes.DubcardPreview}>
        <PreviewControls {...{
          onClickExportImage,
          exportState,
          lang,
          title,
          settings, setSettings,
          pages, selectedPage, setSelectedPage,
          cardTypeOptions, selectedCardType, onChangeCardType,
          resolutionOptions, selectedResolutionIndex, onChangeResolutions,
          fontFamily, fontOptions, onChangeFont,
        }} />
        <Preview svgString={svgString} />
      </Grid>
    </Grid>
  )
}

export default Editor
