import React, { useCallback, useEffect, useState } from 'react'
import { Grid, makeStyles } from '@material-ui/core'
import Layout from '../components/Layout'
import { useDebounce } from '../lib/hooks'

import TalentDetails from '../components/talent/TalentDetails'
import TalentSearch from '../components/talent/TalentSearch'
import { parseParams } from '../lib/query-params'
import { useHistory } from 'react-router'

const useStylesLayoutItem = makeStyles(theme => ({
  expand: {
    flex: 1,
  },
}))

const LayoutItem = ({children}) => {
  const classes = useStylesLayoutItem()

  return (
    <div className={classes.expand}>
      {children}
    </div>
  )
}

const useStyles = makeStyles(() => ({
  fullHeight: {
    height: '100%',
  },
}))

const TalentEditorForm = ({
  inputValue,
  selected,
  searchResults,
  updateTalentAka,
  addAkasToTalent,
  onClickCloseDetails,
  onClickSelect,
  onClickClearSearch,
  onChangeInputValue,
  onClickAddTalent,
}) => {
  const classes = useStyles()

  return (
    <Grid container direction="row" wrap="nowrap" className={classes.fullHeight}>
      <LayoutItem>
        <TalentSearch {...{
          inputValue,
          searchResults,
          selected,
          onClickSelect,
          onClickClearSearch,
          onChangeInputValue,
          onClickAddTalent,
        }} />
      </LayoutItem>
      <LayoutItem>
        <TalentDetails {...{
          selected,
          onClickCloseDetails,
          updateTalentAka,
          addAkasToTalent,
        }} />
      </LayoutItem>
    </Grid>
  )
}

const TalentEditorPage = (props) => {
  const {
    talents,
    createTalent,
    searchTalent,
    updateTalentAka,
    addAkasToTalent,
    location,
    debounceMs=500,
  } = props
  const history = useHistory()
  const queryParams = (location.search && location.search.length > 1) ? parseParams(location.search.slice(1)) : {}
  const talentId = queryParams && queryParams.id
  const [selected, setSelected] = useState(talentId && talents && talents[talentId])
  const [inputValue, setInputValue] = useState('')
  const debouncedInputValue = useDebounce(inputValue, debounceMs)
  const [searchResults, setSearchResults] = useState({query: null, fetching: false, results: [], error: null})

  const onClickAddTalent = useCallback(() => {
    console.log('/talent/TalentSearch/onClickAddTalent', inputValue)
    const input = {
      AKAs: [
        {value: inputValue},
      ],
    }
    createTalent(input).then(result => {
      console.log('/talent/TalentSearch/onClickAddTalent, result', result)
      history.push(`${location.pathname}?id=${result.id}`)
      setSelected(result)
    }).catch(error => {
      console.log('/talent/TalentSearch/onClickAddTalent, error', error)
    })
  }, [inputValue, createTalent, history, location])

  const onChangeInputValue = useCallback((e) => {
    setInputValue(e.target.value)
  }, [setInputValue])

  useEffect(() => {
    if (!debouncedInputValue || debouncedInputValue.length === 0) {
      return
    }

    let cancelled = false
    const before = {query: debouncedInputValue, results: [], error: null, fetching: true}
    // TODO: show spinner while searching, setting `before` caused infinite loop
    // setSearchResults(before)
    const input = {
      akaValue: debouncedInputValue,
      limit: 10,
    }
    searchTalent(input).then(results => {
      if (cancelled) {
        return
      }
      console.log('/talent/TalentSearch/useEffect/searchTalent, results', results)
      setSearchResults({...before, results, fetching: false})
    }).catch(error => {
      if (cancelled) {
        return
      }
      console.error('/talent/TalentSearch/useEffect/searchTalent, error', error)
      setSearchResults({...before, error, fetching: false})
    })

    return () => {
      cancelled = true
    }
  }, [debouncedInputValue, searchTalent, setSearchResults])

  const onClickClearSearch = useCallback(() => {
    setInputValue('')
    setSearchResults(before => ({...before, results: []}))
  }, [setInputValue, setSearchResults])

  const onClickSelect = useCallback(t => () => {
    setSelected(t)
    history.push(`${location.pathname}?id=${t.id}`)
  }, [setSelected, history, location])

  const onClickCloseDetails = useCallback(() => {
    setSelected(null)
    history.push(location.pathname)
  }, [history, location, setSelected])

  useEffect(() => {
    // exit if no query param
    if (!talentId) {
      setSelected(null)
      return
    }

    // already have it cached?
    if (talents && talents[talentId]) {
      return
    }

    const input = {
      id: [talentId],
    }
    searchTalent(input).then(results => {
      console.log(`/talent/searchTalent(${JSON.stringify(input)}), results`, results)
      if (results.length > 0) {
        setSelected(results[0])
      }
    }).catch(error => {
      console.log(`/talent/searchTalent(${JSON.stringify(input)}), error`, error)
    })
  }, [searchTalent, talentId, talents])

  const updateTalentAkaWrapper = useCallback(input => {
    return updateTalentAka(input).then(result => {
      if (selected && (input.id === selected.id)) {
        setSelected(result)
      }
      return result
    })
  }, [updateTalentAka, selected, setSelected])

  const addAkasToTalentWrapper = useCallback(input => {
    return addAkasToTalent(input).then(result => {
      if (selected && (input.id === selected.id)) {
        setSelected(result)
      }
      return result
    })
  }, [addAkasToTalent, selected, setSelected])

  return (
    <Layout { ...{...props} }>
      <TalentEditorForm { ...{
        inputValue,
        selected,
        searchResults,
        updateTalentAka: updateTalentAkaWrapper,
        addAkasToTalent: addAkasToTalentWrapper,
        onClickSelect,
        onClickCloseDetails,
        onClickClearSearch,
        onChangeInputValue,
        onClickAddTalent,
      }} />
    </Layout>
  )
}

export default TalentEditorPage
