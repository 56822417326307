import { makeStyles, Typography } from "@material-ui/core"

const useStyles = makeStyles(theme => ({
  ImportErrorDisplay: {},
  heading: {},
  subheading: {},
  itemsContainer: {},
  item: {},
}))

const ImportErrorDisplay = ({
  heading = "Error",
  subheading = "API metadata was insufficient",
  items = [],
}) => {
  const classes = useStyles()

  return (
    <div className={classes.ImportErrorDisplay}>
      <div className={classes.heading}>
        <Typography variant="h1">{heading}</Typography>
      </div>
      {subheading && (
        <div className={classes.subheading}>
          <Typography variant="h2">{subheading}</Typography>
        </div>
      )}
      {(items.length > 0) && (
        <div className={classes.itemsContainer}>
          {(items || []).map(({message}) => (
            <div className={classes.item}>
              <Typography>{message}</Typography>
            </div>
          ))}
        </div>
      )}
    </div>
  )
}

export default ImportErrorDisplay
