import { Grid, makeStyles, Typography } from '@material-ui/core'
import DeliveryRecord from './DeliveryRecord'

const useStyles = makeStyles(theme => ({
  NoItems: {
    padding: theme.spacing(1),
  },
  Fetching: {
    padding: theme.spacing(1),
  },
}))

const NoItems = () => {
  const classes = useStyles()

  return (
    <Grid container direction="row" wrap="nowrap" alignItems="center" className={classes.NoItems}>
      <Typography variant="h6">No items found</Typography>
    </Grid>
  )
}

const Fetching = () => {
  const classes = useStyles()

  return (
    <Grid container direction="row" wrap="nowrap" alignItems="center" className={classes.Fetching}>
      <Typography variant="h6">Loading</Typography>
    </Grid>
  )
}

const DeliveryList = ({
  deliveryState,
  getUserAttributes,
}) => {
  if (deliveryState.fetching) {
    return <Fetching />
  }

  const deliveries = deliveryState.results
  if (!deliveries || deliveries.length === 0) {
    return <NoItems />
  }

  return (
    <div>
      {deliveries.map(record => (
        <DeliveryRecord
          key={record.id}
          record={record}
          expand={deliveries.length === 1}
          getUserAttributes={getUserAttributes}
        />
      ))}
    </div>
  )
}

export default DeliveryList
