"use strict";
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.computeSvg = exports.calculateLayout = exports.talentSectionReducer = exports.talentReducer = exports.checkContentOnPage = exports.makeSectionHeadingElement = exports.makePageHeadingElement = exports.getBounds = exports.getLast = exports.makePage = exports.makeElementTalent = exports.makeElementTalentName = exports.makeElementTalentRole = exports.doTranslation = exports.makeElement = exports.getRole = void 0;
const deepdown_1 = require("deepdown");
const role_types_1 = require("./role-types");
const models_1 = require("./models");
const types_1 = require("./types");
const getRole = ({ credit, characters, translations, lang }) => {
    const character = characters && credit.character && credit.character.id && characters[credit.character.id];
    const characterAka = character && character.AKAs && character.AKAs.find(aka => aka.id === credit.character.aka);
    const localizedAka = (characterAka && characterAka.localizations) && characterAka.localizations.find(l => l.language === lang);
    return (((0, role_types_1.isCastRole)(credit.role) && character)
        ? (localizedAka && localizedAka.value)
            ? localizedAka.value
            : (characterAka && (0, deepdown_1.drillDown)(characterAka, ['original', 'value']))
                ? (0, deepdown_1.drillDown)(characterAka, ['original', 'value'])
                : 'Unknown Character'
        : (0, exports.doTranslation)({ translations, lang, source: credit.role }));
};
exports.getRole = getRole;
/**
 * constructor for scene element objects
 *
 * @param {number} x position coordinate in scene space
 * @param {number} y position coordinate in scene space
 * @param {string} fill a color, by name or hex code, e.g. "white" or "#ffffff"
 * @param {string} anchor enum of values [start, middle, end]
 * @param {array} margin an array of lengths [top, right, bottom, left]
 * @param {string} text display value
 */ const makeElement = ({ y, x, text, fontSize, anchor = types_1.SvgTextAnchor.middle, fill = 'white', margin }) => {
    return { y, x, text, fontSize, anchor, fill, margin };
};
exports.makeElement = makeElement;
const doTranslation = ({ translations, lang, source }) => (0, deepdown_1.drillDown)(translations, [lang, source]) || source;
exports.doTranslation = doTranslation;
const makeElementTalentRole = ({ settings: { layout, locale }, section, yEnd, role }) => (0, exports.makeElement)({
    x: 0.5 * layout.width - layout.roleSep,
    y: yEnd + layout.talentFontSize,
    text: section.disableUppercaseRole ? role : role.toLocaleUpperCase(locale.target),
    anchor: types_1.SvgTextAnchor.end,
    fontSize: layout.talentFontSize,
    margin: [0, 0, layout.roleVerticalPad, 0],
    fill: layout.textFill,
});
exports.makeElementTalentRole = makeElementTalentRole;
const makeElementTalentName = ({ settings: { layout, locale }, section, yEnd, fullName }) => (0, exports.makeElement)({
    x: 0.5 * layout.width + (section.hideRole ? 0 : layout.roleSep),
    y: yEnd + layout.talentFontSize,
    text: section.disableUppercaseRole ? fullName : fullName.toLocaleUpperCase(locale.target),
    anchor: section.hideRole ? types_1.SvgTextAnchor.middle : types_1.SvgTextAnchor.start,
    fontSize: layout.talentFontSize,
    margin: [0, 0, layout.roleVerticalPad, 0],
    fill: layout.textFill,
});
exports.makeElementTalentName = makeElementTalentName;
const makeElementTalent = ({ settings: { layout, locale }, section, characters, translations, lang, yEnd, credit, talentsById }) => {
    const elements = [];
    if (!section.hideRole && credit) {
        const role = (0, exports.getRole)({ credit, characters, translations, lang });
        elements.push((0, exports.makeElementTalentRole)({ settings: { layout, locale }, section, yEnd, role }));
    }
    const talentId = credit.talent && credit.talent.id;
    const talent = talentsById && talentId && talentsById[talentId];
    const talentAka = credit.talent && credit.talent.aka && talent && talent.AKAs.find(aka => aka.id === credit.talent.aka);
    const talentName = talent && talentAka
        ? talentAka.value
        : (talent && talent.AKAs.length > 0)
            ? talent.AKAs[0].value
            : undefined;
    if (talentName) {
        elements.push((0, exports.makeElementTalentName)({ settings: { layout, locale }, section, yEnd, fullName: talentName || '' }));
    }
    return elements;
};
exports.makeElementTalent = makeElementTalent;
const makePage = ({ settings: { layout }, section, translations, lang }) => {
    const pageHeading = (0, exports.doTranslation)({ translations, lang, source: section.heading });
    const subHeading = section.subHeading && (0, exports.doTranslation)({ translations, lang, source: section.subHeading });
    const elementPageHeading = (0, exports.makePageHeadingElement)({ settings: { layout }, yEnd: layout.verticalPad, pageHeading });
    const yEnd = (0, exports.getBounds)(elementPageHeading).bottomRight.y;
    const elementSectionSubHeading = subHeading && (0, exports.makeSectionHeadingElement)({ settings: { layout }, yEnd, text: subHeading });
    return {
        elements: [
            elementPageHeading,
            ...(!subHeading ? [] : [elementSectionSubHeading]),
        ],
    };
};
exports.makePage = makePage;
const getLast = arr => arr[arr.length - 1];
exports.getLast = getLast;
const getBounds = (element) => ({
    topLeft: { y: element.y - element.fontSize + element.margin[0] },
    bottomRight: { y: element.y + element.margin[2] + element.margin[0] },
});
exports.getBounds = getBounds;
const makePageHeadingElement = ({ settings: { layout }, yEnd, pageHeading }) => (0, exports.makeElement)({
    x: 0.5 * layout.width,
    y: yEnd + layout.headingFontSize,
    text: pageHeading,
    anchor: types_1.SvgTextAnchor.middle,
    fontSize: layout.headingFontSize,
    margin: [0, 0, layout.headingVerticalPad, 0],
    fill: layout.textFill,
});
exports.makePageHeadingElement = makePageHeadingElement;
const makeSectionHeadingElement = ({ settings: { layout }, yEnd, text }) => (0, exports.makeElement)({
    x: 0.5 * layout.width,
    y: yEnd + layout.headingFontSize,
    text,
    anchor: types_1.SvgTextAnchor.middle,
    fontSize: layout.headingFontSize,
    margin: [0, 0, layout.headingVerticalPad, 0],
    fill: layout.textFill,
});
exports.makeSectionHeadingElement = makeSectionHeadingElement;
// returns a page that is safe to add a new element to
const checkContentOnPage = ({ settings: { layout }, section, pages, translations, lang }) => {
    let currentPage, accumResult;
    const lastPage = (0, exports.getLast)(pages);
    currentPage = lastPage;
    accumResult = pages;
    const lastElement = (0, exports.getLast)(lastPage.elements);
    const yEnd = (0, exports.getBounds)(lastElement).bottomRight.y;
    // check height relative to last page if we need a new page
    const pageLowerLimit = layout.height - layout.verticalPad;
    const contentHeight = layout.talentFontSize;
    if ((yEnd + contentHeight) > pageLowerLimit) {
        const newPage = (0, exports.makePage)({ settings: { layout }, section, translations, lang });
        // performs the reducer output, see talentReducer
        currentPage = newPage;
        accumResult = [...pages, newPage];
    }
    return { currentPage, accumResult };
};
exports.checkContentOnPage = checkContentOnPage;
const talentReducer = (props, section) => (accum, credit) => {
    const { settings, characters, talentsById, translations, lang } = props;
    const { layout } = settings;
    const { currentPage, accumResult } = (0, exports.checkContentOnPage)({ settings: { layout }, section, pages: accum, translations, lang });
    const lastElement = (0, exports.getLast)(currentPage.elements);
    const yEnd = (0, exports.getBounds)(lastElement).bottomRight.y;
    currentPage.elements = [
        ...currentPage.elements,
        ...(0, exports.makeElementTalent)({ settings, section, characters, translations, lang, yEnd, credit, talentsById }),
    ];
    return accumResult;
};
exports.talentReducer = talentReducer;
const talentSectionReducer = (props) => (accum, section) => {
    const { settings, translations, lang, sortedRoles, talentsById, characters, } = props;
    // choose which roles to display
    const filteredRoles = !section.roleFilter
        ? sortedRoles
        : sortedRoles.filter((0, models_1.filterRoles)(section.roleFilter));
    if (filteredRoles.length === 0) {
        return accum;
    }
    const { layout, locale, card } = settings;
    const clientForceNewPage = !(card === null || card === void 0 ? void 0 : card.combine);
    let currentPage = (0, exports.getLast)(accum);
    let accumResult = accum;
    // make a new page for each section
    if (section.forceNewPage || clientForceNewPage || (accum.length === 0)) {
        currentPage = (0, exports.makePage)({ settings: { layout }, section, translations, lang });
        accumResult = [...accum, currentPage];
    }
    else {
        // make sub-heading element
        const subHeading = section.subHeading && (0, exports.doTranslation)({ translations, lang, source: section.subHeading });
        if (subHeading) {
            // select most recent element's y coordinate
            const yEnd = (currentPage.elements.length > 0)
                ? (0, exports.getBounds)((0, exports.getLast)(currentPage.elements)).bottomRight.y
                : layout.verticalPad;
            // look ahead at what will be needed, to see if it will fit on the current page
            const subHeadingElement = (0, exports.makeSectionHeadingElement)({ settings, yEnd: (yEnd + layout.sectionVerticalPad), text: subHeading });
            const yEndSubHeading = (0, exports.getBounds)(subHeadingElement).bottomRight.y;
            // make sure at least one credit will be visible under the subheading
            const nextCredit = filteredRoles[0];
            const nextCreditElements = (0, exports.makeElementTalent)({ settings: { layout, locale }, section, characters, translations, lang, yEnd: yEndSubHeading, credit: nextCredit, talentsById });
            if (nextCreditElements.length > 0) {
                const yEndNextCredit = (0, exports.getBounds)((0, exports.getLast)(nextCreditElements)).bottomRight.y;
                // add the subheading if it fits
                const pageLowerLimit = layout.height - layout.verticalPad;
                if (yEndNextCredit < pageLowerLimit) {
                    // TODO: find alternative to this mutation
                    currentPage.elements.push(subHeadingElement);
                }
                else {
                    currentPage = (0, exports.makePage)({ settings: { layout }, section, translations, lang });
                    accumResult = [...accum, currentPage];
                }
            }
        }
    }
    return filteredRoles.reduce((0, exports.talentReducer)(props, section), accumResult);
};
exports.talentSectionReducer = talentSectionReducer;
const calculateLayout = (_a) => {
    var { sections } = _a, otherProps = __rest(_a, ["sections"]);
    return sections.reduce((0, exports.talentSectionReducer)(otherProps), []);
};
exports.calculateLayout = calculateLayout;
const computeSvgElement = ({ x, y, fontSize, anchor, fill, text }) => `<text x="${x}" y="${y}" font-size="${fontSize}" text-anchor="${anchor}" fill="${fill}">${text}</text>`;
const computeSvg = (elements, fontFamily, settings, id) => {
    const { width, height, bgFill } = settings;
    return `
<svg ${id ? `id="${id}"` : ''}
  version="1.1"
  baseProfile="full"
  viewBox="${[0, 0, width, height].join(' ')}"
  xmlns="http://www.w3.org/2000/svg"
  xmlns:xlink="http://www.w3.org/1999/xlink">

    <style type="text/css">
      text { font-family: ${fontFamily} }
    </style>

    <rect width="100%" height="100%" fill="${bgFill}" />
    ${elements.map(computeSvgElement).join('\n    ')}
</svg>
`;
};
exports.computeSvg = computeSvg;
