import { FC } from 'react'
import InputLabel, {InputLabelProps} from '@material-ui/core/InputLabel'
import FormControl from '@material-ui/core/FormControl'
import Select, {SelectProps} from '@material-ui/core/Select'

export interface DropDownOption {
  value: string
  display: string
}

export interface DropDownProps {
  id?: string
  name?: InputLabelProps['htmlFor']
  label?: string
  className?: string
  value: DropDownOption['value']
  onChange: SelectProps['onChange']
  options: DropDownOption[]
  disabled?: boolean
}

const DropDown: FC<DropDownProps> = ({
  id,
  name,
  label,
  className,
  value,
  onChange,
  options,
  disabled,
}) => {
  return (
    <FormControl variant="filled" className={className}>
      {label && <InputLabel htmlFor={name}>{label}</InputLabel>}
      <Select
        native
        fullWidth
        disabled={disabled}
        value={value}
        onChange={onChange}
        inputProps={{
          name,
          id,
        }}
      >
        {(options || []).map(o => (
          <option key={`dropdown/${id}/option/${o.value}`} value={o.value}>{o.display}</option>
        ))}
      </Select>
    </FormControl>
  )
}

export default DropDown
