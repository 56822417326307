import { useCallback, useState } from "react"
import { makeStyles, TextField } from "@material-ui/core"
import { Autocomplete } from "@material-ui/lab"

import clsx from 'clsx'
import { unwindByKey } from "deepdown"
import {bulk} from 'dubcard'

const {BehaviorMode} = bulk

// const reasonInput = 'input'
const reasonClear = 'clear'

const useStylesExistingCharacter = makeStyles({
  root: {
    width: 180,
  },
})

const ImportingExistingCharacter = ({
  credit,
  characters,
  behaviorClass,
  unwoundFranchiseCharacterAKAs,
  onChangeCharacter,
}) => {
  const classes = useStylesExistingCharacter()
  const character = characters && characters[credit?.character?.entity?.id]
  const unwoundCharacterAkas = (character && unwindByKey([character], ['AKAs'])) || []
  const unwoundCharacterMatchAKA = unwoundCharacterAkas.find(c => c.AKAs.id === credit?.character?.entity?.aka)
  // const aka = character?.AKAs && character.AKAs.find(aka => aka.id === credit?.character?.entity?.aka)

  const value = unwoundCharacterMatchAKA || null

  const mode = credit?.character?.mode

  const [inputValue, setInputValue] = useState(unwoundCharacterMatchAKA?.AKAs?.ooriginal?.value ? unwoundCharacterMatchAKA?.AKAs?.original?.value : '')
  // const [, setInputValueReason] = useState(null)


  const renderInput = useCallback(params => {
    return (
      <TextField
        {...params}
        label={(mode !== BehaviorMode.USE_EXISTING) ? "Not Using" : (value?.id) ? "Using Character" : "Choose"}
      />
    )
  }, [value, mode])


  const onInputChange = useCallback((event, inputV, reason) => {
    // console.log('*** ImportingExistingCharacter/onInputChange reason', reason, 'input value', inputV)
    setInputValue(inputV)
    // setInputValueReason(reason)
  }, [])

  const onChangeValue = useCallback((event, newValue, reason) => {
    console.log('ImportingExistingCharacter/onChangeValue - newValue', newValue)
    if (reason === reasonClear || newValue === null) {
      return onChangeCharacter(null)
    }
    onChangeCharacter({id: newValue.id, aka: newValue.AKAs.id})
  }, [onChangeCharacter])

  const getOptionSelected = useCallback((o, v) => {
    return (o.id === v.id) && (o?.AKAs?.id === v?.AKAs?.id)
  }, [])

  const getOptionLabel = useCallback(option => {
    return option?.AKAs?.original?.value || (typeof option === 'string' ? option : 'error')
  }, [])

  return (
    <Autocomplete
      disableClearable
      classes={{root: clsx(behaviorClass, classes.root)}}
      options={unwoundFranchiseCharacterAKAs}
      renderInput={renderInput}
      getOptionLabel={getOptionLabel}
      getOptionSelected={getOptionSelected}
      value={value}
      onChange={onChangeValue}
      disabled={mode !== BehaviorMode.USE_EXISTING}
      inputValue={inputValue}
      onInputChange={onInputChange}
    />
  )

  // return (
  //   <DropDown
  //     disabled={(mode !== BehaviorMode.USE_EXISTING)}
  //     className={clsx(classes.ImportingExistingCharacter, behaviorClass)}
  //     label={(mode !== BehaviorMode.USE_EXISTING) ? "Not Using" : (aka) ? "Using Character" : "Choose"}
  //     value={((mode === BehaviorMode.USE_EXISTING) && character) ? character.id : ''}
  //     options={(mode !== BehaviorMode.USE_EXISTING) ? [] : optionsCharacter}
  //     onChange={onChangeCharacter}
  //   />
  //   )
}

export default ImportingExistingCharacter
