import { Chip, Tooltip } from "@material-ui/core"

const Bcp47Chip = ({
  description,
  code,
}) => {
  return (
    <Tooltip title={description}>
      <Chip color="primary" variant="outlined" label={code} />
    </Tooltip>
  )
}

export default Bcp47Chip
