import React, {useState, useEffect, useCallback} from 'react'
import {drillDown} from 'deepdown'
import {useDebounce} from '../../lib/hooks'
import {getSortedTalent} from '../../lib/models'

import {
  makeStyles,
  Button,
  Grid,
  Typography
} from '@material-ui/core'

import TalentSelectForm from './TalentSelectForm'

const useStyles = makeStyles(theme => ({
  expand: {
    flex: 1,
  },
  characterBackground: {
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.primary.contrastText,
  },
}))


const TalentSelectionHeader = ({
  characters,
  original,
  onClickCancel,
}) => {
  const classes = useStyles()
  const characterId = original && drillDown(original, ['character', 'id'])
  const character = characterId && characters[characterId]
  const characterName = character && drillDown(character, ['AKAs', 0, 'original', 'value'])

  return (
    <div>
      <Grid container direction="row" alignItems="center" justifyContent="space-between">
        <Grid item className={classes.expand}></Grid>
        <Typography variant="body1" align="center">SELECT TALENT FOR CHARACTER</Typography>
        <Grid item className={classes.expand} container direction="row" alignItems="center" justifyContent="flex-end">
          <Button onClick={onClickCancel} variant="text">CANCEL</Button>
        </Grid>
      </Grid>
      <Grid container direction="row" alignItems="center" justifyContent="center" className={classes.characterBackground}>
        <Typography variant="h5"> {characterName}</Typography>
      </Grid>
    </div>
  )
}

const TalentSelectionForCharacter = ({
  inputValue,
  setInputValue,
  onClickCreateTalent,
  onClickSelectExistingTalent,
  onClickCancelSelectTalent,
  characters,
  original,
  searchTalent,
  debounceMs=500,
}) => {
  const debouncedInputValue = useDebounce(inputValue, debounceMs)
  const [searchState, setSearchState] = useState({fetching: false, query: null, results: [], error: null})
  const sortedTalent = getSortedTalent({talents: searchState.results || []})

  const onChangeInputValue = useCallback((e) => {
    // console.log('TalentSelectionForCharacter/onChangeInputValue', e.target.value)
    setInputValue(e.target.value)
  }, [setInputValue])

  useEffect(() => {
    if (!debouncedInputValue || (debouncedInputValue.length < 3)) {
      return
    }

    let mounted = true

    const query = {akaValue: debouncedInputValue}
    const before = {query, fetching: true}
    setSearchState(before)
    searchTalent(query).then(results => {
      if (!mounted) {
        return
      }

      console.log('TalentSelectForm/useEffect/searchTalent - results:', results)
      setSearchState({...before, fetching: false, results})
    }).catch(error => {
      console.error('TalentSelectForm/useEffect/searchTalent - error:', error)
      setSearchState({...before, fetching: false, results: null, error})
    })

    return () => {
      mounted = false
    }
  }, [debouncedInputValue, setSearchState, searchTalent])

  return (
    <div>
      <TalentSelectionHeader {...{ characters, original, onClickCancel: onClickCancelSelectTalent }} />
      <TalentSelectForm {...{
        onClickSelectExistingTalent,
        onClickCreateTalent,
        onChangeInputValue,
        inputValue,
        sortedTalent,
        searchState,
      }} />
    </div>
  )
}

export default TalentSelectionForCharacter
