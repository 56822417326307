"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.reducerLayoutFormatFields = exports.FormatList = exports.Format4x3 = exports.HD = exports.UHD = void 0;
exports.UHD = {
    label: 'UHD',
    width: 3840,
    height: 2160,
    // percent of vertical height
    headingFontSize: (20 / 1080),
    talentFontSize: (20 / 1080),
    roleVerticalPad: (10 / 1080),
    verticalPad: (280 / 1080),
    headingVerticalPad: (25 / 1080),
    sectionVerticalPad: (50 / 1080),
    // percent of horizontal width
    roleSep: (30 / 1920),
    // headingFontSize: 100,
    // talentFontSize: 100,
    // roleVerticalPad: 20,
    // verticalPad: 300,
    // headingVerticalPad: 100,
    // roleSep: 150,
    // bgFill: 'black',
    // textFill: 'white',
};
exports.HD = {
    label: 'HD',
    width: 1920,
    height: 1080,
    // percent of vertical height
    headingFontSize: (20 / 1080),
    talentFontSize: (20 / 1080),
    roleVerticalPad: (10 / 1080),
    verticalPad: (280 / 1080),
    headingVerticalPad: (25 / 1080),
    sectionVerticalPad: (50 / 1080),
    // percent of horizontal width
    roleSep: (30 / 1920),
    // headingFontSize: 20,
    // talentFontSize: 20,
    // roleVerticalPad: 10,
    // verticalPad: 280,
    // headingVerticalPad: 50,
    // roleSep: 30,
    // // color settings
    // bgFill: 'black',
    // textFill: 'white',
};
exports.Format4x3 = {
    label: '4x3',
    width: 1920,
    height: 1440,
    // percent of vertical height
    headingFontSize: (20 / 1080),
    talentFontSize: (20 / 1080),
    roleVerticalPad: (10 / 1080),
    verticalPad: (280 / 1080),
    headingVerticalPad: (25 / 1080),
    sectionVerticalPad: (50 / 1080),
    // percent of horizontal width
    roleSep: (30 / 1920),
    // headingFontSize: 66.67,
    // talentFontSize: 66.67,
    // roleVerticalPad: 13.33,
    // verticalPad: 200,
    // headingVerticalPad: 66.67,
    // roleSep: 75,
    // // color
    // bgFill: 'black',
    // textFill: 'white',
};
exports.FormatList = [
    exports.HD,
    exports.UHD,
    exports.Format4x3,
];
const passThroughList = [
    'label',
    'width',
    'height',
    'bgFill',
    'textFill',
];
const reducerLayoutFormatFields = (context) => (accum, key) => {
    const { width, height } = context;
    return Object.assign(Object.assign({}, accum), { [key]: (passThroughList.includes(key)
            ? context[key]
            : (key === 'roleSep')
                ? context[key] * width
                : context[key] * height) });
};
exports.reducerLayoutFormatFields = reducerLayoutFormatFields;
